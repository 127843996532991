import styled from 'styled-components';

const H1 = styled.div`
  font-size: 24px;
`;

const H2 = styled.div`
  font-size: ${({ theme }) => theme.headings.h2.fontSize};
`;

const H3 = styled.div`
  font-size: ${({ theme }) => theme.headings.h3.fontSize};
`;

const H4 = styled.div`
  font-size: ${({ theme }) => theme.headings.h4.fontSize};
`;

const H5 = styled.div`
  font-size: ${({ theme }) => theme.headings.h5.fontSize};
`;

const H6 = styled.div`
  font-size: ${({ theme }) => theme.headings.h6.fontSize};
`;

export default {
  H1, H2, H3, H4, H5, H6,
};
