import React from 'react';
import styled from 'styled-components';

interface LargeButtonProps {
  label: string,
  onClick: Function,
}

const StyledButton = styled.div`
  width: 100%;
  max-width: 500px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.accent};
  padding: 5px;
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes[3]};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const LargeButton: React.FC<LargeButtonProps> = (props: LargeButtonProps) => {
  const { onClick, label } = props;
  return (
    <StyledButton onClick={() => onClick()}>
      {label}
    </StyledButton>
  );
};

export default LargeButton;
