import styled from 'styled-components';

interface ContainerProps {
  column?: boolean;
  width?: string;
  overflow?: string;
}

const SentimentMeterContainer = styled.div<ContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => (width || '100%')};
  min-width: 300px;
  overflow-x: ${({ overflow }) => (overflow || 'scroll')};
  @media screen and (max-width: 1200px) {
    flex-direction: row;
    width: 100%;
    max-width: 100%:
    min-width: 100%:
    height: auto;
    margin: 15px 0;
    overflow-x: scroll;
    padding: 0;
  }
`;

export default SentimentMeterContainer;
