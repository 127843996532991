/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import {
  LOAD_PORTFOLIO,
  LOAD_PORTFOLIO_SUCCESS,
  LOAD_PORTFOLIO_ERROR,
  SET_PORTFOLIO_LIST,
  SET_PORTFOLIO_ID,
  STATS_GET_SUCCESS,
  GAIN_LOSS_GET_SUCCESS,
  CURRENCIES_GET_ERROR,
  CURRENCIES_GET_REQUEST,
  CURRENCIES_GET_SUCCESS,
  SET_EDIT_STAT_ROW,
  LOAD_ALLOCATIONS,
  LOAD_ALLOCATIONS_SUCCESS,
  LOAD_ALLOCATIONS_ERROR,
  LOAD_DRAWDOWN,
  LOAD_DRAWDOWN_ERROR,
  LOAD_DRAWDOWN_SUCCESS,
  LOAD_NEWS,
  LOAD_NEWS_ERROR,
  LOAD_NEWS_SUCCESS,
  LOAD_TWEETS,
  LOAD_TWEETS_ERROR,
  LOAD_TWEETS_SUCCESS,
  LOAD_REDDIT,
  LOAD_REDDIT_ERROR,
  LOAD_REDDIT_SUCCESS,
  SET_CREATE_PORTFOLIO,
  LOAD_BENCHMARK_SUCCESS,
  SET_OPTIMIZATION_PARAMS,
  LOAD_SCENARIO_ERROR,
  LOAD_SCENARIO,
  LOAD_SCENARIO_SUCCESS,
  SET_STRATEGY_LIST,
  SET_SELECTED_STRATEGY,
} from './constants';
import Client from '../utils/api/backend/clients';

const backendClient = new Client();

export const loadCurrencies = (accessToken: string, idToken: string) => async (dispatch: any) => {
  dispatch({
    type: CURRENCIES_GET_REQUEST,
  });
  try {
    const data: any[] = await (await backendClient.getCurrencies(accessToken, idToken)).json();
    dispatch({
      type: CURRENCIES_GET_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: CURRENCIES_GET_ERROR,
      payload: e,
    });
  }
};

export const setCreatePortfolio = (active: boolean, mode?: 'paper' | 'live') => (dispatch: any) => {
  dispatch({
    type: SET_CREATE_PORTFOLIO,
    payload: { active, mode },
  });
};

export const loadDrawdown = (accessToken: string, idToken: string, id: number, parameters?: any) => async (dispatch: any) => {
  dispatch({
    type: LOAD_DRAWDOWN,
  });
  try {
    const drawdown = await backendClient.getPortfolioDrawdownById(accessToken, idToken, id, parameters);
    dispatch({
      type: LOAD_DRAWDOWN_SUCCESS,
      payload: drawdown,
    });
  } catch (e) {
    dispatch({
      type: LOAD_DRAWDOWN_ERROR,
      payload: e,
    });
  }
};

export const loadAllocations = (accessToken: string, idToken: string, id: number, parameters?: any, withBenchmark = false) => async (dispatch: any) => {
  dispatch({
    type: LOAD_ALLOCATIONS,
  });
  try {
    const allocations = await backendClient.getAllocationsById(accessToken, idToken, id, parameters);

    if (withBenchmark) {
      const benchmark = await backendClient.getAllocationsById(accessToken, idToken, 1380, parameters);
      dispatch({
        type: LOAD_BENCHMARK_SUCCESS,
        payload: benchmark,
      });
    }

    dispatch({
      type: LOAD_ALLOCATIONS_SUCCESS,
      payload: allocations,
    });
    dispatch({
      type: SET_OPTIMIZATION_PARAMS,
      payload: parameters,
    });
  } catch (e) {
    dispatch({
      type: LOAD_ALLOCATIONS_ERROR,
      payload: e,
    });
  }
};

export const loadScenario = (accessToken: string, idToken: string, id: number, eventId: number) => async (dispatch: any) => {
  dispatch({
    type: LOAD_SCENARIO,
  });
  try {
    const scenario = await backendClient.getScenarioById(accessToken, idToken, id, eventId);
    dispatch({
      type: LOAD_SCENARIO_SUCCESS,
      payload: scenario,
    });
  } catch (e) {
    dispatch({
      type: LOAD_SCENARIO_ERROR,
      payload: e,
    });
  }
};

export const setEditStatRow = (val: number | undefined) => (dispatch: any) => {
  dispatch({
    type: SET_EDIT_STAT_ROW,
    payload: val,
  });
};

export const addPosition = (accessToken: string, idToken: string, position: any) => async (dispatch: any) => {
  dispatch({
    type: LOAD_PORTFOLIO,
  });
  try {
    await backendClient.createPosition(accessToken, idToken, position);
    const data = await (await backendClient.getPortfolioById(accessToken, idToken, position.portfolio)).json();
    const gainLoss = await (await backendClient.getPortfolioGainLossById(accessToken, idToken, position.portfolio)).json();
    const dailyIndex = await (await backendClient.getDailyIndex(accessToken, idToken)).json();
    const stats: any[] = [];
    const total: any = {};
    // eslint-disable-next-line no-unused-expressions
    data?.positions.forEach((pos: any) => {
      stats.push({
        id: pos.id,
        currency: pos.symbol,
        market_cap: pos.price_usd * pos.quantity,
        entry_price: pos.purchase_price_usd,
        quantity: pos.quantity,
        price_usd: pos.price_usd,
        purchased_at: pos.purchased_at,
        currencyId: pos.currency_pair.id,
        gainLossUSD: pos.gain_loss,
        gainLossPercent: pos.gain_loss_percentage,
        market_sentiment: pos.currency_pair.summary.opportunity_score,
        news_sentiment: pos.currency_pair.summary.news_sentiment,
        twitter_sentiment: pos.currency_pair.summary.twitter_sentiment,
        reddit_sentiment: pos.currency_pair.summary.reddit_sentiment,
      });
    });
    total.totalInUSD = gainLoss.current_value;
    total.gainLoss = {
      change_1d: {
        value: gainLoss.one_day_change,
        percent: gainLoss.one_day_perc.toFixed(2),
      },
      change_7d: {
        value: gainLoss.seven_day_change,
        percent: gainLoss.seven_day_perc.toFixed(2),
      },
      change_30d: {
        value: gainLoss.thirty_day_change,
        percent: gainLoss.thirty_day_perc.toFixed(2),
      },
    };
    total.dailyIndex = {
      change_1d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 2].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 2].value) / dailyIndex[dailyIndex.length - 2].value) * 100).toFixed(2),
      },
      change_7d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 7].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 7].value) / dailyIndex[dailyIndex.length - 7].value) * 100).toFixed(2),
      },
      change_30d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 30].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 30].value) / dailyIndex[dailyIndex.length - 30].value) * 100).toFixed(2),
      },
    };
    const gainLossStats = {
      ratios: {
        sharpeRatio: gainLoss.sharpe_ratio.toFixed(2),
        sortinoRatio: gainLoss.sortino_ratio.toFixed(2),
        portfolioBeta: gainLoss.portfolio_beta.toFixed(2),
      },
      returns: {
        cagr: gainLoss.cagr.toFixed(2),
        weeklyReturn: gainLoss.average_weekly_return.toFixed(2),
        monthlyReturn: gainLoss.average_monthly_return.toFixed(2),
      },
      risk1: {
        drawdown: gainLoss.max_drawdown.toFixed(2),
        monthlyCvar: gainLoss.monthly_cvar.toFixed(2),
        annualCvar: gainLoss.annual_cvar.toFixed(2),
      },
      risk2: {
        weeklyVolatility: gainLoss.monthly_volatility.toFixed(2),
        monthlyVolatility: gainLoss.monthly_volatility.toFixed(2),
        annualVolatility: gainLoss.annual_volatility.toFixed(2),
      },
      portfolioHistory: gainLoss.portfolio_value,
    };
    const vezgoIdentifiers = data.vezgo_identifiers.map((item: any) => item.vezgo_identifier);
    dispatch({
      type: LOAD_PORTFOLIO_SUCCESS,
      payload: total,
    });
    dispatch({
      type: STATS_GET_SUCCESS,
      payload: { stats, vezgoIdentifiers },
    });
    dispatch({
      type: GAIN_LOSS_GET_SUCCESS,
      payload: gainLossStats,
    });
  } catch (e) {
    dispatch({
      type: LOAD_PORTFOLIO_ERROR,
      payload: e,
    });
  }
};

export const editPosition = (accessToken: string, idToken: string, positionId: string, position: any) => async (dispatch: any) => {
  dispatch({
    type: LOAD_PORTFOLIO,
  });
  try {
    await backendClient.editPosition(accessToken, idToken, positionId, position);
    const data = await (await backendClient.getPortfolioById(accessToken, idToken, position.portfolio)).json();
    const gainLoss = await (await backendClient.getPortfolioGainLossById(accessToken, idToken, position.portfolio)).json();
    const dailyIndex = await (await backendClient.getDailyIndex(accessToken, idToken)).json();
    const stats: any[] = [];
    const total: any = {};
    // eslint-disable-next-line no-unused-expressions
    data?.positions.forEach((pos: any) => {
      stats.push({
        id: pos.id,
        currency: pos.symbol,
        market_cap: pos.price_usd * pos.quantity,
        entry_price: pos.purchase_price_usd,
        quantity: pos.quantity,
        price_usd: pos.price_usd,
        purchased_at: pos.purchased_at,
        currencyId: pos.currency_pair.id,
        gainLossUSD: pos.gain_loss,
        gainLossPercent: pos.gain_loss_percentage,
        market_sentiment: pos.currency_pair.summary.opportunity_score,
        news_sentiment: pos.currency_pair.summary.news_sentiment,
        twitter_sentiment: pos.currency_pair.summary.twitter_sentiment,
        reddit_sentiment: pos.currency_pair.summary.reddit_sentiment,
      });
    });
    total.totalInUSD = gainLoss.current_value;
    total.gainLoss = {
      change_1d: {
        value: gainLoss.one_day_change,
        percent: gainLoss.one_day_perc.toFixed(2),
      },
      change_7d: {
        value: gainLoss.seven_day_change,
        percent: gainLoss.seven_day_perc.toFixed(2),
      },
      change_30d: {
        value: gainLoss.thirty_day_change,
        percent: gainLoss.thirty_day_perc.toFixed(2),
      },
    };
    total.dailyIndex = {
      change_1d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 2].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 2].value) / dailyIndex[dailyIndex.length - 2].value) * 100).toFixed(2),
      },
      change_7d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 7].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 7].value) / dailyIndex[dailyIndex.length - 7].value) * 100).toFixed(2),
      },
      change_30d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 30].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 30].value) / dailyIndex[dailyIndex.length - 30].value) * 100).toFixed(2),
      },
    };
    const gainLossStats = {
      ratios: {
        sharpeRatio: gainLoss.sharpe_ratio.toFixed(2),
        sortinoRatio: gainLoss.sortino_ratio.toFixed(2),
        portfolioBeta: gainLoss.portfolio_beta.toFixed(2),
      },
      returns: {
        cagr: gainLoss.cagr.toFixed(2),
        weeklyReturn: gainLoss.average_weekly_return.toFixed(2),
        monthlyReturn: gainLoss.average_monthly_return.toFixed(2),
      },
      risk1: {
        drawdown: gainLoss.max_drawdown.toFixed(2),
        monthlyCvar: gainLoss.monthly_cvar.toFixed(2),
        annualCvar: gainLoss.annual_cvar.toFixed(2),
      },
      risk2: {
        weeklyVolatility: gainLoss.monthly_volatility.toFixed(2),
        monthlyVolatility: gainLoss.monthly_volatility.toFixed(2),
        annualVolatility: gainLoss.annual_volatility.toFixed(2),
      },
      portfolioHistory: gainLoss.portfolio_value,
    };
    const vezgoIdentifiers = data.vezgo_identifiers.map((item: any) => item.vezgo_identifier);
    dispatch({
      type: LOAD_PORTFOLIO_SUCCESS,
      payload: total,
    });
    dispatch({
      type: STATS_GET_SUCCESS,
      payload: { stats, vezgoIdentifiers },
    });
    dispatch({
      type: GAIN_LOSS_GET_SUCCESS,
      payload: gainLossStats,
    });
  } catch (e) {
    dispatch({
      type: LOAD_PORTFOLIO_ERROR,
      payload: e,
    });
  }
};

export const deletePosition = (accessToken: string, idToken: string, positionId: string, portfolioId: string) => async (dispatch: any) => {
  dispatch({
    type: LOAD_PORTFOLIO,
  });
  try {
    await backendClient.deletePosition(accessToken, idToken, positionId);
    const data = await (await backendClient.getPortfolioById(accessToken, idToken, portfolioId)).json();
    const gainLoss = await (await backendClient.getPortfolioGainLossById(accessToken, idToken, portfolioId)).json();
    const dailyIndex = await (await backendClient.getDailyIndex(accessToken, idToken)).json();
    const stats: any[] = [];
    const total: any = {};
    // eslint-disable-next-line no-unused-expressions
    data?.positions.forEach((pos: any) => {
      stats.push({
        id: pos.id,
        currency: pos.symbol,
        market_cap: pos.price_usd * pos.quantity,
        entry_price: pos.purchase_price_usd,
        quantity: pos.quantity,
        price_usd: pos.price_usd,
        purchased_at: pos.purchased_at,
        currencyId: pos.currency_pair.id,
        gainLossUSD: pos.gain_loss,
        gainLossPercent: pos.gain_loss_percentage,
        market_sentiment: pos.currency_pair.summary.opportunity_score,
        news_sentiment: pos.currency_pair.summary.news_sentiment,
        twitter_sentiment: pos.currency_pair.summary.twitter_sentiment,
        reddit_sentiment: pos.currency_pair.summary.reddit_sentiment,
      });
    });
    total.totalInUSD = gainLoss.current_value;
    total.gainLoss = {
      change_1d: {
        value: gainLoss.one_day_change,
        percent: gainLoss.one_day_perc.toFixed(2),
      },
      change_7d: {
        value: gainLoss.seven_day_change,
        percent: gainLoss.seven_day_perc.toFixed(2),
      },
      change_30d: {
        value: gainLoss.thirty_day_change,
        percent: gainLoss.thirty_day_perc.toFixed(2),
      },
    };
    total.dailyIndex = {
      change_1d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 2].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 2].value) / dailyIndex[dailyIndex.length - 2].value) * 100).toFixed(2),
      },
      change_7d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 7].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 7].value) / dailyIndex[dailyIndex.length - 7].value) * 100).toFixed(2),
      },
      change_30d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 30].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 30].value) / dailyIndex[dailyIndex.length - 30].value) * 100).toFixed(2),
      },
    };
    const gainLossStats = {
      ratios: {
        sharpeRatio: gainLoss.sharpe_ratio.toFixed(2),
        sortinoRatio: gainLoss.sortino_ratio.toFixed(2),
        portfolioBeta: gainLoss.portfolio_beta.toFixed(2),
      },
      returns: {
        cagr: gainLoss.cagr.toFixed(2),
        weeklyReturn: gainLoss.average_weekly_return.toFixed(2),
        monthlyReturn: gainLoss.average_monthly_return.toFixed(2),
      },
      risk1: {
        drawdown: gainLoss.max_drawdown.toFixed(2),
        monthlyCvar: gainLoss.monthly_cvar.toFixed(2),
        annualCvar: gainLoss.annual_cvar.toFixed(2),
      },
      risk2: {
        weeklyVolatility: gainLoss.monthly_volatility.toFixed(2),
        monthlyVolatility: gainLoss.monthly_volatility.toFixed(2),
        annualVolatility: gainLoss.annual_volatility.toFixed(2),
      },
      portfolioHistory: gainLoss.portfolio_value,
    };
    const vezgoIdentifiers = data.vezgo_identifiers.map((item: any) => item.vezgo_identifier);
    dispatch({
      type: LOAD_PORTFOLIO_SUCCESS,
      payload: total,
    });
    dispatch({
      type: STATS_GET_SUCCESS,
      payload: { stats, vezgoIdentifiers },
    });
    dispatch({
      type: GAIN_LOSS_GET_SUCCESS,
      payload: gainLossStats,
    });
  } catch (e) {
    dispatch({
      type: LOAD_PORTFOLIO_ERROR,
      payload: e,
    });
  }
};

export const createStrategy = (accessToken: string, idToken: string, strategy: any) => async (dispatch: any) => {
  try {
    await backendClient.createStrategy(accessToken, idToken, strategy);
    const newStrategyList = await (await backendClient.getStrategyList(accessToken, idToken)).json();
    dispatch({
      type: SET_STRATEGY_LIST,
      payload: newStrategyList?.results || [],
    });
    dispatch({
      type: SET_SELECTED_STRATEGY,
      payload: {
        value: newStrategyList.results.length - 1,
        label: strategy.strategy_name,
        id: newStrategyList.results[newStrategyList.results.length - 1].id,
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateStrategy = (accessToken: string, idToken: string, strategy: any, strategyId: number) => async (dispatch: any) => {
  try {
    await backendClient.updateStrategy(accessToken, idToken, strategy, strategyId);
    const newStrategyList = await (await backendClient.getStrategyList(accessToken, idToken)).json();
    dispatch({
      type: SET_STRATEGY_LIST,
      payload: newStrategyList?.results || [],
    });
  } catch (e) {
    console.log(e);
  }
};

export const createPortfolio = (accessToken: string, idToken: string, portfolio: any, positions: any[], identifiers: any[] = []) => async (dispatch: any) => {
  dispatch({
    type: LOAD_PORTFOLIO,
  });
  try {
    const newPortfolio = await backendClient.createPortfolio(accessToken, idToken, portfolio);
    const newPositions: any[] = [];
    positions.forEach((p: any) => {
      newPositions.push({
        ...p,
        portfolio: newPortfolio.id,
      });
    });
    newPositions.forEach(async (position: any) => {
      await backendClient.createPosition(accessToken, idToken, position);
    });
    identifiers.forEach(async (identifier) => {
      const newIdentifier = { ...identifier, portfolio_id: newPortfolio.id };
      await backendClient.createVezgoIdentifier(accessToken, idToken, newIdentifier);
    });
    const portfoliosList = await (await backendClient.getPortfolioList(accessToken, idToken)).json();
    const data = await (await backendClient.getPortfolioById(accessToken, idToken, newPortfolio.id)).json();
    const gainLoss = await (await backendClient.getPortfolioGainLossById(accessToken, idToken, newPortfolio.id)).json();
    const dailyIndex = await (await backendClient.getDailyIndex(accessToken, idToken)).json();
    const stats: any = [];
    const total: any = {};
    // eslint-disable-next-line no-unused-expressions
    data?.positions.forEach((pos: any) => {
      stats.push({
        id: pos.id,
        currency: pos.symbol,
        market_cap: pos.price_usd * pos.quantity,
        entry_price: pos.purchase_price_usd,
        quantity: pos.quantity,
        price_usd: pos.price_usd,
        purchased_at: pos.purchased_at,
        currencyId: pos.currency_pair.id,
        gainLossUSD: pos.gain_loss,
        gainLossPercent: pos.gain_loss_percentage,
        market_sentiment: pos.currency_pair.summary.opportunity_score,
        news_sentiment: pos.currency_pair.summary.news_sentiment,
        twitter_sentiment: pos.currency_pair.summary.twitter_sentiment,
        reddit_sentiment: pos.currency_pair.summary.reddit_sentiment,
      });
    });
    total.totalInUSD = gainLoss.current_value;
    total.gainLoss = {
      change_1d: {
        value: gainLoss.one_day_change,
        percent: gainLoss.one_day_perc?.toFixed(2),
      },
      change_7d: {
        value: gainLoss.seven_day_change,
        percent: gainLoss.seven_day_perc?.toFixed(2),
      },
      change_30d: {
        value: gainLoss.thirty_day_change,
        percent: gainLoss.thirty_day_perc?.toFixed(2),
      },
    };
    total.dailyIndex = {
      change_1d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 2].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 2].value) / dailyIndex[dailyIndex.length - 2].value) * 100)?.toFixed(2),
      },
      change_7d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 7].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 7].value) / dailyIndex[dailyIndex.length - 7].value) * 100)?.toFixed(2),
      },
      change_30d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 30].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 30].value) / dailyIndex[dailyIndex.length - 30].value) * 100)?.toFixed(2),
      },
    };
    const gainLossStats = {
      ratios: {
        sharpeRatio: gainLoss.sharpe_ratio?.toFixed(2),
        sortinoRatio: gainLoss.sortino_ratio?.toFixed(2),
        portfolioBeta: gainLoss.portfolio_beta?.toFixed(2),
      },
      returns: {
        cagr: gainLoss.cagr?.toFixed(2),
        weeklyReturn: gainLoss.average_weekly_return?.toFixed(2),
        monthlyReturn: gainLoss.average_monthly_return?.toFixed(2),
      },
      risk1: {
        drawdown: gainLoss.max_drawdown?.toFixed(2),
        monthlyCvar: gainLoss.monthly_cvar?.toFixed(2),
        annualCvar: gainLoss.annual_cvar?.toFixed(2),
      },
      risk2: {
        weeklyVolatility: gainLoss.monthly_volatility?.toFixed(2),
        monthlyVolatility: gainLoss.monthly_volatility?.toFixed(2),
        annualVolatility: gainLoss.annual_volatility?.toFixed(2),
      },
      portfolioHistory: gainLoss.portfolio_value,
    };
    const vezgoIdentifiers = data.vezgo_identifiers.map((item: any) => item.vezgo_identifier);
    dispatch({
      type: LOAD_PORTFOLIO_SUCCESS,
      payload: total,
    });
    dispatch({
      type: SET_PORTFOLIO_ID,
      payload: newPortfolio.id,
    });
    dispatch({
      type: SET_PORTFOLIO_LIST,
      payload: portfoliosList,
    });
    dispatch({
      type: STATS_GET_SUCCESS,
      payload: { stats, vezgoIdentifiers },
    });
    dispatch({
      type: GAIN_LOSS_GET_SUCCESS,
      payload: gainLossStats,
    });
  } catch (e) {
    dispatch({
      type: LOAD_PORTFOLIO_ERROR,
      payload: e,
    });
  }
};

export const changePortfolio = (accessToken: string, idToken: string, portfolioId: string) => async (dispatch: any) => {
  dispatch({
    type: LOAD_PORTFOLIO,
  });
  try {
    const data = await (await backendClient.getPortfolioById(accessToken, idToken, portfolioId)).json();
    const gainLoss = await (await backendClient.getPortfolioGainLossById(accessToken, idToken, portfolioId)).json();
    const dailyIndex = await (await backendClient.getDailyIndex(accessToken, idToken)).json();
    const stats: any = [];
    const total: any = {};
    // eslint-disable-next-line no-unused-expressions
    data?.positions.forEach((pos: any) => {
      stats.push({
        id: pos.id,
        currency: pos.symbol,
        market_cap: pos.price_usd * pos.quantity,
        entry_price: pos.purchase_price_usd,
        quantity: pos.quantity,
        price_usd: pos.price_usd,
        purchased_at: pos.purchased_at,
        currencyId: pos.currency_pair.id,
        gainLossUSD: pos.gain_loss,
        gainLossPercent: pos.gain_loss_percentage,
        market_sentiment: pos.currency_pair.summary.opportunity_score,
        news_sentiment: pos.currency_pair.summary.news_sentiment,
        twitter_sentiment: pos.currency_pair.summary.twitter_sentiment,
        reddit_sentiment: pos.currency_pair.summary.reddit_sentiment,
        source: pos.source ?? 'manual',
      });
    });
    total.totalInUSD = gainLoss.current_value;
    total.gainLoss = {
      change_1d: {
        value: gainLoss.one_day_change,
        percent: gainLoss.one_day_perc?.toFixed(2) || 'N/A',
      },
      change_7d: {
        value: gainLoss.seven_day_change,
        percent: gainLoss.seven_day_perc?.toFixed(2) || 'N/A',
      },
      change_30d: {
        value: gainLoss.thirty_day_change,
        percent: gainLoss.thirty_day_perc?.toFixed(2) || 'N/A',
      },
    };
    total.dailyIndex = {
      change_1d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 2].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 2].value) / dailyIndex[dailyIndex.length - 2].value) * 100).toFixed(2),
      },
      change_7d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 7].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 7].value) / dailyIndex[dailyIndex.length - 7].value) * 100).toFixed(2),
      },
      change_30d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 30].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 30].value) / dailyIndex[dailyIndex.length - 30].value) * 100).toFixed(2),
      },
    };
    const gainLossStats = {
      ratios: {
        sharpeRatio: gainLoss.sharpe_ratio?.toFixed(2) || 'N/A',
        sortinoRatio: gainLoss.sortino_ratio?.toFixed(2) || 'N/A',
        portfolioBeta: gainLoss.portfolio_beta?.toFixed(2) || 'N/A',
      },
      returns: {
        cagr: gainLoss.cagr?.toFixed(2) || 'N/A',
        weeklyReturn: gainLoss.average_weekly_return?.toFixed(2) || 'N/A',
        monthlyReturn: gainLoss.average_monthly_return?.toFixed(2) || 'N/A',
      },
      risk1: {
        drawdown: gainLoss.max_drawdown?.toFixed(2) || 'N/A',
        monthlyCvar: gainLoss.monthly_cvar?.toFixed(2) || 'N/A',
        annualCvar: gainLoss.annual_cvar?.toFixed(2) || 'N/A',
      },
      risk2: {
        weeklyVolatility: gainLoss.monthly_volatility?.toFixed(2) || 'N/A',
        monthlyVolatility: gainLoss.monthly_volatility?.toFixed(2) || 'N/A',
        annualVolatility: gainLoss.annual_volatility?.toFixed(2) || 'N/A',
      },
      portfolioHistory: gainLoss.portfolio_value,
    };
    const vezgoIdentifiers = data.vezgo_identifiers.map((item: any) => item.vezgo_identifier);
    dispatch({
      type: LOAD_PORTFOLIO_SUCCESS,
      payload: total,
    });
    dispatch({
      type: STATS_GET_SUCCESS,
      payload: { stats, vezgoIdentifiers },
    });
    dispatch({
      type: GAIN_LOSS_GET_SUCCESS,
      payload: gainLossStats,
    });
    dispatch({
      type: SET_PORTFOLIO_ID,
      payload: portfolioId,
    });
  } catch (e) {
    dispatch({
      type: LOAD_PORTFOLIO_ERROR,
      payload: e,
    });
  }
};

export const loadPortfolio = (accessToken: string, idToken: string) => async (dispatch: any) => {
  dispatch({
    type: LOAD_PORTFOLIO,
  });
  try {
    const portfoliosList = await (await backendClient.getPortfolioList(accessToken, idToken)).json();
    const portfolioId = portfoliosList.results[0].id === 2 ? portfoliosList.results[2].id : portfoliosList.results[0].id;
    const strategyList = await (await backendClient.getStrategyList(accessToken, idToken)).json();
    const data = await (await backendClient.getPortfolioById(accessToken, idToken, portfolioId)).json();
    const gainLoss = await (await backendClient.getPortfolioGainLossById(accessToken, idToken, portfolioId)).json();
    const dailyIndex = await (await backendClient.getDailyIndex(accessToken, idToken)).json();
    const stats: any = [];
    const total: any = {};
    // eslint-disable-next-line no-unused-expressions
    data?.positions.forEach((pos: any) => {
      stats.push({
        id: pos.id,
        currency: pos.symbol,
        market_cap: pos.price_usd * pos.quantity,
        entry_price: pos.purchase_price_usd,
        quantity: pos.quantity,
        price_usd: pos.price_usd,
        purchased_at: pos.purchased_at,
        currencyId: pos.currency_pair.id,
        gainLossUSD: pos.gain_loss,
        gainLossPercent: pos.gain_loss_percentage,
        market_sentiment: pos.currency_pair.summary.opportunity_score,
        news_sentiment: pos.currency_pair.summary.news_sentiment,
        twitter_sentiment: pos.currency_pair.summary.twitter_sentiment,
        reddit_sentiment: pos.currency_pair.summary.reddit_sentiment,
        source: pos.source ?? 'manual',
      });
    });
    total.totalInUSD = gainLoss.current_value;
    total.gainLoss = {
      change_1d: {
        value: gainLoss.one_day_change,
        percent: gainLoss.one_day_perc.toFixed(2),
      },
      change_7d: {
        value: gainLoss.seven_day_change,
        percent: gainLoss.seven_day_perc.toFixed(2),
      },
      change_30d: {
        value: gainLoss.thirty_day_change,
        percent: gainLoss.thirty_day_perc.toFixed(2),
      },
    };
    total.dailyIndex = {
      change_1d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 2].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 2].value) / dailyIndex[dailyIndex.length - 2].value) * 100).toFixed(2),
      },
      change_7d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 7].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 7].value) / dailyIndex[dailyIndex.length - 7].value) * 100).toFixed(2),
      },
      change_30d: {
        value: dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 30].value,
        percent: (((dailyIndex[dailyIndex.length - 1].value - dailyIndex[dailyIndex.length - 30].value) / dailyIndex[dailyIndex.length - 30].value) * 100).toFixed(2),
      },
    };
    const gainLossStats = {
      ratios: {
        sharpeRatio: gainLoss.sharpe_ratio.toFixed(2),
        sortinoRatio: gainLoss.sortino_ratio.toFixed(2),
        portfolioBeta: gainLoss.portfolio_beta.toFixed(2),
      },
      returns: {
        cagr: gainLoss.cagr.toFixed(2),
        weeklyReturn: gainLoss.average_weekly_return.toFixed(2),
        monthlyReturn: gainLoss.average_monthly_return.toFixed(2),
      },
      risk1: {
        drawdown: gainLoss.max_drawdown.toFixed(2),
        monthlyCvar: gainLoss.monthly_cvar.toFixed(2),
        annualCvar: gainLoss.annual_cvar.toFixed(2),
      },
      risk2: {
        weeklyVolatility: gainLoss.monthly_volatility.toFixed(2),
        monthlyVolatility: gainLoss.monthly_volatility.toFixed(2),
        annualVolatility: gainLoss.annual_volatility.toFixed(2),
      },
      portfolioHistory: gainLoss.portfolio_value,
    };
    const vezgoIdentifiers = data.vezgo_identifiers.map((item: any) => item.vezgo_identifier);
    dispatch({
      type: SET_PORTFOLIO_LIST,
      payload: portfoliosList,
    });
    dispatch({
      type: SET_STRATEGY_LIST,
      payload: strategyList?.results || [],
    });
    dispatch({
      type: LOAD_PORTFOLIO_SUCCESS,
      payload: total,
    });
    dispatch({
      type: STATS_GET_SUCCESS,
      payload: { stats, vezgoIdentifiers },
    });
    dispatch({
      type: GAIN_LOSS_GET_SUCCESS,
      payload: gainLossStats,
    });
    dispatch({
      type: SET_PORTFOLIO_ID,
      payload: portfolioId,
    });
  } catch (e) {
    dispatch({
      type: LOAD_PORTFOLIO_ERROR,
      payload: e,
    });
  }
};

export const loadGainLoss = (accessToken: string, idToken: string, portfolioId: string, isPaper: boolean) => async (dispatch: any) => {
  dispatch({
    type: LOAD_PORTFOLIO,
  });
  try {
    const gainLoss = await (await backendClient.getAggregateGainLoss(accessToken, idToken, portfolioId, isPaper)).json();
    const total: any = {};
    total.totalInUSD = gainLoss.current_value;
    total.gainLoss = {
      change_1d: {
        value: gainLoss.one_day_change,
        percent: gainLoss.one_day_perc.toFixed(2),
      },
      change_7d: {
        value: gainLoss.seven_day_change,
        percent: gainLoss.seven_day_perc.toFixed(2),
      },
      change_30d: {
        value: gainLoss.thirty_day_change,
        percent: gainLoss.thirty_day_perc.toFixed(2),
      },
    };
    const gainLossStats = {
      ratios: {
        sharpeRatio: gainLoss.sharpe_ratio.toFixed(2),
        sortinoRatio: gainLoss.sortino_ratio.toFixed(2),
        portfolioBeta: gainLoss.portfolio_beta.toFixed(2),
      },
      returns: {
        cagr: gainLoss.cagr.toFixed(2),
        weeklyReturn: gainLoss.average_weekly_return.toFixed(2),
        monthlyReturn: gainLoss.average_monthly_return.toFixed(2),
      },
      risk1: {
        drawdown: gainLoss.max_drawdown.toFixed(2),
        monthlyCvar: gainLoss.monthly_cvar.toFixed(2),
        annualCvar: gainLoss.annual_cvar.toFixed(2),
      },
      risk2: {
        weeklyVolatility: gainLoss.monthly_volatility.toFixed(2),
        monthlyVolatility: gainLoss.monthly_volatility.toFixed(2),
        annualVolatility: gainLoss.annual_volatility.toFixed(2),
      },
      portfolioHistory: gainLoss.portfolio_value,
    };
    dispatch({
      type: LOAD_PORTFOLIO_SUCCESS,
      payload: total,
    });
    dispatch({
      type: GAIN_LOSS_GET_SUCCESS,
      payload: gainLossStats,
    });
  } catch (e) {
    dispatch({
      type: LOAD_PORTFOLIO_ERROR,
      payload: e,
    });
  }
};

const defaultParams = {
  interval: 36000000,
  dedup: 1,
  coin: ['ADA', 'BNB', 'BTC', 'DASH', 'EOS', 'ETC', 'ETH', 'LTC', 'TRX', 'XEM', 'XLM', 'XMR', 'XRP', 'ZEC'],
  fields: ['_id', 'id', 'collected_at', 'description', 'title', 'MESentimentModel', 'selftext', 'source', 'url', 'user_name', 'created_at', 'author'],
};

export const loadNews = (accessToken: string, idToken: string, coin: string[] = []) => async (dispatch: any) => {
  dispatch({ type: LOAD_NEWS });
  try {
    if (coin.length > 0) {
      defaultParams.coin = coin;
    }
    const data = await backendClient.getPortfolioNews(accessToken, idToken, defaultParams);
    const news: any[] = [];
    data.forEach((d: any) => {
      news.push({
        description: d.description,
        id: d._id,
        source: d.source.name,
        title: d.title,
        url: d.url,
        sentiment: d.MESentimentModel,
        date: d.collected_at,
      });
    });
    dispatch({
      type: LOAD_NEWS_SUCCESS,
      payload: news,
    });
  } catch (e) {
    dispatch({
      type: LOAD_NEWS_ERROR,
      payload: e,
    });
  }
};

export const loadReddit = (accessToken: string, idToken: string, coin: any[] = []) => async (dispatch: any) => {
  dispatch({ type: LOAD_REDDIT });
  try {
    if (coin.length > 0) {
      defaultParams.coin = coin;
    }
    const data = await backendClient.getPortfolioReddit(accessToken, idToken, defaultParams);
    const posts: any[] = [];
    data.forEach((d: any) => {
      posts.push({
        id: d.id,
        author: d.author,
        selftext: d.selftext,
        MESentimentModel: d.MESentimentModel,
        collected_at: d.collected_at,
      });
    });
    const action = {
      type: LOAD_REDDIT_SUCCESS,
      payload: posts,
    };
    dispatch(action);
  } catch (e) {
    dispatch({
      type: LOAD_REDDIT_ERROR,
      payload: e,
    });
  }
};

export const loadTweets = (accessToken: string, idToken: string, coin: any[] = []) => async (dispatch: any) => {
  dispatch({ type: LOAD_TWEETS });
  try {
    if (coin.length > 0) {
      defaultParams.coin = coin;
    }
    const data = await backendClient.getPortfolioTweets(accessToken, idToken, defaultParams);
    const tweets: any[] = [];
    data.forEach((d: any) => {
      tweets.push({
        id: d.id,
        user_name: d.user_name,
        text: d.description,
        MESentimentModel: d.MESentimentModel,
        created_at: d.created_at,
      });
    });
    const action = {
      type: LOAD_TWEETS_SUCCESS,
      payload: tweets,
    };
    dispatch(action);
  } catch (e) {
    dispatch({
      type: LOAD_TWEETS_ERROR,
      payload: e,
    });
  }
};
