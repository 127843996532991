/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useState, useEffect, useLayoutEffect, useRef,
} from 'react';
import {
  MdOutlineKeyboardArrowDown,
  MdFilterListAlt,
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  // MdOutlineAlarm,
} from 'react-icons/md';
import { BsCircle, BsCircleFill } from 'react-icons/bs';
// import { AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import BubbleChart from './BubbleChart';
// import LockedOverlay from './LockedOverlay';
// import BubbleChartMobile from './BubbleChartMobile';
import { simpleHelper, marketCapRankRawFilter, marketCapRawFilter } from '../../screener/utils/helpers';
import { colors } from '../themes/common';
import PortfolioSelector from '../../portfolio/components/Total/PortfolioSelector';
import Client from '../../utils/api/backend/clients';
import { RootState } from '../../store';

interface BubbleHeatmapProps {
  data: any;
  // eslint-disable-next-line react/no-unused-prop-types
  group?: string;
}

interface TabProps {
  active: boolean
}

interface OptionProps {
  display: string,
  value: string
  xValue?: string
}

interface TabBackProps {
  display: string
  value: string
  type: string
  options?: OptionProps[]
  posText?: string
  negText?: string
  chartPosText?: string
  chartNegText?: string
  strongPosText?: string
  strongNegText?: string
  xValue?: string
  yValue: string
  xRange: number[]
  yRange: number[]
  realRange: number[]
  showTopBars?: boolean
}

interface FilterOptionProps {
  display: string,
  value: any
}

interface FilterProps {
  display: string,
  type: string,
  label?: string,
  options: FilterOptionProps[]
}

const BubbleHeatmapContainer = styled.div`
  background: ${({ theme }) => theme.site.bannerBackground};
  border: 1px solid ${({ theme }) => theme.site.bannerBorder};
  border-radius: 8px;
  margin-right: 14px;
  min-width: calc(67% - 14px);
  min-height: 514px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0;
  }
  @media screen and (max-width: 950px) {
    padding: 0px !important;
    display: none !important;
  }
`;

const TabsSection = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  width: 100%;  
  & > div:first-child {
    margin-right: 24px;
  }
  & .select__control {
    min-height: auto;
    padding: 3.5px 10px 3px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    background: ${({ theme }) => theme.site.background};
    border: ${({ theme }) => `1px solid ${theme.site.bannerBorder}`};
    white-space: nowrap;
    &:hover {
      opacity: 0.7;
    }
    & .select__single-value {
      font-size: 13px;
    }
    & .select__indicator {
      background-color: transparent;
      width: 17px;
      height: 17px;
      color: white;
      margin: 0;
      & svg {
        height: 13px;
      }
    }
    & .select__value-container {
      padding: 0;
    }
  }
  @media screen and (max-width: 950px) {
    & > div {
      margin-top: 0;
    }
    display: none;
  }
`;

/* const TabMobileSection = styled.div`
  display: none;
  @media screen and (max-width: 950px) {
    display: flex !important;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 20px;
    & > div:first-child {
      width: 65%;
    }
  }
`; */

const SelectorContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
`;

const Tab = styled.div<TabProps>`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-right: 24px;
  padding: 3.5px 10px 3px;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ active }) => (active ? 'white' : '')};
  background: ${({ theme, active }) => (active ? theme.colors.accent : theme.site.background)};
  border: ${({ theme, active }) => (active ? 'none' : `1px solid ${theme.site.bannerBorder}`)};
  white-space: nowrap;

  &:hover {
    opacity: 0.7;
  }

  @media screen and (max-width: 1500px) {
    margin-bottom: 10px;
  }
`;

const OptionsContainer = styled.div<any>`
  flex-direction: column;
  margin-top: 40px;
  background: ${({ theme }) => theme.site.bannerBackground};
  border: 1px solid ${({ theme }) => theme.site.bannerBorder};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: absolute;
  z-index: 999;
  width: 93%;
  min-width: 189px;
  max-height: 193px;
  overflow-y: auto;
  @media screen and (max-width: 950px) {
    right: ${({ dots }) => (dots ? '10px' : '5px')};
  }
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 16px;
  color: ${({ theme }) => theme.site.color};
  border-bottom: 1px solid ${({ theme }) => theme.site.bannerBorder};
  &:last-child {
    margin-bottom: 0px !important;
    border-bottom: 0px !important;
  }
  &:hover {
    background: ${({ theme }) => theme.site.selectorTextHover};
  }
`;

const Filter = styled.div<any>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;
  margin-bottom: ${({ opened }) => (opened ? '0px' : '4px')};
  &:last-child {
    margin-bottom: 0px !important;
  }
`;

const FilterOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 38px;
  &:first-child {
    padding-top: 0px !important;
  }
`;

const Circle = styled.div<TabProps>`
  background: ${({ active }) => (active ? 'white' : '#55B1EC')};
  width: 17px;
  height: 17px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
  color: ${({ active }) => (active ? 'black' : 'white')};;
`;

/* const CountBox = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.site.bannerBackground};
  border: 1px solid ${({ theme }) => theme.site.bannerBorder};
  border-radius: 6px;
  padding: 8px 5px;
  margin-top: 16px;
  @media screen and (max-width: 950px) {
    display: none;
  }
`; */

/* const CountMobileBox = styled.div`
  display: none;
  @media screen and (max-width: 950px) {
    display: block;
    float: right;
    background: ${({ theme }) => theme.site.bannerBackground};
    border: 1px solid ${({ theme }) => theme.site.bannerBorder};
    border-radius: 6px;
    padding: 6px 8px;
    margin-right: 20px;
  }
`; */

/* const CountNumber = styled.div<any>`
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  margin-right: 5px;
  color: ${({ positive }) => (positive ? '#6BD7A4' : '#F9423D')};
  @media screen and (max-width: 950px) {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-right: 10px;
  }
`;

const CountText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  max-width: 150px;
  @media screen and (max-width: 950px) {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
`; */

const FilterLabel = styled.div`
  font-size: 10px;
  margin-left: 50px;
  font-style: italic;
  margin-bottom: 10px;
`;

/* const NextSignalContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;
  @media screen and (max-width: 950px) {
    display: none;
  }
`; */

const SectionTitleContainer = styled.div`
  background: ${({ theme }) => theme.site.sectionTitleBackground};
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 21px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.site.bannerBorder};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

const SectionTitle = styled.div`
  color: ${({ theme }) => theme.site.color};
  font-size: 16px;
  font-weight: 800;
`;

/* const NextSignalBox = styled.div`
  display: flex;
  background: ${({ theme }) => theme.site.bannerBackground};
  border: 1px solid ${({ theme }) => theme.site.bannerBorder};
  border-radius: 8px;
  padding: 8px 12px;
`; */

const TRADING_SIGNAL = {
  display: 'Trading Signals',
  value: 'trading_signal',
  type: 'tab',
  xValue: 'opportunity_score',
  yValue: 'percent_change_1h',
  xRange: [0, 1.0],
  realRange: [0, 1],
  yRange: [-100.0, 100.0],
  posText: 'Buy Signals',
  negText: 'Sell Signals',
  strongPosText: 'Trending Up',
  strongNegText: 'Trending Down',
  chartNegText: 'SELL',
  chartPosText: 'BUY',
  showTopBars: true,
};

const GAINERS = {
  display: 'Gainers/Losers',
  value: 'gainers',
  type: 'tab',
  xValue: 'percent_change_1h',
  yValue: 'percent_change_24h',
  realRange: [-100.0, 100.0],
  xRange: [-100, 100],
  yRange: [-100, 100],
  posText: 'Gainers',
  negText: 'Losers',
  strongPosText: 'Trending Up',
  strongNegText: 'Trending Down',
  chartNegText: 'DOWN',
  chartPosText: 'UP',
  showTopBars: true,
};

const MOST_ACTIVE = {
  display: 'Relative Volume',
  value: 'most_active',
  type: 'tab',
  xValue: 'volume_indicator',
  yValue: 'percent_change_1h',
  realRange: [0, 5.0],
  xRange: [-1, 6.0],
  yRange: [-100, 100],
  posText: 'Above Average Vol.',
  negText: 'Below Average Vol.',
  strongPosText: 'Trending Up',
  strongNegText: 'Trending Down',
  chartPosText: 'ABOVE AVG.',
  chartNegText: 'BELOW AVG.',
  showTopBars: true,
};

const MOVING_AVERAGES = {
  display: 'Moving Averages',
  value: 'moving_averages',
  type: 'select',
  yValue: 'percent_change_1h',
  realRange: [-0.6, 0.6],
  xRange: [-1.0, 1.0],
  yRange: [-100, 100],
  options: [
    {
      display: 'SMA 20',
      value: 'sma20_vsprice',
      xValue: 'sma20_vsprice',
    },
    {
      display: 'SMA 50',
      value: 'sma50_vsprice',
      xValue: 'sma50_vsprice',
    },
    {
      display: 'SMA 200',
      value: 'sma200_vsprice',
      xValue: 'sma200_vsprice',
    },
  ],
  posText: 'Above SMA',
  negText: 'Below SMA',
  strongPosText: 'Trending Up',
  strongNegText: 'Trending Down',
  chartNegText: 'BELOW PRICE',
  chartPosText: 'ABOVE PRICE',
  showTopBars: true,
};

const EMAS = {
  display: 'EMAs',
  value: 'emas',
  type: 'select',
  yValue: 'percent_change_1h',
  realRange: [-0.6, 0.6],
  xRange: [-1.0, 1.0],
  yRange: [-100, 100],
  options: [
    {
      display: 'EMA 16',
      value: 'ema12_vsprice',
      xValue: 'ema12_vsprice',
    },
    {
      display: 'EMA 26',
      value: 'ema26_vsprice',
      xValue: 'ema26_vsprice',
    },
  ],
  posText: 'Above EMA',
  negText: 'Below EMA',
  strongPosText: 'Trending Up',
  strongNegText: 'Trending Down',
  chartNegText: 'BELOW PRICE',
  chartPosText: 'ABOVE PRICE',
  showTopBars: true,
};

const RSI = {
  display: 'RSI',
  value: 'rsi',
  type: 'tab',
  xValue: 'rsi',
  yValue: 'percent_change_1h',
  realRange: [0, 100.0],
  xRange: [0.0, 100.0],
  yRange: [-100, 100],
  posText: 'Overbought',
  negText: 'Oversold',
  strongPosText: 'Trending Up',
  strongNegText: 'Trending Down',
  showTopBars: false,
  chartNegText: 'Oversold',
  chartPosText: 'Overbought',
};

const SOCIAL_SENTIMENT = {
  display: 'Social Sentiment',
  value: 'social_sentiment',
  type: 'tab',
  xValue: 'twitter_sentiment',
  yValue: 'percent_change_1h',
  realRange: [0, 1.0],
  xRange: [0, 1.0],
  yRange: [-100, 100],
  posText: 'Bullish Sentiment',
  negText: 'Bearish Sentiment',
  chartNegText: 'BEARISH',
  chartPosText: 'BULLISH',
  strongPosText: 'Trending Up',
  strongNegText: 'Trending Down',
  showTopBars: true,
};

/* const FILTERS_TAB = {
  display: 'Filters',
  value: 'filter',
  type: 'filter',
  yValue: 'market_cap_rank',
  realRange: [0, 1],
  xRange: [-0.1, 1.1],
  yRange: [-100, 2500],
}; */

const MARKET_CAP_FILTER = {
  display: 'Market Cap',
  type: 'MKT_CAP',
  options: [
    { display: 'Over $1B', value: { max: null, min: 1e9 } },
    { display: '$0.5B to $1B', value: { max: 1e9, min: 5e8 } },
    { display: '$0.1B to $0.5B', value: { max: 5e8, min: 1e8 } },
    { display: '$0.05B to $0.1B', value: { max: 1e8, min: 5e7 } },
    { display: 'Less than $0.05B', value: { max: 5e7, min: null } },
  ],
};

const MARKET_CAP_RANK_FILTER = {
  display: 'Market Cap Rank',
  type: 'MKT_CAP_RANK',
  label: '*Stablecoins Excluded',
  options: [
    { display: '1-25', value: 25 },
    { display: '1-50', value: 50 },
    { display: '1-100', value: 100 },
    { display: '1-200', value: 200 },
    { display: '1-500', value: 500 },
  ],
};

/* const INDUSTRY_FILTER = {
  display: 'Industry',
  type: 'INDUSTRY',
  options: [
    { display: 'Gaming', value: 'Gaming' },
    { display: 'Social Media', value: 'Social Media' },
    { display: 'FinTech', value: 'FinTech' },
    { display: 'Exchanges', value: 'Exchanges' },
    { display: 'Advertising', value: 'Advertising' },
    { display: 'Payment Provider', value: 'Payment Provider' },
    { display: 'E-Commerce', value: 'E-Commerce' },
    { display: 'Real Estate', value: 'Real Estate' },
    { display: 'Healthcare', value: 'Healthcare' },
  ],
}; */

const SECTOR_FILTER = {
  display: 'Sector',
  type: 'SECTOR',
  options: [
    { display: 'Application Tokens', value: 'Application Tokens' },
    { display: 'Staking Instruments and Decentralized Autonomous', value: 'Staking Instruments and Decentralized Autonomous' },
    { display: 'Organizations', value: 'Organizations' },
    { display: 'General Purpose', value: 'General Purpose' },
    { display: 'Smart Contract Platforms', value: 'Smart Contract Platforms' },
    { display: 'Protocol Interoperability', value: 'Protocol Interoperability' },
    { display: 'Security Tokens', value: 'Security Tokens' },
    { display: 'Stable and Asset Backed', value: 'Stable and Asset Backed' },
    { display: 'Distributed Computation & Storage', value: 'Distributed Computation & Storage' },
    { display: 'Privacy-Preserving', value: 'Privacy-Preserving' },
  ],
};

const SUB_SECTOR_FILTER = {
  display: 'Sub Sector',
  type: 'SUB_SECTOR',
  options: [
    { display: 'Exchange Tokens', value: 'Exchange Tokens' },
    { display: 'DAO Governance Shares', value: 'DAO Governance Shares' },
    { display: 'Digital Art, Collectibles & Non-Fungible Tokens', value: 'Digital Art, Collectibles & Non-Fungible Tokens' },
    { display: 'Retail Payments & Point of Sale', value: 'Retail Payments & Point of Sale' },
    { display: 'Security-Focused Smart Contract Platforms', value: 'Security-Focused Smart Contract Platforms' },
    { display: 'Intermediary Interoperability Solutions', value: 'Intermediary Interoperability Solutions' },
    { display: 'Advertising Platforms', value: 'Advertising Platforms' },
    { display: 'Scalability-Focused Smart Contract Platforms', value: 'Scalability-Focused Smart Contract Platforms' },
    { display: 'Tokenized Derivatives', value: 'Tokenized Derivatives' },
    { display: 'Fiat Collateralized Stablecoins', value: 'Fiat Collateralized Stablecoins' },
    { display: 'Standard Monetary Protocols', value: 'Standard Monetary Protocols' },
    { display: 'Cloud-Computing Intermediary', value: 'Cloud-Computing Intermediary' },
    { display: 'General Purpose Smart Contract Platforms', value: 'General Purpose Smart Contract Platforms' },
    { display: 'Video Games', value: 'Video Games' },
    { display: 'Supply Chain Management Platforms', value: 'Supply Chain Management Platforms' },
    { display: 'Algorithmic, Non-Collateralized Stablecoins', value: 'Algorithmic, Non-Collateralized Stablecoins' },
    { display: 'Smart Contract Oracles', value: 'Smart Contract Oracles' },
    { display: 'Gift Cards, Rebates and Coupons', value: 'Gift Cards, Rebates and Coupons' },
    { display: 'Crowdsourced Data Storage Platform', value: 'Crowdsourced Data Storage Platform' },
    { display: 'Data Notarization Platforms', value: 'Data Notarization Platforms' },
    { display: 'Privacy-Focused Smart Contract Platforms', value: 'Privacy-Focused Smart Contract Platforms' },
    { display: 'Default Privacy Coins', value: 'Default Privacy Coins' },
    { display: 'Optional Privacy Coins', value: 'Optional Privacy Coins' },
    { display: 'Tokenized Hedge Funds & Venture Capital Funds', value: 'Tokenized Hedge Funds & Venture Capital Funds' },
    { display: 'Gaming Services', value: 'Gaming Services' },
    { display: 'Tokenized Indexes', value: 'Tokenized Indexes' },
    { display: 'Tokenized Wallet Applications', value: 'Tokenized Wallet Applications' },
    { display: 'Atomic Swap Solutions', value: 'Atomic Swap Solutions' },
    { display: 'Staking Token', value: 'Staking Token' },
    { display: 'Gas Fee Tokens', value: 'Gas Fee Tokens' },
    { display: 'Social Networks', value: 'Social Networks' },
    { display: 'Decentralized Identity Solutions', value: 'Decentralized Identity Solutions' },
    { display: 'Crypto Collateralized Stablecoins', value: 'Crypto Collateralized Stablecoins' },
    { display: 'Real Asset Collateralized Coins', value: 'Real Asset Collateralized Coins' },
    { display: 'Crowdsourced Computation Platform', value: 'Crowdsourced Computation Platform' },
    { display: 'Prediction Markets', value: 'Prediction Markets' },
    { display: 'Commodity Collaterilized Coins', value: 'Commodity Collaterilized Coins' },
    { display: 'Accounts Receivable Factoring Platforms', value: 'Accounts Receivable Factoring Platforms ' },
    { display: 'Tokenized Debt', value: 'Tokenized Debt' },
  ],
};

const backendClient = new Client();

const BubbleHeatmap: React.FC<BubbleHeatmapProps> = (props: BubbleHeatmapProps) => {
  const { data } = props;
  const tabs: TabBackProps[] = [TRADING_SIGNAL, GAINERS, MOST_ACTIVE, MOVING_AVERAGES,
    EMAS, RSI, SOCIAL_SENTIMENT];
  /* const tabsMobileOptions: TabBackProps[] = [TRADING_SIGNAL, GAINERS, MOST_ACTIVE,
    MOVING_AVERAGES, EMAS, RSI, SOCIAL_SENTIMENT]; */
  // const [tabsMobile, setTabsMobile] = useState<TabBackProps[]>([TRADING_SIGNAL, FILTERS_TAB]);
  const filters: FilterProps[] = [
    MARKET_CAP_RANK_FILTER, MARKET_CAP_FILTER, SECTOR_FILTER, SUB_SECTOR_FILTER,
  ];
  const accountState: any = useSelector((state: RootState) => state.account);
  const {
    access_token: accessToken, id_token: idToken,
  } = accountState;
  const containerRef = useRef<any>();
  const [active, setActive] = useState<string>('trading_signal');
  const [labelText, setLabelText] = useState('TRADING SIGNAL');
  const [opened, setOpened] = useState<string>('');
  const [chartData, setChartData] = useState([]);
  const [chosenOption, setChosenOption] = useState<string>('');
  const [xRange, setXRange] = useState<number[]>([0.0, 1.0]);
  const [yRange, setYRange] = useState<number[]>([0.0, 2500.0]);
  const [activeFilters, setActiveFilters] = useState<string[]>(['1-25']);
  const [openedFilters, setOpenedFilters] = useState<string[]>(['MKT_CAP_RANK']);
  // const [posCount, setPosCount] = useState<number>(0);
  // const [negCount, setNegCount] = useState<number>(0);
  // const [strongPosCount, setStrongPosCount] = useState<number>(0);
  // const [strongNegCount, setStrongNegCount] = useState<number>(0);
  // const [posText, setPosText] = useState<string>('');
  // const [negText, setNegText] = useState<string>('');
  // const [strongPosText, setStrongPosText] = useState<string>('');
  // const [strongNegText, setStrongNegText] = useState<string>('');
  const [chartNegText, setChartNegText] = useState<string>('');
  const [chartPosText, setChartPosText] = useState<string>('');
  const [tabType, setTabType] = useState<string>('');
  const [radius, setRadius] = useState<number>(7.5);
  const [signalCount, setSignalCount] = useState<number>(30);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [showTopBars, setShowTopBars] = useState(true);
  const [fullSceenMode, setFullScreenMode] = useState(false);
  const [filterCurrencies, setFilterCurrencies] = useState<string[]>([]);

  useLayoutEffect(() => {
    const resize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    if (window) {
      window.addEventListener('resize', resize);
      resize();
      return () => window.removeEventListener('resize', resize);
    }
    return () => {};
  }, []);

  const exitHandler = () => {
    if (!document.fullscreenElement) {
      setFullScreenMode(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);
    return () => {
      document.removeEventListener('fullscreenchange', exitHandler);
      document.removeEventListener('webkitfullscreenchange', exitHandler);
      document.removeEventListener('mozfullscreenchange', exitHandler);
      document.removeEventListener('MSFullscreenChange', exitHandler);
    };
  }, []);

  /*   const makeFullscreen = () => {
    setFullScreenMode(true);
    const elem = containerRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    setFullScreenMode(false);
    document.exitFullscreen();
  }; */

  const getTabByValue = (val: string) => tabs.find((t) => t.value === val);
  const getFilterByValue = (val: string) => filters
    .find((f) => f.options.find((opt) => opt.display === val));

  const getCircleColor = (signal: number) => {
    if (signal <= -0.1) {
      return '#F9423D';
    }
    if (signal <= -0.05) {
      return '#FF6763';
    }
    if (signal <= -0.025) {
      return '#F37A77';
    }
    if (signal <= 0) {
      return '#F99E9B';
    }
    if (signal <= 0.025) {
      return '#9FE0C1';
    }
    if (signal <= 0.05) {
      return '#82DEB3';
    }
    if (signal <= 0.1) {
      return '#5FD39D';
    }
    return '#53C891';
  };

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (signalCount > 0) {
        setSignalCount(signalCount - 1);
      }
      if (signalCount === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    setSignalCount(30);
  }, [data]);

  useEffect(() => {
    const tmp: any = [];
    let filteredData: any = [...data.filter((d: any) => d.market_cap > 0 && (d.market_cap_rank && d.market_cap_rank > 0))];
    const tab = getTabByValue(active);
    let radiusScale = 0.95;
    setRadius(30);
    if (filterCurrencies?.length > 0) {
      filteredData = filteredData.filter((coinData:any) => filterCurrencies.some((currency: string) => currency === coinData.currency));
    }
    if (activeFilters.length > 0) {
      activeFilters.forEach((af) => {
        const filter = getFilterByValue(af);
        const filterToApply = {
          type: filter?.type,
          value: filter?.options.find((opt) => opt.display === af)?.value,
        };
        if (filter?.type === 'SUB_SECTOR' || filter?.type === 'SECTOR') {
          filteredData = simpleHelper(filteredData, filterToApply);
        } else if (filter?.type === 'MKT_CAP_RANK') {
          filteredData = marketCapRankRawFilter(filteredData, filterToApply);
          if (filterToApply.value === 50) {
            radiusScale = 0.96;
            // setRadius(15);
          }
          if (filterToApply.value === 100) {
            radiusScale = 0.97;
            // setRadius(10);
          }
          if (filterToApply.value === 200) {
            radiusScale = 0.98;
            // setRadius(8.5);
          }
          if (filterToApply.value === 500) {
            radiusScale = 0.99;
            // setRadius(8.5);
          }
        } else {
          filteredData = marketCapRawFilter(filteredData, filterToApply);
        }
      });
    }
    const tradingDomain = new Set<string>();
    if (chosenOption) {
      filteredData.forEach((c: any) => {
        let x = c[chosenOption];
        if (x > tab?.realRange[1]!) {
          x = tab?.realRange[1];
        }
        if (x < tab?.realRange[0]!) {
          x = tab?.realRange[0];
        }
        const realX = x;
        /* if (tab?.value === 'trading_signal') {
          if (x < 0.25) {
            x = 'Strong Sell';
            tradingDomain.add('Strong Sell');
          }
          if (x >= 0.25 && x < 0.5) {
            x = 'Sell';
            tradingDomain.add('Sell');
          }
          if (x >= 0.5 && x < 0.75) {
            x = 'Buy';
            tradingDomain.add('Buy');
          }
          if (x >= 0.75) {
            x = 'Strong Buy';
            tradingDomain.add('Strong Buy');
          }
        } */
        tmp.push({
          cx: x,
          cy: c[tab?.yValue!],
          symbol: c.currency,
          fullName: c.name,
          price: c.price_usd,
          change: c.percent_change_24h,
          signal: c.opportunity_score,
          rank: c.market_cap_rank,
          color: getCircleColor(c[tab?.yValue!]),
          realX,
          tradingDomain,
        });
      });
    } else {
      filteredData.forEach((c: any) => {
        let x = c[tab?.xValue!];
        if (x > tab?.realRange[1]!) {
          x = tab?.realRange[1];
        }
        if (x < tab?.realRange[0]!) {
          x = tab?.realRange[0];
        }
        const realX = x;
        /* if (tab?.value === 'trading_signal') {
          if (x < 0.25) {
            x = 'Strong Sell';
            tradingDomain.add('Strong Sell');
          }
          if (x >= 0.25 && x < 0.5) {
            x = 'Sell';
            tradingDomain.add('Sell');
          }
          if (x >= 0.5 && x < 0.75) {
            x = 'Buy';
            tradingDomain.add('Buy');
          }
          if (x >= 0.75) {
            x = 'Strong Buy';
            tradingDomain.add('Strong Buy');
          }
        } */
        tmp.push({
          cx: x,
          cy: c[tab?.yValue!],
          symbol: c.currency,
          fullName: c.name,
          price: c.price_usd,
          change: c.percent_change_24h,
          signal: c.opportunity_score,
          rank: c.market_cap_rank,
          color: getCircleColor(c[tab?.yValue!]),
          realX,
          tradingDomain,
        });
      });
    }
    // let count = 0;
    // let negCounter = 0;
    let cxMin = tab?.realRange[1]!;
    let cxMax = 0;
    let cyMin = tab?.yRange[1]!;
    let cyMax = tab?.yRange[0]!;
    /* if (tab?.value === 'trading_signal') {
      tmp.forEach((t: any) => {
        if (t.cx.includes('Buy')) {
          count += 1;
        }
      }); */
    if (tab?.value === 'rsi') {
      tmp.forEach((t: any) => {
        if (t.cx > cxMax) {
          cxMax = t.cx;
        }
        if (t.cx < cxMin) {
          cxMin = t.cx;
        }
        if (t.cy > cyMax) {
          cyMax = t.cy;
        }
        if (t.cy < cyMin) {
          cyMin = t.cy;
        }
      });
    } else {
      tmp.forEach((t: any) => {
        if (t.cx > cxMax) {
          cxMax = t.cx;
        }
        if (t.cx < cxMin) {
          cxMin = t.cx;
        }
        if (t.cy > cyMax) {
          cyMax = t.cy;
        }
        if (t.cy < cyMin) {
          cyMin = t.cy;
        }
      });
    }
    const tmp2: any = [];
    tmp.sort((a: any, b: any) => a.rank - b.rank);
    tmp.forEach((t: any, index: number) => {
      if (index === 0) {
        tmp2.push({
          realRadius: radius,
          ...t,
        });
      } else {
        tmp2.push({
          realRadius: radius * radiusScale ** index,
          ...t,
        });
      }
    });
    // setPosCount(count);
    // setNegCount(tab?.value === 'rsi' ? negCounter : tmp2.length - count);
    const xMax = Math.abs(cxMax) > Math.abs(cxMin) ? cxMax : Math.abs(cxMin);
    const yMax = Math.abs(cyMax) > Math.abs(cyMin) ? cyMax : Math.abs(cyMin);
    const xMargin = xMax * 0.15;
    const yMargin = yMax * 0.25;
    setTabType(tab?.value!);
    // setPosText(tab?.posText!);
    // setNegText(tab?.negText!);
    // setStrongNegText(tab?.strongNegText!);
    // setStrongPosText(tab?.strongPosText!);
    setChartPosText(tab?.chartPosText!);
    setChartNegText(tab?.chartNegText!);
    if (width <= 950) {
      setXRange([cxMin - 0.05, cxMax + 0.05]);
    } else if (tab?.value === 'rsi' || tab?.value === 'trading_signal') {
      setXRange(tab?.xRange!);
    } else {
      setXRange([-xMax - xMargin, xMax + xMargin]);
    }
    // let count = 0;
    // let nCount = 0;
    if (tab?.value === 'trading_signal') {
      tmp2.forEach((t: any) => {
        if (t.cx > (tab?.xRange![0] + tab?.xRange![1]) / 2) {
          // count += 1;
        } else {
          // nCount += 1;
        }
      });
    } else if (tab?.value === 'rsi') {
      tmp2.forEach((t: any) => {
        if (t.cx >= 70) {
          // count += 1;
        }
        if (t.cx <= 30) {
          // nCount += 1;
        }
      });
    } else {
      tmp2.forEach((t: any) => {
        if (t.cx > (-xMax - xMargin + xMax + xMargin) / 2) {
          // count += 1;
        } else {
          // nCount += 1;
        }
      });
    }
    // setPosCount(count);
    // setNegCount(nCount);
    setYRange([-yMax - yMargin, yMax + yMargin]);
    setShowTopBars(tab?.showTopBars!);
    setChartData(tmp2);
  }, [active, chosenOption, activeFilters, radius, data, filterCurrencies]);

  const handleTabClick = (tab: TabBackProps) => {
    if (tab.type === 'select') {
      if (opened === tab.value) {
        setOpened('');
      } else {
        setChosenOption(tab.options![0].value);
        setOpened(tab.value);
      }
    } else if (tab.type === 'filter') {
      if (opened === tab.value) {
        setOpened('');
      } else {
        setOpened(tab.value);
      }
      return;
    } else {
      setOpened('');
      setChosenOption('');
    }
    if (tab.value === 'trading_signal') {
      setLabelText('TRADING SIGANL');
    }
    if (tab.value === 'gainers') {
      setLabelText('24HR');
    }
    if (tab.value === 'most_active') {
      setLabelText('RELATIVE VOLUME');
    }
    if (tab.value === 'moving_averages') {
      setLabelText('SMA');
    }
    if (tab.value === 'emas') {
      setLabelText('EMA');
    }
    if (tab.value === 'rsi') {
      setLabelText('RSI');
    }
    if (tab.value === 'social_sentiment') {
      setLabelText('SOCIAL SENTIMENT');
    }
    setActive(tab.value);
  };

  const handleOptionClick = (option: OptionProps) => {
    setChosenOption(option.value);
  };

  const handleFilterTab = (val: string) => {
    if (opened === val) {
      setOpened('');
    } else {
      setOpened(val);
    }
  };

  const handleFilterClick = (filter: FilterProps) => {
    let tmp = [...openedFilters];
    if (tmp.includes(filter.type)) {
      tmp = tmp.filter((t) => t !== filter.type);
    } else {
      tmp.push(filter.type);
    }
    setOpenedFilters(tmp);
    handleFilterTab(filter.display);
  };

  const handleFilterOptionClick = (option: FilterOptionProps) => {
    let tmp = [...activeFilters];
    if (tmp.includes(option.display)) {
      tmp = tmp.filter((t) => t !== option.display);
    } else {
      const filter = getFilterByValue(option.display);
      // @ts-ignore
      if (filter?.type !== 'SECTOR' || filter?.type !== 'SUB_SECTOR') {
        tmp = tmp.filter((t) => filter?.type !== getFilterByValue(t)?.type);
      }
      tmp.push(option.display);
    }
    setActiveFilters(tmp);
  };

  const selectPortfolio = async (id: any) => {
    if (id === null) {
      setFilterCurrencies([]);
      return;
    }
    const portfolioData = await (await backendClient.getPortfolioById(accessToken, idToken, id)).json();
    const currencies = portfolioData?.positions?.map((pos: any) => pos?.currency_pair?.symbol);
    setFilterCurrencies(currencies);
    setActiveFilters(['1-25']);
    setOpenedFilters(['MKT_CAP_RANK']);
  };

  /*   const handleOpen = () => {
    if (opened === 'dots') {
      setOpened('');
    } else {
      setOpened('dots');
    }
  }; */

  /*   const handleMobileOption = (tab: TabBackProps) => {
    setTabsMobile([tab, FILTERS_TAB]);
    handleTabClick(tab);
  }; */

  const renderOptions = (tab: any) => tab.options?.map((itm: OptionProps) => (
    <Option key={`option_${itm.display}`} onClick={() => handleOptionClick(itm)}>
      <div style={{ marginRight: '12px', marginTop: '5px' }}>
        {
          chosenOption === itm.value
            ? <BsCircleFill color={colors.accent} />
            : <BsCircle color={colors.accent} />
        }
      </div>
      {itm.display}
    </Option>
  ));

  const renderFilterOptions = (tab: any) => tab.options?.map((itm: OptionProps) => (
    <Option key={`option_${itm.display}`} onClick={() => handleFilterOptionClick(itm)}>
      <div style={{ marginRight: '12px', marginTop: '5px' }}>
        {
          activeFilters.includes(itm.display)
            ? <BsCircleFill color={colors.accent} />
            : <BsCircle color={colors.accent} />
        }
      </div>
      {itm.display}
    </Option>
  ));

  const renderFilters = () => filters.map((filter: FilterProps) => (
    <>
      <Filter key={`filter_${filter.type}`} opened={openedFilters.includes(filter.type)} onClick={() => handleFilterClick(filter)}>
        <div style={{ marginTop: '3.5px' }}>
          {!openedFilters.includes(filter.type)
            ? <MdKeyboardArrowRight size={24} />
            : <MdKeyboardArrowDown size={24} />}
        </div>
        {filter.display}
      </Filter>
      {openedFilters.includes(filter.type) && filter.label
        ? (
          <FilterLabel>
            {filter.label}
          </FilterLabel>
        )
        : null}
      {openedFilters.includes(filter.type)
        ? (filter.options.map((opt: FilterOptionProps) => (
          <FilterOption key={opt.display} onClick={() => handleFilterOptionClick(opt)}>
            <div style={{ marginRight: '12px', marginTop: '5px' }}>
              {
              activeFilters.includes(opt.display)
                ? <BsCircleFill color={colors.accent} />
                : <BsCircle color={colors.accent} />
            }
            </div>
            {opt.display}
          </FilterOption>
        )))
        : null}
    </>
  ));

  /*   const renderMobileTabs = () => tabsMobile.map((tab) => {
    if (tab.type === 'tab') {
      return (
        <Tab key={tab.display} active={tab.value === active} onClick={() => handleTabClick(tab)}>
          {tab.display}
        </Tab>
      );
    }
    if (tab.type === 'filter') {
      return (
        <SelectorContainer>
          <Tab active={activeFilters.length > 0} onClick={() => handleTabClick(tab)}>
            <MdFilterListAlt size={17} color={activeFilters.length > 0 ? 'white' : ''} />
            {tab.display}
            <Circle active={activeFilters.length > 0}>
              {activeFilters.length}
            </Circle>
          </Tab>
          {opened === tab.value
            ? (
              <OptionsContainer style={{ width: '100%', maxHeight: '293px' }}>
                {renderFilters()}
              </OptionsContainer>
            ) : null}
        </SelectorContainer>
      );
    }
    return (
      <SelectorContainer>
        <Tab
          active={tab.value === active}
          onClick={() => handleTabClick(tab)}
          style={{ justifyContent: 'space-between' }}>
          {tab.display}
          <MdOutlineKeyboardArrowDown size={17} />
        </Tab>
        {opened === tab.value
          ? (
            <OptionsContainer>
              {renderOptions(tab)}
            </OptionsContainer>
          ) : null}
      </SelectorContainer>
    );
  }); */

  const renderTabs = () => tabs.map((tab) => {
    if (tab.type === 'tab') {
      return (
        <Tab active={tab.value === active} key={tab.display} onClick={() => handleTabClick(tab)}>
          {tab.display}
        </Tab>
      );
    }
    if (tab.type === 'filter') {
      return (
        <SelectorContainer>
          <Tab active={activeFilters.length > 0} onClick={() => handleTabClick(tab)}>
            <MdFilterListAlt size={17} color={activeFilters.length > 0 ? 'white' : ''} />
            {tab.display}
            <Circle active={activeFilters.length > 0}>
              {activeFilters.length}
            </Circle>
          </Tab>
          {opened === tab.value
            ? (
              <OptionsContainer style={{ width: '125%', maxHeight: '293px' }}>
                {renderFilters()}
              </OptionsContainer>
            ) : null}
          {/* {!['Tier1', 'Tier2'].includes(group as string) && <LockedOverlay />} */}
        </SelectorContainer>
      );
    }
    return (
      <SelectorContainer>
        <Tab active={tab.value === active} onClick={() => handleTabClick(tab)} style={{ justifyContent: 'space-between' }}>
          {tab.display}
          <MdOutlineKeyboardArrowDown size={17} />
        </Tab>
        {opened === tab.value
          ? (
            <OptionsContainer>
              {renderOptions(tab)}
            </OptionsContainer>
          ) : null}
      </SelectorContainer>
    );
  });

  return (
    <>
      {/*       <NextSignalMobileContainer>
        <div style={{ fontSize: '12px', lineHeight: '24px', marginRight: '11px' }}>
          Next Trading Signal
        </div>
        <NextSignalBox>
          <MdOutlineAlarm size={24} />
          <div style={{ fontSize: '12px', lineHeight: '24px', marginLeft: '10px' }}>
            {signalCount < 10 ? `0:0${signalCount}` : `0:${signalCount}`}
          </div>
        </NextSignalBox>
      </NextSignalMobileContainer> */}
      <BubbleHeatmapContainer ref={containerRef}>
        <SectionTitleContainer>
          <SectionTitle>Market Insights</SectionTitle>
          {/*  {!fullSceenMode ? (
            <div style={{ cursor: 'pointer' }} onClick={() => makeFullscreen()}>
              <AiOutlineFullscreen size={25} />
            </div>
          ) : (
            <div style={{ cursor: 'pointer' }} onClick={() => exitFullscreen()}>
              <AiOutlineFullscreenExit size={25} />
            </div>
          )} */}
        </SectionTitleContainer>
        <div style={{ padding: 10, paddingBottom: 0 }}>
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px',
          }}
          >
            <TabsSection>
              <PortfolioSelector width="165px" nullPortfolioOption onSelect={(id) => selectPortfolio(id)} />
              {filters.map((tab) => (
                <SelectorContainer>
                  <Tab active={openedFilters.includes(tab.type) || !!tab.options.find((opt) => activeFilters.includes(opt.display))} onClick={() => handleFilterClick(tab)} style={{ justifyContent: 'space-between' }}>
                    {tab.display}
                    <MdOutlineKeyboardArrowDown size={17} />
                  </Tab>
                  {opened === tab.display
                    ? (
                      <OptionsContainer>
                        {renderFilterOptions(tab)}
                      </OptionsContainer>
                    ) : null}
                </SelectorContainer>
              ))}
            </TabsSection>
          </div>
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px',
          }}
          >
            <TabsSection>
              {renderTabs()}
            </TabsSection>
            {/*  <NextSignalContainer>
              <div style={{
                fontSize: '16px', lineHeight: '24px',
                marginRight: '11px', whiteSpace: 'nowrap', overflow: 'hidden',
              }}
              >
                Next Trading Signal
              </div>
              <NextSignalBox>
                <MdOutlineAlarm size={24} />
                <div style={{ fontSize: '16px', lineHeight: '24px', marginLeft: '10px' }}>
                  {signalCount < 10 ? `0:0${signalCount}` : `0:${signalCount}`}
                </div>
              </NextSignalBox>
            </NextSignalContainer> */}
          </div>
          {/* <TabMobileSection>
          {renderMobileTabs()}
          <SelectorContainer>
            <div onClick={() => handleOpen()}>
              <BsThreeDotsVertical size={24} />
            </div>
            {opened === 'dots'
              ? (
                <OptionsContainer dots style={{ minWidth: '160px' }}>
                  {tabsMobileOptions.map((tab) => (
                    <Option key={tab.display} onClick={() => handleMobileOption(tab)}>
                      {tab.display}
                    </Option>
                  ))}
                </OptionsContainer>
              ) : null}
          </SelectorContainer>
        </TabMobileSection> */}
          <BubbleChart
            data={chartData}
            height={fullSceenMode ? height - 200 : 360}
            width={1150}
            xRange={xRange}
            yRange={yRange}
            type={tabType}
            text={labelText}
            chartNegText={chartNegText}
            chartPosText={chartPosText}
            showTopBars={showTopBars}
          />
          {/* <CountMobileBox>
        <CountNumber positive>
          {posCount}
        </CountNumber>
        <CountText>
          {posText}
        </CountText>
      </CountMobileBox> */}
          {/* {width <= 950
          ? (
            <BubbleChartMobile
              data={chartData}
              height={1000}
              width={324}
              xRange={xRange}
              yRange={yRange}
              type={tabType}
              chartNegText={chartNegText}
              chartPosText={chartPosText}
              showTopBars={showTopBars}
            />
          ) : null} */}
          {/* <CountMobileBox style={{ marginBottom: '20px' }}>
        <CountNumber positive={false}>
          {negCount}
        </CountNumber>
        <CountText>
          {negText}
        </CountText>
      </CountMobileBox> */}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
              TRADING SIGNALS
            </div> */}
            {/* <div style={{
              display: 'flex', justifyContent: 'space-between', marginLeft: '20px', width: '100%',
            }}
            >
              <CountBox>
                <CountNumber positive={false}>
                  {negCount}
                </CountNumber>
                <CountText>
                  {negText}
                </CountText>
              </CountBox>
              <CountBox>
                <CountNumber positive>
                  {posCount}
                </CountNumber>
                <CountText>
                  {posText}
                </CountText>
              </CountBox>
            </div> */}
            {/* <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '50%',
            marginLeft: '18px',
            marginRight: '20px',
          }}
          >
            <CountBox>
              <CountNumber positive>
                {posCount}
              </CountNumber>
              <CountText>
                {posText}
              </CountText>
            </CountBox>
            <CountBox>
              <CountNumber positive>
                {strongPosCount}
              </CountNumber>
              <CountText>
                {strongPosText}
              </CountText>
            </CountBox>
          </div> */}
          </div>
        </div>
      </BubbleHeatmapContainer>
    </>
  );
};

BubbleHeatmap.defaultProps = {
  group: 'Tier0',
};

export default BubbleHeatmap;
