/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { setCookie } from 'nookies';
import { AccountActionTypes } from './actionTypes';
import {
  APPLY_FILTERS_FAILURE,
  APPLY_FILTERS_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CHANGE_SUBSCRIPTION_FAILURE,
  CHANGE_SUBSCRIPTION_SUCCESS,
  FETCH_EXCHANGES_FAILURE,
  FETCH_EXCHANGES_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  LOGOUT,
  OAUTH_LOGOUT,
  REDIRECT_TO_BILLING,
  REFRESH_TOKEN_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_LOADING,
  SIGN_IN_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_UP_LOADING,
  SIGN_UP_SUCCESS,
  SUBSCRIBE_FAILURE,
  SUBSCRIBE_SUCCESS,
  UPDATE_PAYMENT_FAILURE,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_FAILURE,
  DELETE_ACCOUNT_FAILURE,
} from './constants';
import {
  ErrorPayload,
  SignInSuccessPayload,
  StripeCreditCard,
  SubscriptionBody,
} from './models';
import Client from '../utils/api/backend/clients';

const backendClient = new Client();

export function redirectToBilling(): AccountActionTypes {
  return {
    type: REDIRECT_TO_BILLING,
    payload: {},
  };
}

export function test(): string {
  return '';
}

export function fetchExchangesSuccess(payload: object): AccountActionTypes {
  return {
    type: FETCH_EXCHANGES_SUCCESS,
    payload,
  };
}

export function fetchExchangesFailure(payload: object): AccountActionTypes {
  return {
    type: FETCH_EXCHANGES_FAILURE,
    payload,
  };
}

export const fetchExchanges = () => (dispatch: any) => {
  backendClient.getExchanges()
    .then((res) => res.json())
    .then((resJSON) => {
      (resJSON.error)
        ? dispatch(fetchExchangesFailure({ error: 'Something went wrong. Please try again.' }))
        : dispatch(fetchExchangesSuccess(resJSON));
    })
    .catch(() => {
      dispatch(fetchExchangesFailure({ error: 'Something went wrong. Please try again.' }));
    });
};

export function signInSuccess(payload: SignInSuccessPayload): AccountActionTypes {
  return {
    type: SIGN_IN_SUCCESS,
    payload,
  };
}

export function signInFailure(payload: ErrorPayload): AccountActionTypes {
  return {
    type: SIGN_IN_FAILURE,
    payload,
  };
}

export function signInLoading(payload: boolean): AccountActionTypes {
  return {
    type: SIGN_IN_LOADING,
    payload,
  };
}

export const signIn = (email: string, password: string) => (dispatch: any) => {
  dispatch(signInLoading(true));

  const body = {
    username: email.toLowerCase(),
    password,
  };

  fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}/signin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((resJSON) => {
      if (resJSON.error) {
        dispatch(signInFailure({ error: resJSON.error }));
      } else {
        dispatch(signInSuccess(resJSON));
        dispatch(fetchExchanges());
        setCookie(null, 'access_token', resJSON.access_token, {
          maxAge: resJSON.expires_at - resJSON.validated_at,
          path: '/',
        });
        setCookie(null, 'id_token', resJSON.id_token, {
          maxAge: resJSON.expires_at - resJSON.validated_at,
          path: '/',
        });
      }
      dispatch(signInLoading(false));
    })
    .catch((error) => {
      console.error(error);
      dispatch(signInFailure({ error: 'Sign in failed. Please try again or contact us.' }));
      dispatch(signInLoading(false));
    });
};

const parseOauthCallback = async (hash: string) => {
  const raw = hash.split('&').map((item) => item.split('='));

  const params: Map<string, string> = new Map<string, string>();
  // eslint-disable-next-line no-restricted-syntax
  for (const param of raw) {
    const [k, v] = param;
    params.set(k, v);
  }

  const idToken = params.get('id_token') ?? '';
  const accessToken = params.get('access_token') ?? '';
  if (idToken === '' || accessToken === '') {
    return null;
  }

  try {
    const response = await backendClient.verifyTokens(idToken, accessToken);
    if (!response.ok) {
      return null;
    }

    const jsonResp = await response.json();
    return jsonResp;
  } catch (e) {
    return null;
  }
};

export const verifyTokens = (hash: string) => async (dispatch: any) => {
  dispatch(signInLoading(true));

  const result = await parseOauthCallback(hash);
  if (!result) {
    dispatch(signInFailure({ error: 'Sign in failed. Please try again or contact us.' }));
    dispatch(signInLoading(false));
    return;
  }

  dispatch(signInSuccess(result)) && dispatch(fetchExchanges());
  dispatch(signInLoading(false));
};

export function subscribeSuccess(payload: object): AccountActionTypes {
  return {
    type: SUBSCRIBE_SUCCESS,
    payload,
  };
}

export function subscribeFailure(payload: ErrorPayload): AccountActionTypes {
  return {
    type: SUBSCRIBE_FAILURE,
    payload,
  };
}

export function signUpLoading(payload: boolean): AccountActionTypes {
  return {
    type: SIGN_UP_LOADING,
    payload,
  };
}

export const subscribe = (
  user: any, creditCard: any, plan: string, coupon: string, password?: string,
) => (dispatch: any, getState: any) => {
  dispatch(signUpLoading(true));

  const { email, first_name: firstName, last_name: lastName } = user;
  const { id_token: idToken, access_token: accessToken } = getState().account;

  const customer = {
    email,
    first_name: firstName,
    last_name: lastName,
  };

  const error = 'Something went wrong. Please try again.';

  backendClient.subscribe(customer, creditCard, '', plan, accessToken, idToken, coupon)
    .then((res) => {
      if (!res.ok) {
        dispatch(subscribeFailure({ error }));
        return null;
      }
      return res.json();
    })
    .then((resJson) => {
      if (resJson) {
        dispatch(subscribeSuccess({ plan }));
        dispatch(signIn(email, password || ''));
        dispatch(signUpLoading(false));
      }
    })
    .catch((e) => {
      dispatch(subscribeFailure(e));
      signUpLoading(false);
    });
};

export function signUpSuccess(payload: object): AccountActionTypes {
  return {
    type: SIGN_UP_SUCCESS,
    payload,
  };
}

export function signUpFailure(payload: ErrorPayload): AccountActionTypes {
  return {
    type: SIGN_UP_FAILURE,
    payload,
  };
}

export const signUp = (
  email: string, password: string, subscribing: boolean, subscribeObj: any,
) => (dispatch: any) => {
  dispatch(signUpLoading(true));

  const body = {
    username: email.toLowerCase(),
    password,
  };

  fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((resJSON) => {
      dispatch(signUpLoading(false));
      if (resJSON.error) {
        dispatch(signUpFailure({ error: resJSON.error }));
        return;
      }

      if (subscribing) {
        const {
          user, creditCard, plan, discountCode = '',
        } = subscribeObj;
        dispatch(subscribe(user, creditCard, plan, discountCode, password));
      } else {
        dispatch(signIn(email, password));
      }
    })
    .catch(() => {
      dispatch(signUpFailure({ error: 'Sign up Failed. Please try again or contact us.' }));
      dispatch(signUpLoading(false));
    });
};

export function forgotPasswordSuccess(payload: object): AccountActionTypes {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload,
  };
}

export function forgotPasswordFailure(payload: ErrorPayload): AccountActionTypes {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload,
  };
}

export const forgotPassword = (email: string) => (dispatch: any) => {
  const body = {
    username: email.toLowerCase(),
  };

  fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}/forgot_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((resJSON) => {
      dispatch(forgotPasswordSuccess(resJSON));
    })
    .catch((error) => {
      dispatch(forgotPasswordFailure(error));
    });
};

export function resetPasswordSuccess(payload: object): AccountActionTypes {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload,
  };
}

export function resetPasswordFailure(payload: ErrorPayload): AccountActionTypes {
  return {
    type: RESET_PASSWORD_FAILURE,
    payload,
  };
}

// eslint-disable-next-line max-len
export const resetPassword = (email: string, password: string, code: string) => (dispatch: any) => {
  dispatch(signUpLoading(true));

  const body = {
    username: email.toLowerCase(),
    password,
    code,
  };

  fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}/change_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((resJSON) => {
      resJSON.error
        ? dispatch(resetPasswordFailure({ error: resJSON.error }))
        : dispatch(resetPasswordSuccess(resJSON));
    })
    .catch(() => {
      dispatch(resetPasswordFailure({ error: 'Something went wrong. Please try again.' }));
    });
};

export function applyFiltersSuccess(payload: object): AccountActionTypes {
  return {
    type: APPLY_FILTERS_SUCCESS,
    payload,
  };
}

export function applyFiltersFailure(payload: ErrorPayload): AccountActionTypes {
  return {
    type: APPLY_FILTERS_FAILURE,
    payload,
  };
}

export const applyFilters = (filters: string[]) => (dispatch: any, getState: any) => {
  const { user, id_token: idToken, access_token: accessToken } = getState().account;
  const { id: pk } = user;
  const body = {
    first_name: 'John',
    last_name: 'Doe',
    exchange_filter: filters,
  };

  const bodyJSON = JSON.stringify(body);

  backendClient.updateUser(pk, accessToken, idToken, bodyJSON)
    .then((res) => {
      if (!res.ok) {
        dispatch(applyFiltersFailure({ error: 'Something went wrong. Please try again.' }));
        return null;
      }
      return res.json();
    })
    .then((resJSON) => {
      if (resJSON) dispatch(applyFiltersSuccess(resJSON));
    })
    .catch(() => {
      dispatch(applyFiltersFailure({ error: 'Something went wrong. Please try again.' }));
    });
};

export function updateUserSuccess(payload: ErrorPayload): AccountActionTypes {
  return {
    type: UPDATE_USER_SUCCESS,
    payload,
  };
}

export function updateUserFailure(payload: ErrorPayload): AccountActionTypes {
  return {
    type: UPDATE_USER_FAILURE,
    payload,
  };
}

export const updateUser = (
  firstName: string,
  lastName: string,
) => (dispatch: any, getState: any) => {
  const { user, id_token: idToken, access_token: accessToken } = getState().account;
  const { id: pk } = user;
  const body = {
    first_name: firstName,
    last_name: lastName,
  };

  const error = 'Something went wrong. Please try again.';
  const bodyJSON = JSON.stringify(body);

  backendClient.updateUser(pk, accessToken, idToken, bodyJSON)
    .then((res) => {
      if (!res.ok) {
        dispatch(updateUserFailure({ error }));
        return null;
      }
      return res.json();
    })
    .then((resJSON) => {
      if (resJSON) dispatch(updateUserSuccess(resJSON));
    })
    .catch(() => {
      dispatch(updateUserFailure({ error }));
    });
};

export function changeSubscriptionSuccess(payload: ErrorPayload): AccountActionTypes {
  return {
    type: CHANGE_SUBSCRIPTION_SUCCESS,
    payload,
  };
}

export function changeSubscriptionFailure(payload: ErrorPayload): AccountActionTypes {
  return {
    type: CHANGE_SUBSCRIPTION_FAILURE,
    payload,
  };
}

export const changeSubscription = (
  subscriptionBody: SubscriptionBody,
) => (dispatch: any, getState: any) => {
  const { id_token: idToken, access_token: accessToken } = getState().account;
  const error = 'Something went wrong. Please try again.';

  backendClient.upgradeSubscription(subscriptionBody, accessToken, idToken)
    .then((res) => {
      if (!res.ok) {
        dispatch(changeSubscriptionFailure({ error }));
        return null;
      }
      return res.json();
    })
    .then((resJson) => {
      if (resJson) {
        dispatch(changeSubscriptionSuccess(resJson));
        return resJson;
      }
      return null;
    })
    .catch((e) => dispatch(changeSubscriptionFailure(e)));
};

export function cancelSubscriptionSuccess(payload: ErrorPayload): AccountActionTypes {
  return {
    type: CANCEL_SUBSCRIPTION_SUCCESS,
    payload,
  };
}

export function cancelSubscriptionFailure(payload: ErrorPayload): AccountActionTypes {
  return {
    type: CANCEL_SUBSCRIPTION_FAILURE,
    payload,
  };
}

export const cancelSubscription = () => (dispatch: any, getState: any) => {
  const { id_token: idToken, access_token: accessToken } = getState().account;

  const error = 'Something went wrong. Please try again.';

  backendClient.cancelSubscription(accessToken, idToken)
    .then((res) => {
      if (!res.ok) {
        dispatch(cancelSubscriptionFailure({ error }));
        return null;
      }
      return res.json();
    })
    .then((resJson) => {
      if (resJson) {
        dispatch(cancelSubscriptionSuccess(resJson));
      }
    })
    .catch((e) => dispatch(cancelSubscriptionFailure(e)));
};

export function updatePaymentSuccess(payload: ErrorPayload): AccountActionTypes {
  return {
    type: UPDATE_PAYMENT_SUCCESS,
    payload,
  };
}

export function updatePaymentFailure(payload: ErrorPayload): AccountActionTypes {
  return {
    type: UPDATE_PAYMENT_FAILURE,
    payload,
  };
}

export const updatePayment = (creditCard: StripeCreditCard) => (dispatch: any, getState: any) => {
  const { id_token: idToken, access_token: accessToken } = getState().account;

  const error = 'Something went wrong. Please try again.';

  backendClient.updatePayment(creditCard, accessToken, idToken)
    .then((res) => {
      if (!res.ok) {
        dispatch(updatePaymentFailure({ error }));
        return null;
      }
      return res.json();
    })
    .then((resJson) => {
      if (resJson) {
        dispatch(updatePaymentSuccess(resJson));
        return resJson;
      }
      return null;
    })
    .catch((e) => dispatch(updatePaymentFailure(e)));
};

export function logout(): AccountActionTypes {
  return {
    type: LOGOUT,
  };
}

export function oauthLogout(): AccountActionTypes {
  return {
    type: OAUTH_LOGOUT,
  };
}

export function refreshTokenSuccess(payload: SignInSuccessPayload): AccountActionTypes {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload,
  };
}

export const refreshToken = (token: string) => (dispatch: any, getState: any) => {
  const { id_token: idToken, access_token: accessToken } = getState().account;

  backendClient.refreshToken(token, accessToken, idToken)
    .then((res) => {
      if (!res.ok) {
        dispatch(logout());
        return null;
      }
      return res.json();
    })
    .then((resJSON) => {
      if (resJSON) dispatch(refreshTokenSuccess(resJSON));
    })
    .catch(() => {
      dispatch(logout());
    });
};

export function validateCouponSuccess(payload: ErrorPayload): AccountActionTypes {
  return {
    type: VALIDATE_COUPON_SUCCESS,
    payload,
  };
}

export function validateCouponFailure(payload: ErrorPayload): AccountActionTypes {
  return {
    type: VALIDATE_COUPON_FAILURE,
    payload,
  };
}

export const validateCoupon = (coupon: string) => (dispatch: any, getState: any) => {
  const { id_token: idToken, access_token: accessToken } = getState().account;

  backendClient.validateCoupon(coupon, accessToken, idToken)
    .then((res) => {
      if (!res.ok) {
        return null;
      }
      return res.json();
    })
    .then((resJSON) => {
      console.log(resJSON);
    })
    .catch((e) => {
      console.error(e);
    });
};

export function deleteAccountFailure(payload: ErrorPayload): AccountActionTypes {
  return {
    type: DELETE_ACCOUNT_FAILURE,
    payload,
  };
}

export const deleteAccount = () => (dispatch: any, getState: any) => {
  const { id_token: idToken, access_token: accessToken } = getState().account;

  const error = 'Something went wrong. Please try again.';

  backendClient.deleteAccount(accessToken, idToken)
    .then((res) => {
      if (!res.ok) {
        dispatch(deleteAccountFailure({ error }));
        return null;
      }
      return res.json();
    })
    .then((resJson) => {
      if (resJson) {
        dispatch(oauthLogout());
      }
    })
    .catch((e) => dispatch(deleteAccountFailure(e)));
};
