import styled from 'styled-components';

const CardContainer = styled.div`
  background: ${({ theme }) => theme.site.bannerBackground};
  border: 1px solid ${({ theme }) => theme.site.bannerBorder};
  border-radius: 8px;
  padding: 16px;
`;

export default CardContainer;
