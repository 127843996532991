/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';

interface InputProps {
  label?: string;
  value?: string;
  onChange: Function;
  placeholder?: string;
  type?: string;
  error?: string;
  autoComplete?: string;
  pattern?: string;
  inputMode?: 'text' | 'search' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | undefined;
  width?: string;
  marginBottom?: string;
}

const TextInput = styled.input<InputProps>`
  width: 100%;
  max-width: 500px;
  height: 50px;
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  color: black;
  text-decoration: none;
  outline: none;
  border: 2px solid ${(props) => props.theme.site.bannerBorder};
  box-shadow: ${(props) => (props.value === ''
    ? props.theme.site.bannerShadow
    : 'none')};
  font-size: ${(props) => props.theme.fontSizes[2]};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '35px')};
  &:focus {
    opacity: 0.8;
    border: 2px solid ${(props) => (props.value === ''
    ? props.theme.colors.accent
    : props.theme.site.bannerBorder)};
    box-shadow: none;
  }
`;

const Label = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const Error = styled.div`
  height: 20px;
  color: ${(props) => props.theme.colors.negative};
`;

const Input: React.FC<InputProps> = (props: InputProps) => {
  const {
    value, onChange, placeholder = '', type = 'text', label = '', error = '',
    autoComplete = '', pattern = '', inputMode = 'text',
    width = '100%', marginBottom,
  } = props;
  return (
    <div style={{ width }}>
      <Label>{label}</Label>
      <Error>{error}</Error>
      <TextInput
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        type={type}
        autoComplete={autoComplete}
        pattern={pattern}
        inputMode={inputMode}
        marginBottom={marginBottom}
      />
    </div>
  );
};

export default Input;
