/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import { svg } from 'd3';
import React, { useRef, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { BsPlusLg } from 'react-icons/bs';
import { HiOutlineMinus } from 'react-icons/hi';
import { CryptoIcon } from '..';
import { GTheme } from '../themes';

let d3 : any = null;

if ((process as any).browser) {
  // eslint-disable-next-line global-require
  d3 = require('d3');
}

type Data = {
  cx: any,
  cy: number,
  symbol: string,
  fullName: string,
  price: number,
  signal: number,
  change: number,
  realX?: number,
  realRadius: number,
  rank: number,
  color: string,
}

interface BubbleChartProps {
  data: Data[]
  height: number
  width: number
  xRange: number[]
  yRange: number[]
  type: string
  chartPosText: string
  chartNegText: string
  showTopBars: boolean
  text: string
}

interface TooltipProps {
  symbol: string,
  fullName: string,
  price: number,
  signal: number,
  change: number,
  x: number,
  y: number
}

const ZoomButton = styled.div`
  background: ${({ theme }) => theme.site.bannerBackground};
  border: 1px solid ${({ theme }) => theme.site.bannerBorder};
  padding: 8px;
  border-radius: 8px;
  position: absolute;
  width: 32px;
  height: 32px;
  text-align: center;
  z-index: 99;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  :first-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 0px;
  }
  :nth-child(2) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;

const Tooltip = styled.div`
  background: ${({ theme }) => theme.site.bannerBackground};
  border: 1px solid ${({ theme }) => theme.site.bannerBorder};
  padding: 16px;
  box-shadow: ${({ theme }) => theme.site.bannerShadow};
  border-radius: 8px;
  position: absolute;
  width: 225px;
  z-index: 999;
`;

const TooltipTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
`;

const TooltipPrice = styled.div`
  font-size: 20px;
  line-height: 24px;
`;

const TooltipChange = styled.div<any>`
  font-size: 15px;
  line-height: 18px;
  color: ${({ positive }) => (positive ? '#6BD7A4' : '#F9423D')};
`;

const TooltipSignal = styled.div<any>`
  border-radius: 1.25px;
  margin-top: 12px;
  text-align: center;
  width: 100%;
  padding: 15px 24px;
  background: ${({ positive }) => (positive ? '#6BD7A4' : '#F9423D')};
`;

const TooltipSignalText = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #FFFFFF;
`;

const BubbleChart: React.FC<BubbleChartProps> = (props: BubbleChartProps) => {
  const {
    data, height, width, xRange, yRange, type, chartNegText, chartPosText, showTopBars, text,
  } = props;
  const tickW = 0.0154;
  const [chart, setChart] = useState<any>(null);
  const [chartWidth, setChartWidth] = useState(width);
  const [tooltipInfo, setTooltipInfo] = useState<TooltipProps>();
  const [currentZoomState, setCurrentZoomState] = useState<any>();
  const theme = useTheme() as GTheme;
  // const [currentSelectionState, setCurrentSelectionState] = useState<any>([0, 1.5]);
  // const previousSelectionState = usePrevious(currentSelectionState);
  const ref = useRef<null>(null);
  const yLabel = type === 'gainers' ? '24 HR Price change' : '1 HR Price change';

  const zoom = d3.zoom().scaleExtent([0.25, 10]).on('zoom', (event: any) => {
    setCurrentZoomState(event.transform);
    chart.select('g').attr('transform', event.transform);
  });

  const zoomIn = () => {
    chart.transition().call(zoom.scaleBy, 2);
  };

  const zoomOut = () => {
    chart.transition().call(zoom.scaleBy, 0.5);
  };

  const rescale = () => {
    const currentWidth = parseInt(d3.select('#svgContainer').style('width'), 10);
    /*     const outerWidth = document?.getElementById('svgContainer')?.parentElement?
      .parentElement?.offsetWidth || 1000; */
    // currentWidth = outerWidth - 400;
    /*     if (currentWidth > 1150 && !document.fullscreenElement) {
      setChartWidth(1150);
    } else {
      setChartWidth(currentWidth);
    } */
    setChartWidth(currentWidth);
  };

  const updateChart = (reason: string) => {
    if (reason === 'data') {
      const xScale: any = d3.scaleLinear().domain(xRange).range([0, chartWidth]);
      const yScale = d3.scaleLinear().domain(yRange).range([height, 0]);

      if (currentZoomState) {
        const newXScale = currentZoomState.rescaleX(xScale);
        xScale.domain(newXScale.domain());
        const newYScale = currentZoomState.rescaleY(yScale);
        yScale.domain(newYScale.domain());
      }
      chart.select('g.chart').selectAll('circle')
        .data(data)
        .join('circle')
        .on('mouseover', (d: any) => {
          setTooltipInfo({
            x: d.pageX + 50,
            y: d.pageY,
            symbol: d.toElement.__data__.symbol,
            fullName: d.toElement.__data__.fullName,
            price: d.toElement.__data__.price,
            signal: d.toElement.__data__.signal,
            change: d.toElement.__data__.change,
          });
        })
        .on('mouseout', () => {
          setTooltipInfo(undefined);
        })
        .transition()
        .duration(1500)
        .attr('cx', (d: Data) => xScale(d.cx))
        .attr('cy', (d: Data) => yScale(d.cy))
        .attr('r', (d: Data) => d.realRadius)
        .attr('opacity', 0.75)
        .style('fill', (d: Data) => d.color);
      chart.select('g.chart').selectAll('text.label')
        .data(data)
        .join('text')
        .classed('label', true)
        .on('mouseover', (d: any) => {
          if (!tooltipInfo) {
            setTooltipInfo({
              x: d.pageX + 50,
              y: d.pageY,
              symbol: d.toElement.__data__.symbol,
              fullName: d.toElement.__data__.fullName,
              price: d.toElement.__data__.price,
              signal: d.toElement.__data__.signal,
              change: d.toElement.__data__.change,
            });
          }
        })
        .on('mouseout', () => {
          setTooltipInfo(undefined);
        })
        .transition()
        .duration(1500)
        .attr('x', (d: Data) => xScale(d.cx))
        .attr('y', (d: Data) => yScale(d.cy))
        .attr('font-size', (d: Data) => `${d.realRadius * 0.53}px`)
        .attr('font-weight', '600')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('cursor', 'pointer')
        .attr('fill', 'white')
        .attr('transform-origin', (d: Data) => `${xScale(d.cx)} ${yScale(d.cy)}`)
        .text((d: Data) => d.symbol);
      chart.selectAll('path.line').remove();
      chart.selectAll('path.line2').remove();
      const line2 = d3.line()([[20, height / 2],
        [chartWidth, height / 2]]);
      chart.append('path')
        .attr('d', line2)
        .attr('class', 'line2')
        .attr('stroke', theme.site.bannerBorder);
      if (type === 'rsi') {
        const line = d3.line()([[chartWidth * 0.3, height - 30],
          [chartWidth * 0.3, 0]]);
        chart.append('path')
          .attr('d', line)
          .attr('class', 'line')
          .attr('stroke', theme.site.bannerBorder);
        const line3 = d3.line()([[chartWidth * 0.7, height - 30],
          [chartWidth * 0.7, 0]]);
        chart.append('path')
          .attr('d', line3)
          .attr('class', 'line')
          .attr('stroke', theme.site.bannerBorder);
      } else {
        const line = d3.line()([[chartWidth / 2, height - 30],
          [chartWidth / 2, 0]]);
        chart.append('path')
          .attr('d', line)
          .attr('class', 'line')
          .attr('stroke', theme.site.bannerBorder);
      }
      chart.selectAll('rect').remove();
      chart.selectAll('text.rectLabel').remove();
      if (showTopBars) {
        chart.append('rect')
          .attr('x', 0)
          .attr('y', height - 30)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', (chartWidth / 4))
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text(`${text}: `)
          .append('tspan')
          .style('fill', '#F9423D')
          .text(`${chartNegText} `)
          .append('tspan')
          .style('fill', theme.site.color)
          .text('| 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#F9423D')
          .text('DOWN');
        chart.append('rect')
          .attr('x', chartWidth / 2)
          .attr('y', height - 30)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', (chartWidth * 0.75))
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text(`${text}: `)
          .append('tspan')
          .style('fill', '#6BD7A4')
          .text(`${chartPosText} `)
          .append('tspan')
          .style('fill', theme.site.color)
          .text('| 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#F9423D')
          .text('DOWN');
        chart.append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', (chartWidth / 4))
          .attr('text-anchor', 'middle')
          .attr('y', 17)
          .attr('class', 'rectLabel')
          .text(`${text}: `)
          .append('tspan')
          .style('fill', '#F9423D')
          .text(`${chartNegText} `)
          .append('tspan')
          .style('fill', theme.site.color)
          .text('| 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#6BD7A4')
          .text('UP');
        chart.append('rect')
          .attr('x', chartWidth / 2)
          .attr('y', 0)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', (chartWidth * 0.75))
          .attr('text-anchor', 'middle')
          .attr('y', 17)
          .attr('class', 'rectLabel')
          .text(`${text}: `)
          .append('tspan')
          .style('fill', '#6BD7A4')
          .text(`${chartPosText} `)
          .append('tspan')
          .style('fill', theme.site.color)
          .text('| 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#6BD7A4')
          .text('UP');
      } else if (type === 'rsi') {
        chart.append('rect')
          .attr('x', 0)
          .attr('y', height - 30)
          .attr('width', chartWidth * 0.3)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', chartWidth * 0.15)
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text('RSI: OVERSOLD | 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#F9423D')
          .text('DOWN');
        chart.append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', chartWidth * 0.3)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', chartWidth * 0.15)
          .attr('text-anchor', 'middle')
          .attr('y', 18)
          .attr('class', 'rectLabel')
          .text('RSI: OVERSOLD | 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#37CD93')
          .text('UP');
        chart.append('rect')
          .attr('x', chartWidth * 0.3)
          .attr('y', height - 30)
          .attr('width', chartWidth * 0.4)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', chartWidth * 0.5)
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text('RSI: NEUTRAL | 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#F9423D')
          .text('DOWN');
        chart.append('rect')
          .attr('x', chartWidth * 0.3)
          .attr('y', 0)
          .attr('width', chartWidth * 0.4)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', chartWidth * 0.5)
          .attr('text-anchor', 'middle')
          .attr('y', 18)
          .attr('class', 'rectLabel')
          .text('RSI: NEUTRAL | 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#37CD93')
          .text('UP');
        chart.append('rect')
          .attr('x', chartWidth * 0.7)
          .attr('y', height - 30)
          .attr('width', chartWidth * 0.3)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', chartWidth * 0.85)
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text('RSI: OVERBOUGHT | 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#F9423D')
          .text('DOWN');
        chart.append('rect')
          .attr('x', chartWidth * 0.7)
          .attr('y', 0)
          .attr('width', chartWidth * 0.3)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', chartWidth * 0.85)
          .attr('text-anchor', 'middle')
          .attr('y', 18)
          .attr('class', 'rectLabel')
          .text('RSI: OVERBOUGHT | 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#37CD93')
          .text('UP');
      } else {
        chart.append('rect')
          .attr('x', 0)
          .attr('y', height - 30)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.bannerBorder);
        chart.append('text')
          .attr('dx', (chartWidth / 4))
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text(chartNegText);
        chart.append('rect')
          .attr('x', chartWidth / 2)
          .attr('y', height - 30)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', (chartWidth * 0.75))
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text(chartPosText);
      }
      chart.selectAll('text.yLabel').remove();
      chart.append('text')
        .attr('class', 'yLabel')
        .attr('text-anchor', 'middle')
        .attr('dy', '5%')
        .attr('dx', '5%')
        .attr('transform', () => `translate( 0, ${(height / 2) + 55}), rotate(-90)`)
        .text(yLabel);
    } else {
      const xScale: any = d3.scaleLinear().domain(xRange).range([0, chartWidth]);
      const yScale = d3.scaleLinear().domain(yRange).range([height - 30, 0]);
      chart.select('g.chart').selectAll('circle')
        .data(data)
        .join('circle')
        .on('mouseover', (d: any) => {
          setTooltipInfo({
            x: d.pageX + 50,
            y: d.pageY,
            symbol: d.toElement.__data__.symbol,
            fullName: d.toElement.__data__.fullName,
            price: d.toElement.__data__.price,
            signal: d.toElement.__data__.signal,
            change: d.toElement.__data__.change,
          });
        })
        .on('mouseout', () => {
          setTooltipInfo(undefined);
        })
        .attr('cx', (d: Data) => xScale(d.cx))
        .attr('cy', (d: Data) => yScale(d.cy))
        .attr('r', (d: Data) => d.realRadius)
        .attr('opacity', 0.75)
        .style('fill', (d: Data) => d.color);
      chart.select('g.chart').selectAll('text.label')
        .data(data)
        .join('text')
        .classed('label', true)
        .on('mouseover', (d: any) => {
          if (!tooltipInfo) {
            setTooltipInfo({
              x: d.pageX + 50,
              y: d.pageY,
              symbol: d.toElement.__data__.symbol,
              fullName: d.toElement.__data__.fullName,
              price: d.toElement.__data__.price,
              signal: d.toElement.__data__.signal,
              change: d.toElement.__data__.change,
            });
          }
        })
        .on('mouseout', () => {
          setTooltipInfo(undefined);
        })
        .attr('x', (d: Data) => xScale(d.cx))
        .attr('y', (d: Data) => yScale(d.cy))
        .attr('font-size', (d: Data) => `${d.realRadius * 0.53}px`)
        .attr('font-weight', '600')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('cursor', 'pointer')
        .attr('fill', 'white')
        .attr('transform-origin', (d: Data) => `${xScale(d.cx)} ${yScale(d.cy)}`)
        .text((d: Data) => d.symbol);
      chart.selectAll('path.line').remove();
      chart.selectAll('path.line2').remove();
      const line2 = d3.line()([[20, height / 2],
        [chartWidth, height / 2]]);
      chart.append('path')
        .attr('d', line2)
        .attr('class', 'line2')
        .attr('stroke', theme.site.bannerBorder);
      if (type === 'rsi') {
        const line = d3.line()([[chartWidth * 0.3, height - 30],
          [chartWidth * 0.3, 0]]);
        chart.append('path')
          .attr('d', line)
          .attr('class', 'line')
          .attr('stroke', theme.site.bannerBorder);
        const line3 = d3.line()([[chartWidth * 0.7, height - 30],
          [chartWidth * 0.7, 0]]);
        chart.append('path')
          .attr('d', line3)
          .attr('class', 'line')
          .attr('stroke', theme.site.bannerBorder);
      } else {
        const line = d3.line()([[chartWidth / 2, height - 30],
          [chartWidth / 2, 0]]);
        chart.append('path')
          .attr('d', line)
          .attr('class', 'line')
          .attr('stroke', theme.site.bannerBorder);
      }
      chart.selectAll('rect').remove();
      chart.selectAll('text.rectLabel').remove();
      if (showTopBars) {
        chart.append('rect')
          .attr('x', 0)
          .attr('y', height - 30)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', (chartWidth / 4))
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text(`${text}: `)
          .append('tspan')
          .style('fill', '#F9423D')
          .text(`${chartNegText} `)
          .append('tspan')
          .style('fill', theme.site.color)
          .text('| 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#F9423D')
          .text('DOWN');
        chart.append('rect')
          .attr('x', chartWidth / 2)
          .attr('y', height - 30)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', (chartWidth * 0.75))
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text(`${text}: `)
          .append('tspan')
          .style('fill', '#6BD7A4')
          .text(`${chartPosText} `)
          .append('tspan')
          .style('fill', theme.site.color)
          .text('| 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#F9423D')
          .text('DOWN');
        chart.append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', (chartWidth / 4))
          .attr('text-anchor', 'middle')
          .attr('y', 17)
          .attr('class', 'rectLabel')
          .text(`${text}: `)
          .append('tspan')
          .style('fill', '#F9423D')
          .text(`${chartNegText} `)
          .append('tspan')
          .style('fill', theme.site.color)
          .text('| 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#6BD7A4')
          .text('UP');
        chart.append('rect')
          .attr('x', chartWidth / 2)
          .attr('y', 0)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', (chartWidth * 0.75))
          .attr('text-anchor', 'middle')
          .attr('y', 17)
          .attr('class', 'rectLabel')
          .text(`${text}: `)
          .append('tspan')
          .style('fill', '#6BD7A4')
          .text(`${chartPosText} `)
          .append('tspan')
          .style('fill', theme.site.color)
          .text('| 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#6BD7A4')
          .text('UP');
      } else if (type === 'rsi') {
        chart.append('rect')
          .attr('x', 0)
          .attr('y', height - 30)
          .attr('width', chartWidth * 0.3)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', chartWidth * 0.15)
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text(chartNegText);
        chart.append('rect')
          .attr('x', chartWidth * 0.3)
          .attr('y', height - 30)
          .attr('width', chartWidth * 0.4)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', chartWidth * 0.5)
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text('Not Overbought or Oversold');
        chart.append('rect')
          .attr('x', chartWidth * 0.7)
          .attr('y', height - 30)
          .attr('width', chartWidth * 0.3)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', chartWidth * 0.85)
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text(chartPosText);
      } else {
        chart.append('rect')
          .attr('x', 0)
          .attr('y', height - 30)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', (chartWidth / 4))
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text(chartNegText);
        chart.append('rect')
          .attr('x', chartWidth / 2)
          .attr('y', height - 30)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        chart.append('text')
          .attr('dx', (chartWidth * 0.75))
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text(chartPosText);
      }
      chart.selectAll('text.yLabel').remove();
      chart.append('text')
        .attr('class', 'yLabel')
        .attr('text-anchor', 'middle')
        .attr('dy', '5%')
        .attr('dx', '5%')
        .attr('transform', () => `translate( 0 , ${(height / 2) + 55}), rotate(-90)`)
        .text(yLabel);
    }
    const brush = d3.brush().on('end', (event: any) => {
      if (event.selection) {
        const pos1 = event.selection[0];
        const pos2 = event.selection[1];
        const xScale = d3.scaleLinear().domain(xRange).range([0, chartWidth]);
        const yScale = d3.scaleLinear().domain(yRange).range([height - 30, 0]);
        xScale.domain([xScale.invert(pos1[0]), xScale.invert(pos2[0])]);
        yScale.domain([yScale.invert(pos1[1]), yScale.invert(pos2[1])]);
        chart.select('.brush').call(brush.move, null);
        chart.selectAll('path.line').remove();
        chart.selectAll('path.line2').remove();
        chart.select('g.chart').selectAll('circle')
          .data(data)
          .transition()
          .duration(1500)
          .attr('cx', (d: Data) => xScale(d.cx))
          .attr('cy', (d: Data) => yScale(d.cy));
        chart.select('g.chart').selectAll('text.label')
          .data(data)
          .transition()
          .duration(1500)
          .attr('x', (d: Data) => xScale(d.cx))
          .attr('y', (d: Data) => yScale(d.cy));
        chart.select('.brush').attr('display', 'none');
      }
    });
    chart.on('click', () => {
      chart.select('.brush').attr('display', 'block').call(brush);
    });
    chart.on('dblclick', () => {
      chart.select('.brush').attr('display', 'none');
      updateChart('width');
    });
    setChart(chart);
  };

  useEffect(() => {
    if (chart) {
      updateChart('width');
    }
  }, [chartWidth, height]);

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', rescale);
    }
    return (() => {
      window.removeEventListener('resize', rescale);
    });
  }, []);

  useEffect(() => {
    if (!chart) {
      const svgElement = d3.select(ref.current!);
      const xScale: any = d3.scaleLinear().domain(xRange).range([0, chartWidth]);
      const yScale = d3.scaleLinear().domain(yRange).range([height - 30, 0]);
      // svgElement.call(zoom);
      // svgElement.call(d3.brush().extent([[0, 0], [chartWidth, height]]));
      svgElement.select('g.chart').selectAll('circle')
        .data(data)
        .join('circle')
        .attr('cx', (d: Data) => xScale(d.cx))
        .attr('cy', (d: Data) => yScale(d.cy))
        .attr('r', (d: Data) => d.realRadius)
        .attr('opacity', 0.75)
        .attr('cursor', 'pointer')
        .style('fill', (d: Data) => d.color)
        .on('mouseover', (d: any) => {
          if (!tooltipInfo) {
            setTooltipInfo({
              x: d.pageX + 50,
              y: d.pageY,
              symbol: d.toElement.__data__.symbol,
              fullName: d.toElement.__data__.fullName,
              price: d.toElement.__data__.price,
              signal: d.toElement.__data__.signal,
              change: d.toElement.__data__.change,
            });
          }
        })
        .on('mouseout', () => {
          setTooltipInfo(undefined);
        });
      svgElement.select('g.chart').selectAll('text.label')
        .data(data)
        .enter()
        .append('text')
        .classed('label', true)
        .attr('x', (d: Data) => xScale(d.cx))
        .attr('y', (d: Data) => yScale(d.cy))
        .attr('font-size', '13px')
        .attr('font-weight', '600')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('cursor', 'pointer')
        .attr('fill', 'white')
        .attr('transform-origin', (d: Data) => `${xScale(d.cx + tickW)} ${yScale(d.cy)}`)
        .text((d: Data) => d.symbol)
        .on('mouseover', (d: any) => {
          if (!tooltipInfo) {
            setTooltipInfo({
              x: d.pageX + 50,
              y: d.pageY,
              symbol: d.toElement.__data__.symbol,
              fullName: d.toElement.__data__.fullName,
              price: d.toElement.__data__.price,
              signal: d.toElement.__data__.signal,
              change: d.toElement.__data__.change,
            });
          }
        })
        .on('mouseout', () => {
          setTooltipInfo(undefined);
        });
      const line = d3.line()([[chartWidth / 2, height - 30],
        [chartWidth / 2, 0]]);
      const line2 = d3.line()([[20, height / 2],
        [chartWidth, height / 2]]);
      svgElement.append('path')
        .attr('d', line)
        .attr('class', 'line')
        .attr('stroke', theme.site.bannerBorder);
      svgElement.append('path')
        .attr('d', line2)
        .attr('class', 'line2')
        .attr('stroke', theme.site.bannerBorder);
      if (showTopBars) {
        svgElement.append('rect')
          .attr('x', 0)
          .attr('y', height - 30)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        svgElement.append('text')
          .attr('dx', (chartWidth / 4))
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text(`${text}: `)
          .append('tspan')
          .style('fill', '#F9423D')
          .text(`${chartNegText} `)
          .append('tspan')
          .style('fill', theme.site.color)
          .text('| 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#F9423D')
          .text('DOWN');
        svgElement.append('rect')
          .attr('x', chartWidth / 2)
          .attr('y', height - 30)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        svgElement.append('text')
          .attr('dx', (chartWidth * 0.75))
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text(`${text}: `)
          .append('tspan')
          .style('fill', '#6BD7A4')
          .text(`${chartPosText} `)
          .append('tspan')
          .style('fill', theme.site.color)
          .text('| 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#F9423D')
          .text('DOWN');
        svgElement.append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        svgElement.append('text')
          .attr('dx', (chartWidth / 4))
          .attr('text-anchor', 'middle')
          .attr('y', 17)
          .attr('class', 'rectLabel')
          .text(`${text}: `)
          .append('tspan')
          .style('fill', '#F9423D')
          .text(`${chartNegText} `)
          .append('tspan')
          .style('fill', theme.site.color)
          .text('| 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#6BD7A4')
          .text('UP');
        svgElement.append('rect')
          .attr('x', chartWidth / 2)
          .attr('y', 0)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        svgElement.append('text')
          .attr('dx', (chartWidth * 0.75))
          .attr('text-anchor', 'middle')
          .attr('y', 17)
          .attr('class', 'rectLabel')
          .text(`${text}: `)
          .append('tspan')
          .style('fill', '#6BD7A4')
          .text(`${chartPosText} `)
          .append('tspan')
          .style('fill', theme.site.color)
          .text('| 1HR PRICE: ')
          .append('tspan')
          .style('fill', '#6BD7A4')
          .text('UP');
      } else {
        svgElement.append('rect')
          .attr('x', 0)
          .attr('y', height - 30)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        svgElement.append('text')
          .attr('dx', (chartWidth / 4))
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text(chartNegText);
        svgElement.append('rect')
          .attr('x', chartWidth / 2)
          .attr('y', height - 30)
          .attr('width', chartWidth / 2)
          .attr('height', 30)
          .attr('stroke-width', 1)
          .attr('stroke', theme.site.bannerBorder)
          .attr('fill', theme.site.sectionTitleBackground);
        svgElement.append('text')
          .attr('dx', (chartWidth * 0.75))
          .attr('text-anchor', 'middle')
          .attr('y', height - 12)
          .attr('class', 'rectLabel')
          .text(chartPosText);
      }
      svgElement.append('text')
        .attr('class', 'yLabel')
        .attr('text-anchor', 'middle')
        .attr('dy', '5%')
        .attr('dx', '5%')
        .attr('transform', () => `translate( 0, ${(height / 2) + 55}), rotate(-90)`)
        .text(yLabel);
      rescale();
      setChart(svgElement);
    } else {
      updateChart('data');
    }
  }, [data, currentZoomState, theme]);
  return (
    <div id="svgContainer" style={{ marginLeft: '20px' }}>
      <ZoomButton
        onClick={() => zoomIn()}
        style={{ left: chartWidth * 0.99, marginTop: height * 0.7 }}
      >
        <BsPlusLg size={14} />
      </ZoomButton>
      <ZoomButton
        onClick={() => zoomOut()}
        style={{ left: chartWidth * 0.99, marginTop: height * 0.7 + 32 }}
      >
        <HiOutlineMinus size={14} />
      </ZoomButton>
      {tooltipInfo
        ? (
          <Tooltip style={{ left: `${tooltipInfo.x - 50}px`, marginTop: '170px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
              <div style={{ marginRight: '10px', marginTop: '5px' }}>
                <CryptoIcon
                  symbol={tooltipInfo?.symbol}
                  height="40"
                  width="40"
                />
              </div>
              <div>
                <TooltipTitle>
                  {tooltipInfo.symbol}
                </TooltipTitle>
                <TooltipTitle>
                  {tooltipInfo.fullName}
                </TooltipTitle>
              </div>
            </div>
            <TooltipPrice>
              {`$${tooltipInfo.price.toFixed(2)}`}
            </TooltipPrice>
            <TooltipChange positive={tooltipInfo.change >= 0}>
              {`${tooltipInfo.change > 0 ? '+' : ''}${((tooltipInfo.price * tooltipInfo.change) / 100).toFixed(2)} (${(tooltipInfo.change).toFixed(2)}%)`}
            </TooltipChange>
            <TooltipSignal positive={tooltipInfo.signal >= 0.5}>
              <TooltipSignalText>
                {`Signal: ${tooltipInfo.signal >= 0.5 ? 'BUY' : 'SELL'} (${tooltipInfo.signal})`}
              </TooltipSignalText>
            </TooltipSignal>
          </Tooltip>
        )
        : null}
      <svg
        ref={ref}
        width={chartWidth}
        height={height}
      >
        <g className="chart" />
        <g className="brush" />
      </svg>
    </div>

  );
};

export default React.memo(BubbleChart);
