import React from 'react';
import styled from 'styled-components';

interface StyledButtonProps {
  active: boolean;
}

const StyledButton = styled.div<StyledButtonProps>`
  width: 100%;
  max-width: 300px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.accent};
  background: ${({ active, theme }) => active && theme.colors.accent};
  padding: 5px;
  margin-top: 15px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

interface SelectButtonProps {
  label: string;
  activeLabel: string;
  onClick: Function;
  active: boolean;
}

const SelectButton: React.FC<SelectButtonProps> = (props: SelectButtonProps) => {
  const {
    onClick, label, active, activeLabel,
  } = props;
  return (
    <StyledButton active={active} onClick={() => onClick()}>
      {active ? activeLabel : label}
    </StyledButton>
  );
};

export default SelectButton;
