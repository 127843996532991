/* eslint-disable camelcase */
import React from 'react';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import styled from 'styled-components';
import { CardContainer, CryptoIcon } from '..';
import { colors } from '../themes/common';
import { formatPriceToUSD } from '../../utils';

interface PriceChangeTextProps {
  positive: boolean
}

interface SignalProps {
  signal: number
}

interface CurrencyCardSmallProps {
  data: {
    currency: string
    name: string
    price_usd: number
    percent_change_24h: number
    opportunity_score: number
    prices7D: number[]
  }
}

const Container = styled(CardContainer)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.site.bannerShadow};
  width: 200px;
  min-width: 200px;
  margin-right: 20px;
`;

const CurrencyLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CurrencyText = styled.div`
  flex-direction: column;
  margin-left: 10px;
`;

const CurrencySymbol = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[3]};
`;

const CurrencyName = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  color: ${({ theme }) => theme.site.pageHeaderText};
`;

const PriceContainer = styled.div`
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 10px 10px 0;
`;

const PriceText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

const PriceChangeText = styled.div<PriceChangeTextProps>`
  color: ${({ theme, positive }) => (positive ? theme.colors.positive : theme.colors.negative)};
`;

const Signal = styled.div<SignalProps>`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  background-color: ${({ theme, signal }) => {
    if (!signal) return 'gray';
    if (signal >= 0.5) return theme.colors.positive;
    return theme.colors.negative;
  }};
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 10px 0;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CurrencyCardSmall: React.FC<CurrencyCardSmallProps> = (props: CurrencyCardSmallProps) => {
  const {
    data: {
      currency, name, price_usd, percent_change_24h, opportunity_score, prices7D,
    },
  } = props;
  let displayName = name;
  const txtArr = name.split('');

  if (txtArr.length > 10) {
    displayName = `${txtArr.splice(0, 7).join('')}...`;
  }

  const oppScoreText = () => {
    if (!opportunity_score) return 'NEUTRAL';
    if (opportunity_score >= 0.5) return 'BUY';
    return 'SELL';
  };

  return (
    <Container>
      <CurrencyLabel>
        <CryptoIcon symbol={currency} height="50" width="50" />
        <CurrencyText>
          <CurrencySymbol>{currency}</CurrencySymbol>
          <CurrencyName>{displayName}</CurrencyName>
        </CurrencyText>
      </CurrencyLabel>
      <Row>
        <PriceContainer>
          <PriceText>{price_usd && formatPriceToUSD(price_usd)}</PriceText>
          <PriceChangeText positive={percent_change_24h > 0}>
            {percent_change_24h.toFixed(2)}
            %
          </PriceChangeText>
        </PriceContainer>
        <Sparklines data={prices7D} style={{ width: 75, height: 25 }}>
          <SparklinesLine color={colors.accent} style={{ fill: 'none', strokeWidth: 3 }} />
        </Sparklines>
      </Row>
      <Signal signal={opportunity_score}>
        {`Signal: ${oppScoreText()} (${opportunity_score})`}
      </Signal>
    </Container>
  );
};

export default CurrencyCardSmall;
