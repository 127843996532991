import styled from 'styled-components';

const PageHeaderTitle = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.accent};
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  padding-bottom: 10px;
`;

export default PageHeaderTitle;
