import React from 'react';
import { AiFillCaretRight, AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai';
import Marquee from 'react-fast-marquee';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Client from '../../utils/api/backend/clients';
import { convertPriceToString } from '../../utils';
import { Ticker as CoreTicker } from '../../utils/api/backend/models';
import { RootState } from '../../store';

const backendClient = new Client();

interface ContainerProps {
  group?: string,
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.site.tickerBorder};
  width: ${({ group }) => (['Tier2', 'Admin'].includes(group || '') ? '95%' : '100%')};
  margin-left: ${({ group }) => (['Tier2', 'Admin'].includes(group || '') ? '5%' : '0')};
  z-index: 100;
  @media screen and (max-width: 1250px) {
   width: 100%;
   margin-left: 0;
`;

const PauseButton = styled.div`
  height: 30px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.site.tickerBorder};
  cursor: pointer;
`;

const TickerItem = styled.div`
  width: 150px;
  height: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 10px;
`;

const Symbol = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
`;

const Price = styled.span`
  font-size: 12px;
  line-height: 12px;
`;

const Change = styled.span`
  padding: 2px 6px;
  font-size: 8px;
  line-height: 8px;
  color: black;
  background: #9BF5DC;
`;

interface TickersProps {
  group?: string,
}

const Tickers: React.FC<TickersProps> = ({ group }: TickersProps) => {
  const accountState: any = useSelector((state: RootState) => state.account);
  const {
    access_token: accessToken, id_token: idToken,
  } = accountState;
  const [move, setMove] = React.useState(true);
  const [tickerData, setTickerData] = React.useState<any[]>([]);

  const fetchTickerData = async () => {
    let tickers : CoreTicker[] = [];

    try {
      const tickersResp = await backendClient.getTickers(accessToken, idToken);
      if (tickersResp.status !== 200) {
        return;
      }

      tickers = await tickersResp.json();
    } catch (e) {
      console.error('ticker error:', e);
    }

    if (tickers.length > 0) {
      setTickerData(tickers.slice(0, 25));
    }
  };

  const renderTickers = () => {
    const tickers = tickerData.map(({ currency, close, percent_change_1h: pctChangeHr }) => {
      const price = convertPriceToString(close);
      const pctChange = Math.abs(pctChangeHr).toFixed(2);
      const icon = pctChangeHr > 0
        ? <AiFillCaretUp size={15} color="green" />
        : <AiFillCaretDown size={15} color="red" />;
      const bg = pctChangeHr > 0 ? '#9BF5DC' : '#FDE1E2';
      return (
        <TickerItem key={`ticker_item_${currency}`}>
          <Symbol>{ currency }</Symbol>
          { icon }
          <Price>
            $
            { price }
          </Price>
          <Change style={{ background: bg }}>{ pctChange }</Change>
        </TickerItem>
      );
    });
    return tickers;
  };

  React.useEffect(() => {
    fetchTickerData();
  }, []);

  return (
    <Container group={group}>
      <PauseButton onClick={() => setMove(!move)}>
        <AiFillCaretRight size={20} />
      </PauseButton>
      <Marquee play={move} gradient={false} speed={20}>
        {renderTickers()}
      </Marquee>
    </Container>
  );
};

Tickers.defaultProps = {
  group: '',
};

export default Tickers;
