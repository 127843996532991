import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Link from 'next/link';
import { AiOutlineTwitter, AiFillLinkedin } from 'react-icons/ai';
import { BsTelegram } from 'react-icons/bs';
import {
  NavLogo, FooterContainer, SocialLogos, FooterTitle, FooterLink,
  FooterLoginBtn,
  GreyText, FooterSubtext,
} from '../globalStyles/common';
import { HubspotContactForm } from '..';

export const Flex = styled.div<{alignItems?: string}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'unset')}
`;
export const FlexWidth100 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const Footer: React.FC<any> = ({ style }: any) => {
  const result: any = useSelector((state) => state);
  const router = useRouter();
  const { theme } = result.currencyProfile;

  return (
    <div>
      <FooterContainer style={style}>
        <div>
          <NavLogo>
            {theme === 'light'
              ? <Image src="/assets/logoBlack.png" width="106" height="20" alt="logo" />
              : <Image src="/assets/logo.png" width="106" height="20" alt="logo" />}
          </NavLogo>
          <SocialLogos>
            <a href="https://www.twitter.com/joinuptick" target="_blank" rel="noreferrer">
              <AiOutlineTwitter color="black" size={20} />
            </a>
            <a href="https://www.linkedin.com/company/uptick-co" target="_blank" rel="noreferrer">
              <AiFillLinkedin color="black" size={20} />
            </a>
            <a href="https://t.me/joinchat/Si9tndmlQSExNjDN" target="_blank" rel="noreferrer">
              <BsTelegram color="black" size={20} />
            </a>
          </SocialLogos>
        </div>
        <div>
          <FooterTitle>About Uptick</FooterTitle>
          <Link href="/">
            <FooterLink>Home</FooterLink>
          </Link>

          <FooterLink href="mailto: contact@uptick.co"><div>Contact Us</div></FooterLink>
        </div>
        <div>
          <FooterTitle>Join The Newsletter</FooterTitle>
          <HubspotContactForm hubspotID={process.env.NEXT_PUBLIC_HUBSPOT_FORM_ID || ''} elemID="hubspot_newsletter_form" />
        </div>
        <div>
          <FooterTitle>LOG IN / SIGN UP</FooterTitle>
          <Flex>
            <FooterLoginBtn onClick={() => { router.push('/signin'); }}>Try For Free</FooterLoginBtn>
          </Flex>
        </div>
      </FooterContainer>
      <FooterSubtext>
        <FlexWidth100>
          <strong>UPTICK. INC. © 2022</strong>
          <GreyText>
            <Link href="/terms-of-service">TERMS</Link>
            {' '}
            |
            {' '}
            <Link href="/privacy-policy">PRIVACY</Link>
          </GreyText>
        </FlexWidth100>
        {/* eslint-disable-next-line max-len */}
        All content provided herein our website, hyperlinked sites, associated applications, forums, blogs, social media accounts and other platforms (“Site”) is for your general information only, procured from third party sources. We make no warranties of any kind in relation to our content, including but not limited to accuracy and updatedness. No part of the content that we provide constitutes financial advice, legal advice or any other form of advice meant for your specific reliance for any purpose. Any use or reliance on our content is solely at your own risk and discretion. You should conduct your own research, review, analyse and verify our content before relying on them. Trading is a highly risky activity that can lead to major losses, please therefore consult your financial advisor before making any decision. No content on our Site is meant to be a solicitation or offer.
      </FooterSubtext>
    </div>
  );
};

export default Footer;
