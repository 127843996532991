const numberPattern = /^[0-9s]{13,19}$/;
const expirationPattern = /^(0[1-9]|1[0-2])\/2[0-9]{1}$/;
const cvcPattern = /^[0-9]{3,4}$/;

export const isValidName = (name: string) => name.length > 0;
export const isValidCreditCardNumber = (cardNumber: string) => numberPattern.test(cardNumber);
// eslint-disable-next-line max-len
export const isValidCreditCardExpiration = (cardExpiration: string) => expirationPattern.test(cardExpiration);
export const isValidCvcPattern = (cardCvc: string) => cvcPattern.test(cardCvc);
export const isValidCountry = (country: string) => country.length > 1;
export const isValidZip = (zip: string) => zip.length > 1;

export enum MetaTagKeys {
  TITLE = 'title',
  DESCRIPTION = 'description',
  META_TITLE = 'meta_title',
  META_DESCRIPTION = 'meta_description',
  OG_TYPE = 'og:type',
  OG_TITLE = 'og:title',
  OG_URL = 'og:url',
  OG_DESCRIPTION = 'og:description',
  OG_IMAGE = 'og:image',
  TWITTER_CARD = 'twitter:card',
  TWITTER_URL = 'twitter:url',
  TWITTER_TITLE = 'twitter:title',
  TWITTER_DESCRIPTION = 'twitter:description',
  TWITTER_IMAGE = 'twitter:image',
  TWITTER_IMAGE_ALT = 'twitter:image:alt',
}

export const getMetaTags = (title: string, description: string, url: string) => {
  const {
    TITLE, DESCRIPTION, META_TITLE, META_DESCRIPTION,
    OG_URL, OG_TITLE, OG_DESCRIPTION,
    TWITTER_TITLE, TWITTER_DESCRIPTION,
  } = MetaTagKeys;

  const ogUrl = url || 'https://uptick.co';

  return [
    { key: META_TITLE, name: TITLE, content: title },
    { key: META_DESCRIPTION, name: DESCRIPTION, content: description },
    { key: OG_URL, name: OG_URL, content: ogUrl },
    { key: OG_TITLE, name: OG_TITLE, content: title },
    { key: OG_DESCRIPTION, name: OG_DESCRIPTION, content: description },
    { key: TWITTER_TITLE, name: TWITTER_TITLE, content: title },
    { key: TWITTER_DESCRIPTION, name: TWITTER_DESCRIPTION, content: description },
  ];
};

export const getMetaUrl = (name : string) : string => `https://uptick.co/${name}`;

const currencyConstructor = new Intl.NumberFormat('en-US', { maximumSignificantDigits: 20 });

export const convertPriceToString = (num: number) => {
  if (typeof num !== 'number') {
    return num;
  }

  if (num >= 0.1) {
    return parseFloat(num.toFixed(2));
  }

  const decimalString = `${num}`.split('.')[1] || '';
  const decimalCharArr = decimalString.split('');

  let zeroes = 0;
  let exitLoop = false;

  decimalCharArr.forEach((char) => {
    if (exitLoop) return;

    if (char === '0') {
      zeroes += 1;
    } else {
      zeroes += 2;
      exitLoop = true;
    }
  });

  if (zeroes > 8) zeroes = 8;
  const newNum = parseFloat(num.toFixed(zeroes));
  return currencyConstructor.format(newNum);
};

export const formatPriceToUSD = (num: number) => {
  if (typeof num !== 'number') {
    return num;
  }

  if (num >= 0.1) {
    return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  return `$${convertPriceToString(num)}`;
};

export const formatLargeNumber = (num: number) => {
  if (typeof num !== 'number') {
    return num;
  }

  if (num > 1000000000) {
    return `${(num / 1000000000).toFixed(2)} B`;
  }

  if (num > 1000000) {
    return `${(num / 1000000).toFixed(2)} M`;
  }

  return num;
};

const translations = new Map<string, string>();
translations.set('0XBTC', '_0xbtc');
translations.set('1CR', '_1cr');
translations.set('1GOLD', '_1gold');
translations.set('1INCH', '_1inch');
translations.set('1SG', '_1sg');
translations.set('1ST', '_1st');
translations.set('1WO', '_1wo');
translations.set('2015', '_2015');
translations.set('2BACCO', '_2bacco');
translations.set('2GIVE', '_2give');
translations.set('2KEY', '_2key');
translations.set('300F', '_300f');
translations.set('32BIT', '_32bit');
translations.set('365', '_365');
translations.set('3DES', '_3des');
translations.set('404', '_404');
translations.set('4ART', '_4art');
translations.set('7E', '_7e');
translations.set('888', '_888');
translations.set('8BIT', '_8bit');
translations.set('8X8', '_8x8');
translations.set('B@', '_bat');
translations.set('FOR', '_for');
translations.set('LET', '_let');
translations.set('NEW', '_new');
translations.set('SUPER', '_super');
translations.set('THX!', '_thx');
translations.set('TRUE', '_true');
translations.set('TRY', '_try');
translations.set('YIELD', '_yield');

export const getCurrencyIcon = (sym: string) => {
  // const dirSymbol = translations.get(sym) || sym;
  const cdnUrl = `${process.env.NEXT_PUBLIC_CDN}/cc/${sym}.png`;
  return cdnUrl;
};

export const isElectron = () => {
  // Renderer process
  if (typeof window !== 'undefined' && typeof window.process === 'object' && (window as any).process?.type === 'renderer') {
    return true;
  }

  // Main process
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
    return true;
  }

  return false;
};
