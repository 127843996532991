import React from 'react';
import styled from 'styled-components';
import GaugeChart from 'react-gauge-chart';
import { CardContainer, LoadingOverlay } from '..';

export interface SentimentMeterProps {
  value?: number;
  sentiment?: string;
  label?: string;
  width?: string;
}

interface ContainerProps {
  width?: string;
}

export interface GaugeChartMemoProps {
  gaugeValue?: number;
}

const Container = styled(CardContainer)<ContainerProps>`
  padding: 20px;
  width: ${({ width }) => (width || '32.5%')};
  min-width: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1200px) {
    min-width: 200px;
    margin-left: 0;
    margin-right: 0;
  }
`;

const ContainerLabel = styled.div`
  font-weight: bold;
  width: 100%;
  margin-bottom: 5px;
`;

const ChartLabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 170px;
  position: absolute;
  bottom: 15px;
`;

const ChartLabel = styled.div`
  font-weight: bold;
`;

const GaugeChartMemo = React.memo((props: GaugeChartMemoProps) => (
  <GaugeChart
    id="gauge-chart1"
    nrOfLevels={4}
    colors={['#F9423D', '#feb0b2', '#9bf5dc', '#37CD93']}
    percent={props.gaugeValue}
    hideText
    arcPadding={0}
    arcWidth={0.25}
    cornerRadius={0}
    needleColor="#55B1EC"
    needleBaseColor="#55B1EC"
    style={{ width: '200px' }}
  />
));

const SentimentMeter: React.FC<SentimentMeterProps> = (props: SentimentMeterProps) => {
  const {
    value = 0, sentiment = '', label = 'News sentiment', width,
  } = props;

  let gaugeValue = value;

  if (sentiment.toLowerCase() === 'very bullish') gaugeValue = 0.9;
  if (sentiment.toLowerCase() === 'bullish') gaugeValue = 0.65;
  if (sentiment.toLowerCase() === 'bearish') gaugeValue = 0.35;
  if (sentiment.toLowerCase() === 'very bearsh') gaugeValue = 0.1;

  if (typeof window === 'undefined') return <div />;

  return (
    <Container width={width}>
      <ContainerLabel>{label}</ContainerLabel>
      <GaugeChartMemo gaugeValue={gaugeValue} />
      <ChartLabelsContainer>
        <ChartLabel>BEARISH</ChartLabel>
        <ChartLabel>BULLISH</ChartLabel>
      </ChartLabelsContainer>
      {
        (!sentiment && !value) && <LoadingOverlay />
      }
    </Container>
  );
};

export default SentimentMeter;
