import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { FaLockOpen } from 'react-icons/fa';

const LockedSignalContainer = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  cursor: pointer;
  white-space: nowrap;
  text-decoration: underline;
  &:hover {
    opacity: 0.7;
  }
  svg {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const LockedSignal: React.FC = () => (
  <LockedSignalContainer>
    <Link href="/signin">
      <span>
        Unlock
        <FaLockOpen />
        {' '}
        Signal
      </span>
    </Link>
  </LockedSignalContainer>
);

export default LockedSignal;
