/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import {
  AiFillHome, AiOutlineFilter, AiOutlineBell, AiOutlineGlobal,
  AiFillFolderOpen,
} from 'react-icons/ai';
import {
  FaEthereum, FaBullhorn, FaDoorOpen, FaUserCircle,
} from 'react-icons/fa';
import {
  MdOutlineSpaceDashboard,
  MdOutlinePaid,
  MdOutlineArticle,
  MdOutlineDonutSmall,
  MdNotificationsNone,
} from 'react-icons/md';
import { BsFillCalendar2WeekFill } from 'react-icons/bs';
import { useSelectedContext } from '../../../pages/_app';
import {
  NavContainer, Nav, NavLogo, NavItem, BurgerMenu, BurgerMenuItem,
  BurgerMenuItemIcon, LogoutButton, Pointer, MobileNav,
  MobileHeader, SmallButton,
} from '../globalStyles/common';
import { GlobalFilters, HeaderUnauth } from '..';
import { logout, oauthLogout, applyFilters } from '../../account/actions';

interface HeaderProps {
  title?: string;
  border?: boolean;
  oauthLogoutUrl: string;
  group?: string;
}

const Header: React.FC<HeaderProps> = ({
  title, border, oauthLogoutUrl, group = '',
}: HeaderProps) => {
  const { changeValue } = useSelectedContext();
  const result: any = useSelector((state) => state);
  const { exchange_filter: exchangeFilter = [], refresh_token: refreshToken } = result.account;
  const router = useRouter();
  const dispatch = useDispatch();
  const [active, setActive] = React.useState(router?.pathname);
  const [closed, setClosed] = React.useState(true);
  const [selectedFilters, setSelectedFilters] = React.useState<string[]>(exchangeFilter || []);
  const [showFilters, setShowFilters] = React.useState(false);

  const { loggedIn } = result.account;

  const changeTheme = () => {
    const { theme } = result.currencyProfile;
    if (theme === 'light') {
      changeValue('dark');
      dispatch({ type: 'CHANGE_THEME', payload: 'dark' });
      localStorage.setItem('theme', 'dark');
    } else {
      changeValue('light');
      dispatch({ type: 'CHANGE_THEME', payload: 'light' });
      localStorage.setItem('theme', 'light');
    }
  };

  const handleLogout = () => {
    if (!refreshToken) {
      dispatch(oauthLogout());
      window.location.href = oauthLogoutUrl;
    } else {
      dispatch(logout());
      router.push('/');
    }
    changeValue('light');
    dispatch({ type: 'CHANGE_THEME', payload: 'light' });
    localStorage.setItem('theme', 'light');
  };

  const handleLogin = () => {
    router.push('/signin');
  };

  const handleRerouting = (asPath: string, searchParams: string) => {
    const { replace } = router;
    const trueURL = `${asPath}?${searchParams}`;
    const visibleURL = asPath;
    replace(trueURL, visibleURL, { scroll: false });
  };

  const addExchURLParam = (exchFilters: string[]) => {
    const { query, asPath } = router;
    const nonExchKeys = Object.keys(query).filter((k) => k !== 'exchanges');
    const urlParams = nonExchKeys.map((paramKey) => `${paramKey}=${query[paramKey]}`);
    const exchParam = `exchanges=${exchFilters.join(',')}`;

    const searchParams = urlParams.length > 0
      ? `${urlParams.join('&')}&${exchParam}`
      : `${exchParam}`;

    handleRerouting(asPath, searchParams);
  };

  const handleFilters = (exch: string[]) => {
    dispatch(applyFilters(exch));
    setSelectedFilters(exch);

    const { query, asPath } = router;

    if (exch.length > 0) {
      addExchURLParam(exch);
    } else {
      const nonExchKeys = Object.keys(query).filter((k) => k !== 'exchanges');
      const urlParams = nonExchKeys.map((paramKey) => `${paramKey}=${query[paramKey]}`);
      const searchParams = urlParams.join('&');

      handleRerouting(asPath, searchParams);
    }
    setShowFilters(false);
  };

  const getPathWithExchanges = (path: string) => {
    if (selectedFilters.length > 0) {
      const exchParam = selectedFilters.join(',');

      return {
        pathname: path,
        query: { exchanges: exchParam },
      };
    }
    return path;
  };

  React.useEffect(() => {
    const { query } = router;

    if (exchangeFilter.length > 0 && !query.exchanges) {
      addExchURLParam(exchangeFilter);
    }
    if (['Tier2', 'Admin'].includes(group)) {
      changeValue('dark');
      dispatch({ type: 'CHANGE_THEME', payload: 'dark' });
      localStorage.setItem('theme', 'dark');
    }
  }, []);

  const { theme } = result.currencyProfile;

  return !loggedIn ? <HeaderUnauth /> : (
    <MobileHeader>
      <NavContainer>
        <Nav>
          {!['Tier2', 'Admin'].includes(group) && (
            <NavLogo onClick={() => setActive('/')}>
              <Link href="/">
                <div>
                  {theme === 'light'
                    ? <Image src="/assets/logoBlack.png" width="106" height="20" alt="logo" />
                    : <Image src="/assets/logo.png" width="106" height="20" alt="logo" />}
                </div>
              </Link>
            </NavLogo>
          )}
          <NavItem onClick={() => setActive('/dashboard')} active={active === '/dashboard'}>
            <Link href={getPathWithExchanges('/dashboard')} as="/dashboard">
              { ['Tier2', 'Admin'].includes(group) ? <AiFillHome size={20} /> : 'Dashboard'}
            </Link>
            { ['Tier2', 'Admin'].includes(group) && <span>Dashboard</span> }
          </NavItem>
          <NavItem onClick={() => setActive('/profile')} active={active === '/profile'}>
            <Link href={!loggedIn ? '/profile' : getPathWithExchanges('/profile')} as="/profile">
              { ['Tier2', 'Admin'].includes(group) ? <FaEthereum size={20} /> : 'Currency Profiles'}
            </Link>
            { ['Tier2', 'Admin'].includes(group) && <span>Currency Profiles</span> }
          </NavItem>
          <NavItem onClick={() => setActive('/news')} active={active === '/news'}>
            <Link href={!loggedIn ? '/news' : getPathWithExchanges('/news')} as="/news">
              { ['Tier2', 'Admin'].includes(group) ? <FaBullhorn size={20} /> : 'News and Social' }
            </Link>
            { ['Tier2', 'Admin'].includes(group) && <span>News and Social</span> }
          </NavItem>
          {/*
            <NavItem onClick={() => setActive('/portfolio')} active={active === '/portfolio'}>
              <Link href="/portfolio">Portfolio</Link>
            </NavItem>
          */}
          <NavItem onClick={() => setActive('/screener')} active={active === '/screener'}>
            <Link href={getPathWithExchanges('/screener')} as="/screener">
              { ['Tier2', 'Admin'].includes(group) ? <AiOutlineFilter size={20} /> : 'Screener' }
            </Link>
            { ['Tier2', 'Admin'].includes(group) && <span>Screener</span> }
          </NavItem>
          <NavItem onClick={() => setActive('/alerts')} active={active === '/alerts'}>
            <Link href={getPathWithExchanges('/alerts')} as="/alerts">
              { ['Tier2', 'Admin'].includes(group) ? <AiOutlineBell size={20} /> : 'Alerts' }
            </Link>
            { ['Tier2', 'Admin'].includes(group) && <span>Alerts</span> }
          </NavItem>
          <NavItem onClick={() => setActive('/events')} active={active === '/events'}>
            <Link href={getPathWithExchanges('/events')} as="/alerts">
              { ['Tier2', 'Admin'].includes(group) ? <BsFillCalendar2WeekFill size={20} /> : 'Events' }
            </Link>
            { ['Tier2', 'Admin'].includes(group) && <span>Events</span> }
          </NavItem>
          <NavItem onClick={() => setActive('/portfolio')} active={active === '/portfolio'}>
            <Link href={getPathWithExchanges('/portfolio')} as="/portfolio">
              { ['Tier2', 'Admin'].includes(group) ? <AiFillFolderOpen size={20} /> : 'Portfolio' }
            </Link>
            { ['Tier2', 'Admin'].includes(group) && <span>Portfolio</span> }
          </NavItem>
        </Nav>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
          {!['Tier2', 'Admin'].includes(group) && (
            <div onClick={() => changeTheme()} style={{ marginRight: '24px', cursor: 'pointer' }}>
              {theme === 'light'
                ? <Image src="/assets/sunBlack.png" width="24" height="24" alt="sun" />
                : <Image src="/assets/sunWhite.png" width="24" height="24" alt="sun" />}
            </div>
          )}
          {
            loggedIn && (
              !['Tier2', 'Admin'].includes(group) ? (
                <div className="navFilters" onClick={() => setShowFilters(!showFilters)}>
                  <div style={{ marginRight: '8px', marginTop: '2px' }}>
                    {theme === 'light'
                      ? <Image src="/assets/globeBlack.png" width="16" height="16" alt="globe" />
                      : <Image src="/assets/globeWhite.png" width="16" height="16" alt="globe" />}
                  </div>
                  <div style={{ fontSize: '16px' }}>
                    Filters
                  </div>
                </div>
              ) : (
                <NavItem onClick={() => setShowFilters(!showFilters)} style={{ marginRight: '24px' }}>
                  <AiOutlineGlobal size={20} />
                  <span>Filters</span>
                </NavItem>
              )
            )
          }
          {
            loggedIn && (
              <NavItem onClick={() => setActive('/account')} active={active === '/account'} style={{ marginRight: '24px' }}>
                <Link href={getPathWithExchanges('/account')} as="/account">
                  { ['Tier2', 'Admin'].includes(group) ? <FaUserCircle size={20} /> : 'Account' }
                </Link>
                { ['Tier2', 'Admin'].includes(group) && <span>Account</span> }
              </NavItem>
            )
          }
          {

            (loggedIn && ['Tier2', 'Admin'].includes(group)) && (
              <NavItem>
                <FaDoorOpen size={20} onClick={handleLogout} style={{ alignSelf: 'flex-start' }} />
                <span>Logout</span>
              </NavItem>
            )
          }
          {
            (loggedIn && !['Tier2', 'Admin'].includes(group)) && (<div onClick={handleLogout} style={{ fontSize: '16px', cursor: 'pointer' }}>Logout</div>)
          }
          {
            !loggedIn && (<SmallButton onClick={handleLogin}>Sign In</SmallButton>)
          }
        </div>
      </NavContainer>
      { showFilters && (
      <GlobalFilters
        onApply={(exchFilters: string[]) => handleFilters(exchFilters)}
        selectedExchanges={selectedFilters}
      />
      )}
      <MobileNav border={border}>
        <div style={{ cursor: 'pointer' }}>
          {theme === 'light'
            ? <Image src="/assets/mobileLogoBlack.png" width="30" height="26" alt="logo" />
            : <Image src="/assets/mobileLogoWhite.png" width="30" height="26" alt="logo" />}
        </div>
        {title
          ? (
            <div className="mobileTitle">
              {title}
            </div>
          )
          : null}
        {closed
          ? (
            <Pointer onClick={() => setClosed(false)}>
              {theme === 'light'
                ? <Image src="/assets/burgerBlack.png" width="19" height="19" alt="menu" />
                : <Image src="/assets/burgerWhite.png" width="19" height="19" alt="menu" />}
            </Pointer>
          )
          : (
            <Pointer onClick={() => setClosed(true)}>
              {theme === 'light'
                ? <Image src="/assets/xBlack.png" width="24" height="24" alt="menu" />
                : <Image src="/assets/xWhite.png" width="24" height="24" alt="menu" />}
            </Pointer>
          )}
      </MobileNav>
      {!closed
        ? (
          <BurgerMenu>
            <MobileNav border={border}>
              <div style={{ cursor: 'pointer' }} onClick={() => { setActive('/'); setClosed(false); }}>
                <Link href="/">
                  {theme === 'light'
                    ? <Image src="/assets/mobileLogoBlack.png" width="30" height="26" alt="logo" />
                    : <Image src="/assets/mobileLogoWhite.png" width="30" height="26" alt="logo" />}
                </Link>
              </div>
              {title
                ? (
                  <div className="mobileTitle">
                    {title}
                  </div>
                )
                : null}
              {closed
                ? (
                  <Pointer onClick={() => setClosed(false)}>
                    {theme === 'light'
                      ? <Image src="/assets/burgerBlack.png" width="19" height="19" alt="menu" />
                      : <Image src="/assets/burgerWhite.png" width="19" height="19" alt="menu" />}
                  </Pointer>
                )
                : (
                  <Pointer onClick={() => setClosed(true)}>
                    {theme === 'light'
                      ? <Image src="/assets/xBlack.png" width="24" height="24" alt="menu" />
                      : <Image src="/assets/xWhite.png" width="24" height="24" alt="menu" />}
                  </Pointer>
                )}
            </MobileNav>
            {/*  <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
              <Link href="/">Home</Link>
            </BurgerMenuItem> */}
            <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
              <BurgerMenuItemIcon>
                <MdOutlineSpaceDashboard size={35} />
              </BurgerMenuItemIcon>
              <Link href="/dashboard">Dashboard</Link>
            </BurgerMenuItem>
            <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
              <BurgerMenuItemIcon>
                <MdOutlinePaid size={35} />
              </BurgerMenuItemIcon>
              <Link href="/profile">Currency Profiles</Link>
            </BurgerMenuItem>
            <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
              <BurgerMenuItemIcon>
                <MdOutlineArticle size={35} />
              </BurgerMenuItemIcon>
              <Link href="/news">News and Social</Link>
            </BurgerMenuItem>
            {/* <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
              <Link href="/portfolio">Portfolio</Link>
            </BurgerMenuItem> */}
            <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
              <BurgerMenuItemIcon>
                <MdOutlineDonutSmall size={35} />
              </BurgerMenuItemIcon>
              <Link href="/screener">Market Screener</Link>
            </BurgerMenuItem>
            <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
              <BurgerMenuItemIcon>
                <MdNotificationsNone size={35} />
              </BurgerMenuItemIcon>
              <Link href="/alerts">Alerts</Link>
            </BurgerMenuItem>
            <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
              <BurgerMenuItemIcon>
                <MdNotificationsNone size={35} />
              </BurgerMenuItemIcon>
              <Link href="/events">Events</Link>
            </BurgerMenuItem>
            <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
              <BurgerMenuItemIcon>
                <AiFillFolderOpen size={35} />
              </BurgerMenuItemIcon>
              <Link href="/portfolio">Portfolio</Link>
            </BurgerMenuItem>
            {/*
              <FlexAlign style={{ width: '100%', justifyContent: 'space-around'}}>
                <Pointer>
                  <Image src="/assets/header/app-store-icon.png" width="170" height="61"/>
                </Pointer>
                <Pointer>
                  <Image src="/assets/header/google-play-icon.png" width="170" height="61"/>
                </Pointer>
              </FlexAlign>
            */}
            {
              loggedIn && (
                <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
                  <BurgerMenuItemIcon>
                    <FaUserCircle size={35} />
                  </BurgerMenuItemIcon>
                  <Link href="/account">Account</Link>
                </BurgerMenuItem>
              )
            }
            {
              loggedIn ? (
                <LogoutButton onClick={() => { setActive('/'); setClosed(true); handleLogout(); }}>
                  Logout
                </LogoutButton>
              ) : (
                <LogoutButton onClick={() => { setActive('/signin'); setClosed(true); handleLogin(); }}>
                  Sign In
                </LogoutButton>
              )
            }
          </BurgerMenu>
        )
        : null}
    </MobileHeader>
  );
};

export default Header;
