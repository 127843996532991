export const priceChangeHelper = (data: any[], filter: any) => {
  const priceChangeFields = {
    HR_PERFORM: 'hourChange',
    DLY_PERFORM: 'dayChange',
    WK_PERFORM: 'weekChange',
    YR_PERFORM: 'yearChange',
  };

  return data.filter((coinData) => {
    const coinDataField = (priceChangeFields as any)[filter.type];
    if (filter.value > 0) return coinData[coinDataField] >= filter.value;
    return coinData[coinDataField] <= filter.value;
  });
};

export const marketCapHelper = (data: any[], filter: any) => data.filter(({ marketCap }) => {
  if (!filter.value.min) return marketCap <= filter.value.max;
  if (!filter.value.max) return marketCap >= filter.value.min;
  return (marketCap >= filter.value.min && marketCap <= filter.value.max);
});

export const marketCapRawFilter = (data: any[], filter: any) => {
  if (!filter.value.min) {
    return data.filter((coinData) => coinData.market_cap <= filter.value.max);
  }
  if (!filter.value.max) {
    return data.filter((coinData) => coinData.market_cap >= filter.value.min);
  }
  return data.filter(
    (coinData) => coinData.market_cap >= filter.value.min
        && coinData.market_cap <= filter.value.max,
  );
};

export const marketCapRankHelper = (data: any[], filter: any) => data.filter(
  ({ marketCapRank }) => marketCapRank <= filter.value,
);

export const marketCapRankRawFilter = (data: any[], filter: any) => data.filter(
  (coinData) => coinData.market_cap_rank <= filter.value,
);

export const marketCapRankTrendFilter = (data: any[], filter: any) => {
  if (filter.value.min !== null) {
    return data.filter((coinData) => coinData.marketCapChange >= filter.value.min);
  }
  if (filter.value.max !== null) {
    return data.filter((coinData) => coinData.marketCapChange <= filter.value.max);
  }
  if (filter.value.equal !== null) {
    return data.filter((coinData) => coinData.marketCapChange === filter.value.equal);
  }
  return data;
};

export const twitterWowDeltaFilter = (data: any[], filter: any) => {
  if (filter.value.min !== null) {
    return data.filter((coinData) => coinData.twitterCountWowDelta >= filter.value.min);
  }
  if (filter.value.max !== null) {
    return data.filter((coinData) => coinData.twitterCountWowDelta <= filter.value.max);
  }
  if (filter.value.equal !== null) {
    return data.filter((coinData) => coinData.twitterCountWowDelta === filter.value.equal);
  }
  return data;
};

export const emaVSFilter = (data: any[], filter: any) => {
  if (filter.value === 'above') {
    return data.filter((coinData) => coinData.ema12 > coinData.ema26);
  }
  return data.filter((coinData) => coinData.ema12 < coinData.ema26);
};

export const betaFilter = (data: any[], filter: any) => {
  if (filter.value?.max) {
    return data.filter((coinData) => coinData.beta < 1);
  }
  return data.filter((coinData) => coinData.beta >= 1);
};

export const smaPriceHelper = (data: any[], filter: any) => {
  const smaPriceTypeFields = {
    SMA20_VS_PRICE: 'sma20VSprice',
    SMA50_VS_PRICE: 'sma50VSprice',
    SMA200_VS_PRICE: 'sma200VSprice',
  };

  const dataField = (smaPriceTypeFields as any)[filter.type];
  return data.filter((coinData) => {
    const smaVal = coinData[dataField];
    if (typeof filter.value !== 'number') return (smaVal >= filter.value.min && smaVal <= filter.value.max);
    if (filter.value > 0) return smaVal >= filter.value;
    return smaVal <= filter.value;
  });
};

export const smaVSHelper = (data: any[], filter: any) => {
  const smaVSTypeFields = {
    SMA20_VS_SMA50: 'sma20VSsma50',
    SMA20_VS_SMA200: 'sma20VSsma200',
    SMA50_VS_SMA200: 'sma50VSsma200',
  };
  const dataField = (smaVSTypeFields as any)[filter.type];
  return data.filter((coinData) => {
    if (filter.value === 'above') return coinData[dataField] === 1;
    return coinData[dataField] === 0;
  });
};

export const rsiHelper = (data: any[], filter: any) => data.filter(({ rsi }) => {
  if (filter.value === 'bullish') return rsi < 30;
  if (filter.value === 'bearish') return rsi > 70;
  return (rsi >= 30 && rsi <= 70);
});

export const singalHelper = (data: any[], filter: any) => data.filter(({ signal }) => {
  if (filter.value === 'buy') return signal > 0.52;
  if (filter.value === 'sell') return signal < 0.48;
  return (signal >= 0.48 && signal <= 0.52);
});

export const bollingerHelper = (data: any[], filter: any) => data.filter(({ bollinger }) => {
  if (filter.value === 1) return bollinger > 1;
  if (filter.value === 2) return bollinger === 1;
  if (filter.value === 3) return (bollinger > 0.5 && bollinger < 1);
  if (filter.value === 4) return (bollinger > 0 && bollinger < 0.5);
  if (filter.value === 5) return bollinger === 0;
  return bollinger < 0;
});

export const weekVolHelper = (data: any[], filter: any) => data.filter(({ weekVolatility }) => {
  if (filter.value === 1) return weekVolatility < 0.5;
  if (filter.value === 2) return (weekVolatility >= 0.5 && weekVolatility < 0.15);
  if (filter.value === 3) return (weekVolatility >= 0.15 && weekVolatility <= 0.25);
  return weekVolatility > 0.25;
});

export const dailyVolHelper = (data: any[], filter: any) => data.filter(({ dailyVolume }) => {
  if (filter.value > 0) return dailyVolume > filter.value;
  return dailyVolume < 1;
});

export const simpleHelper = (data: any[], filter: any) => {
  const simpleTypeFields = {
    MACD: 'macd',
    SECTOR: 'sector',
    SUB_SECTOR: 'subsector',
    LOCATION: 'location',
    PLATFORM: 'platform',
    VALUATION: 'valuation',
  };
  if (filter.type !== 'INDUSTRY') {
    return data.filter(
      (coinData) => coinData[(simpleTypeFields as any)[filter.type]] === filter.value,
    );
  }
  return data.filter(
    (coinData) => coinData[(simpleTypeFields as any)[filter.type]]?.includes(filter.value),
  );
};

export const volumeAvgHelper = (data: any[], filter: any) => data.filter(({ volumeAvg }) => {
  if (filter.value === 1) return volumeAvg >= 1000;
  if (filter.value === 2) return (volumeAvg >= 500 && volumeAvg < 1000);
  if (filter.value === 3) return (volumeAvg >= 100 && volumeAvg < 500);
  if (filter.value === 4) return (volumeAvg >= 10 && volumeAvg < 100);
  return volumeAvg < 10;
});

export const eventHelper = (data: any[], filter: any) => data.filter(({
  event7, event15, event30, eventAny,
}) => {
  if (filter.value === 1) return event7;
  if (filter.value === 2) return event15;
  if (filter.value === 3) return event30;
  return eventAny;
});

export const sentimentHelper = (data: any[], filter: any) => {
  const sentTypeFields = {
    NEWS_SENT: 'sentiment',
    TWITTER_SENT: 'twitterSentiment',
    REDDIT_SENT: 'redditSentiment',
  };
  const dataField = (sentTypeFields as any)[filter.type];
  return data.filter((coinData) => {
    const sentimenVal = coinData[dataField];
    return (sentimenVal > filter.value.min && sentimenVal <= filter.value.max);
  });
};

export const trendTypeHelper = (data: any[], filter: any) => {
  const trendTypeFields = {
    SENT_TREND: ['newsTrend', 'sentiment'],
    TWITTER_TREND: ['twitterTrend', 'twitterSentiment'],
    REDDIT_TREND: ['redditTrend', 'redditSentiment'],
  };
  const dataFieldOne = (trendTypeFields as any)[filter.type][0];
  const dataFieldTwo = (trendTypeFields as any)[filter.type][1];
  return data.filter((coinData) => {
    if (coinData[dataFieldOne] > 20) {
      if (filter.value === 'bullish') return coinData[dataFieldTwo] > 0.5;
      return coinData[dataFieldTwo] < 0.5;
    }
    return false;
  });
};

export const valuationHelper = (data: any[], filter: any) => data.filter((coinData) => {
  if (filter.value === 1) {
    return coinData.valuation > coinData.price;
  }
  return coinData.valuation < coinData.price;
});
