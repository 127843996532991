/* eslint-disable no-unused-vars */
import styled from 'styled-components';

interface Props {
  flexDirection?: string;
  flexDirectionMobile?: string;
  alignItems?: string;
  flexWrap?: string;
  flex?: number;
  group?: string;
}

const PageContainer = styled.div<Props>`
  width: ${({ group }) => (['Tier2', 'Admin'].includes(group || '') ? '95%' : '100%')};
  display: flex;
  flex: ${({ flex }) => (flex || 1)};
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'column')};
  justify-content: 'center';
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  box-sizing: border-box;
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : 'wrap')};
  padding: 20px 50px 20px 50px;
  margin-left: ${({ group }) => (['Tier2', 'Admin'].includes(group || '') ? '5%' : '0')};
  @media screen and (max-width: 1250px) {
    padding: 20px;
    margin-left: 0;
    width: 100%;
    flex-direction: ${({ flexDirection, flexDirectionMobile }) => (
    flexDirectionMobile || flexDirection || 'column'
  )};
  }
`;

export default PageContainer;
