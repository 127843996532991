import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import {
  FiltersContainer, FiltersTitle, FiltersSubtitle, FiltersCheckboxes,
  Flex, FiltersApply, FiltersApplyText, FiltersApplySmallText,
} from '../globalStyles/common';
import { RootState } from '../../store';

interface GlobalFiltersProps {
  onApply: Function;
  selectedExchanges: string[];
  sidebar?: boolean;
}

const GlobalFilters: React.FC<GlobalFiltersProps> = (props: GlobalFiltersProps) => {
  const { onApply, selectedExchanges, sidebar } = props;
  const [appliedFilters, setAppliedFilters] = React.useState<string[]>(selectedExchanges || []);
  const accountState: any = useSelector((state: RootState) => state.account);
  const { exchanges, exchange_filter: exchangeFilter = [] } = accountState;

  useEffect(() => {
    setAppliedFilters(exchangeFilter);
  }, [exchangeFilter]);

  const toggleFilter = (selectedFilter: string) => {
    let newFilters = [];
    if (appliedFilters.includes(selectedFilter)) {
      newFilters = appliedFilters.filter((itm) => itm !== selectedFilter);
    } else {
      newFilters = [...appliedFilters, selectedFilter];
    }
    setAppliedFilters(newFilters);
  };

  const apply = () => {
    onApply(appliedFilters);
  };

  const removeAll = () => {
    setAppliedFilters([]);
    onApply([]);
  };

  return (
    <FiltersContainer style={sidebar ? { padding: 0, marginBottom: '32px' } : {}}>
      <FiltersTitle>
        Exchanges
      </FiltersTitle>
      <FiltersSubtitle>
        Select exchanges relevant to your trading accounts
      </FiltersSubtitle>
      <Flex style={{ marginTop: '10px' }}>
        <FiltersApply onClick={() => setAppliedFilters(exchanges)} style={{ marginRight: '20px' }} disabled={false}>
          <FiltersApplySmallText>
            Select All
          </FiltersApplySmallText>
        </FiltersApply>
      </Flex>
      <FiltersCheckboxes>
        {exchanges.map((filter: string) => (
          <Flex key={`exchange_filter_${filter}`}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              onMouseDown={() => toggleFilter(filter)}
              style={{
                marginRight: '5px', maxHeight: '20px', cursor: 'pointer',
              }}
            >
              {!appliedFilters.includes(filter)
                ? <MdCheckBoxOutlineBlank size={20} />
                : <MdCheckBox size={20} />}
            </div>
            <FiltersSubtitle>
              {filter}
            </FiltersSubtitle>
          </Flex>
        ))}
      </FiltersCheckboxes>
      <Flex style={sidebar ? { justifyContent: 'space-between' } : {}}>
        <FiltersApply disabled={appliedFilters.length === 0} onClick={() => apply()}>
          <FiltersApplyText>
            {`(${appliedFilters.length}) Apply`}
          </FiltersApplyText>
        </FiltersApply>
        <FiltersApply disabled={exchangeFilter.length === 0} onClick={() => removeAll()}>
          <FiltersApplySmallText>
            Remove All
          </FiltersApplySmallText>
        </FiltersApply>
        {!sidebar && (
          <FiltersSubtitle>
            Changes apply sitewide
          </FiltersSubtitle>
        )}
      </Flex>
      {sidebar && (
        <FiltersSubtitle style={{ marginTop: '12px' }}>
          Changes apply sitewide
        </FiltersSubtitle>
      )}
    </FiltersContainer>
  );
};

GlobalFilters.defaultProps = {
  sidebar: false,
};

export default GlobalFilters;
