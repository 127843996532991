import React, { useEffect } from 'react';

export interface HubspotContactFormProps {
  hubspotID: string;
  elemID: string;
}

const HubspotContactForm = ({ hubspotID, elemID }:HubspotContactFormProps) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js?pre=1';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region: 'na1',
          portalId: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
          formId: hubspotID,
          target: `#${elemID}`,
        });
      }
    });
  }, []);

  return (
    <div>
      <div id={elemID} />
    </div>
  );
};

export default HubspotContactForm;
