import * as React from 'react';
import styled from 'styled-components';
import {
  FiChevronDown,
  FiChevronUp,
} from 'react-icons/fi';
import {
  OptionsContainer, SelectorContainer, SelectorDisplay, Option,
} from '../globalStyles/common';
// import { LockedOverlay } from '..';

interface SelectorOptionProps {
  display: any;
  value: string;
  searchName?: string;
}

interface SimpleSelectorProps {
  options: any[]
  onSelect: Function
  placeholder: string
  selected?: SelectorOptionProps
  reset?: boolean
  onReset?: React.Dispatch<React.SetStateAction<string>>
  // eslint-disable-next-line react/no-unused-prop-types
  group?: string;
}

const SelectInput = styled.input`
  outline: none;
  color: ${({ theme }) => theme.site.color};
  background: ${({ theme }) => theme.site.bannerBackground};
  border: none;
`;

const SearchSelector: React.FC<SimpleSelectorProps> = (props: SimpleSelectorProps) => {
  const {
    options = [], onSelect = () => {}, placeholder = 'Placeholder', reset = false, onReset = () => {}, selected,
  } = props;
  const [selectorValue, setSelectorValue] = React.useState(selected?.display || '');
  const [showOptions, setShowOptions] = React.useState(false);
  const [, setIsOptionsHover] = React.useState(false);
  const [searchVal, setSearchVal] = React.useState('');
  const [optionsToShow, setOptionsToShow] = React.useState(options);

  React.useEffect(() => {
    if (reset) {
      setSelectorValue('');
      onReset('');
    }
  }, [reset]);

  const handleOptionClick = (itm: SelectorOptionProps) => {
    if (itm.value) {
      setSelectorValue(itm.display);
    } else {
      setSelectorValue('');
    }
    onSelect(itm);
    setShowOptions(false);
  };

  const handleSearch = (val: string) => {
    setSearchVal(val);
    let tmpOptions = [...options];
    // eslint-disable-next-line max-len
    tmpOptions = tmpOptions.filter((option) => option.display.toLowerCase().startsWith(val.toLowerCase()) || (option.searchName && option.searchName.toLowerCase().startsWith(val.toLowerCase())));
    setOptionsToShow(tmpOptions);
  };

  const renderOptions = () => optionsToShow.map((itm: SelectorOptionProps, idx: number) => (
    // TODO remove idx
    // eslint-disable-next-line react/no-array-index-key
    <Option key={`option_${idx}_${itm.value}`} onClick={() => handleOptionClick(itm)}>
      {itm.display}
    </Option>
  ));

  return (
    <SelectorContainer onMouseLeave={() => setShowOptions(false)}>
      <SelectorDisplay hasValue={selectorValue !== ''} onClick={() => setShowOptions(!showOptions)}>
        {!showOptions
          ? <span>{ selectorValue || placeholder }</span>
          : (
            <SelectInput
              placeholder={placeholder}
              value={searchVal}
              onChange={(e) => handleSearch(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          )}
        {showOptions ? <FiChevronUp /> : <FiChevronDown />}
      </SelectorDisplay>
      {
        showOptions && (
          <OptionsContainer
            onMouseOver={() => setIsOptionsHover(true)}
            onMouseOut={() => setIsOptionsHover(false)}
          >
            { renderOptions() }
          </OptionsContainer>
        )
      }
      {/*  { !['Tier1', 'Tier2'].includes(group as string) && <LockedOverlay />} */}
    </SelectorContainer>
  );
};

SearchSelector.defaultProps = {
  reset: false,
  onReset: () => {},
  group: 'Tier0',
  selected: {
    display: '',
    value: '',
  },
};

export default SearchSelector;
