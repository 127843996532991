import * as React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { BsCircle, BsCircleFill } from 'react-icons/bs';
import {
  OptionsContainer, SelectorContainer, SelectorDisplay, Option,
} from '../globalStyles/common';
// import { LockedOverlay } from '..';

interface SelectorOptionProps {
  display: any;
  value: string;
}

interface SimpleSelectorProps {
  options: any[];
  onSelect: Function;
  placeholder: string;
  reset?: boolean;
  onReset?: React.Dispatch<React.SetStateAction<string>>;
  // eslint-disable-next-line react/no-unused-prop-types
  group?: string;
}

const MultiSelector: React.FC<SimpleSelectorProps> = (props: SimpleSelectorProps) => {
  const {
    options = [], onSelect = () => {}, placeholder = 'Placeholder', reset = false, onReset = () => {},
  } = props;
  const [showOptions, setShowOptions] = React.useState(false);
  const [chosenOptions, setChosenOptions] = React.useState<SelectorOptionProps[]>([{ display: 'Any', value: '' }]);
  const [, setIsOptionsHover] = React.useState(false);

  React.useEffect(() => {
    if (reset) {
      onReset('');
      setChosenOptions([{ display: 'Any', value: '' }]);
    }
  }, [reset]);

  const handleOptionClick = (itm: SelectorOptionProps) => {
    let opts: SelectorOptionProps[] = [...chosenOptions];
    if (itm.value) {
      opts = opts.filter((opt) => opt.value);
      if (opts.some((opt) => opt.value === itm.value)) {
        opts = opts.filter((opt) => opt.value !== itm.value);
      } else {
        opts.push(itm);
      }
      setChosenOptions(opts);
      onSelect({ data: opts });
    } else {
      setChosenOptions([itm]);
      onSelect({ data: [itm] });
    }
  };

  const renderOptions = () => options.map((itm: SelectorOptionProps) => (
    <Option key={`option_${itm.display}`} onClick={() => handleOptionClick(itm)}>
      <div style={{ marginRight: '5px', marginTop: '10px' }}>
        {
          chosenOptions.some((opt) => opt.display === itm.display)
            ? <BsCircleFill color="#55B1EC" />
            : <BsCircle color="#55B1EC" />
        }
      </div>
      {itm.display}
    </Option>
  ));

  return (
    <SelectorContainer onMouseLeave={() => setShowOptions(false)}>
      <SelectorDisplay hasValue={false} onClick={() => setShowOptions(!showOptions)}>
        <span>{placeholder}</span>
        {showOptions ? <FiChevronUp /> : <FiChevronDown />}
      </SelectorDisplay>
      {
        showOptions && (
          <OptionsContainer
            onMouseOver={() => setIsOptionsHover(true)}
            onMouseOut={() => setIsOptionsHover(false)}
          >
            { renderOptions() }
          </OptionsContainer>
        )
      }
      {/*  { !['Tier1', 'Tier2'].includes(group as string) && <LockedOverlay /> } */}
    </SelectorContainer>
  );
};

MultiSelector.defaultProps = {
  reset: false,
  onReset: () => {},
  group: 'Tier0',
};

export default MultiSelector;
