import styled from 'styled-components';

const AlertsTableContainer = styled.div`
  min-height: 100px;
  position: relative;
  padding-bottom: 20px;
  width: 100%;
  div:first-child {
    div:first-child {
      border: none;
      max-height: 62vh;
    }
  }
  table {
    background: ${({ theme }) => theme.site.background};
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    th {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      text-align: right;
      :nth-child(2) {
        text-align: center;
      }
    }
    td {
      background: ${({ theme }) => theme.site.bannerBackground};
    }
    th, td {
      border-top: 1px solid ${({ theme }) => theme.site.bannerBorder};
      border-botom: 1px solid ${({ theme }) => theme.site.bannerBorder};
      padding: 16px;
    }
    th:first-child, td:first-child {
      text-align: left;
    }
    th:first-child, th:nth-child(4), td:first-child, td:nth-child(4) {
      border-left: 1px solid ${({ theme }) => theme.site.bannerBorder};
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    th:nth-child(2), th:nth-child(11), td:nth-child(2), td:nth-child(11) {
      border-right: 1px solid ${({ theme }) => theme.site.bannerBorder};
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    th:last-child, th:nth-child(3), td:nth-child(3), td:last-child {
      border: none;
      background: transparent;
    }
    th, td:first-child {
      background: ${({ theme }) => theme.site.bannerBackground};
      position: sticky;
      left: 0;
    }
    th {
      top: 0;
      z-index: 2;
    }
    td:first-child {
      z-index: 1;
    }
    th:first-child {
      z-index: 3;
    }
    @media screen and (max-width: 1360px) {
      border-spacing: 0;
      th, td {
        border-radius: 0 !important;
      }
      td {
        border-top: none;
        border-bottom: 1px solid ${({ theme }) => theme.site.bannerBorder};
      }
      th:first-child, td:first-child {
        box-shadow: 4px 0 4px -1px rgba(0, 0, 0, .1);
      }
      th:nth-child(2), td:nth-child(2) {
        border-right: none;
      }
      th:nth-child(3), td:nth-child(3) {
        display: none;
      }
      th:nth-child(4), td:nth-child(4) {
        border-left: none;
      }
    }
  }
`;

export default AlertsTableContainer;
