/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { MdDashboard } from 'react-icons/md';
import {
  BsNewspaper, BsGlobe, BsFillBriefcaseFill,
  BsFillBellFill, BsFillCalendar2WeekFill, BsFillChatDotsFill,
} from 'react-icons/bs';
import { FaFilter, FaEthereum, FaUserAlt } from 'react-icons/fa';
import { AiOutlinePoweroff } from 'react-icons/ai';
import SidebarNavItem from './SidebarNavItem';
import { useSelectedContext } from '../../../pages/_app';
import { GlobalFilters } from '..';
import { logout, oauthLogout, applyFilters } from '../../account/actions';

const Container = styled.div`
  width: 5%;
  position: fixed;
  padding-top: 25px;
  height: 100vh;
  background: ${({ theme }) => theme.site.bannerBackground};
  top: 0;
  left: 0;
  z-index: 100000;
  overflow: hidden;
  overflow-y: scroll;
  transition: width 300ms;
  &:hover {
    width: 300px;
    box-shadow: 10px 10px 10px #00000050;
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const BorderLine = styled.hr`
  width: 73%;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.site.bannerBorder};
`;

const ListContainer = styled.div`
  text-align: center;
  padding-right: 28px;
  padding-left: 28px;
  padding-top: 25px;
`;

interface SidebarProps {
  oauthLogoutUrl: string,
  group?: string,
}

const Sidebar: React.FC<SidebarProps> = ({ group, oauthLogoutUrl }: SidebarProps) => {
  const result: any = useSelector((state) => state);
  const { changeValue } = useSelectedContext();
  const { exchange_filter: exchangeFilter = [], refresh_token: refreshToken } = result.account;
  const router = useRouter();
  const dispatch = useDispatch();
  const [active, setActive] = React.useState(router?.pathname);
  const [selectedFilters, setSelectedFilters] = React.useState<string[]>(exchangeFilter || []);
  const [showBar, setShowBar] = React.useState(false);

  /*   useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.chatbase.co/embed.min.js';
    script.id = 'uYa0vpCjLqR4OjpheYjt1';
    script.defer = true;
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window) {
        window.chatbaseConfig = {
          chatbotId: 'uYa0vpCjLqR4OjpheYjt1',
        };
      }
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []); */

  const getPathWithExchanges = (path: string, additionalQuery?: any) => {
    if (selectedFilters.length > 0) {
      const exchParam = selectedFilters.join(',');

      return {
        pathname: path,
        query: { exchanges: exchParam, ...additionalQuery },
      };
    }
    return {
      pathname: path,
      query: additionalQuery,
    };
  };

  const handleLogout = () => {
    if (!refreshToken) {
      dispatch(oauthLogout());
      window.location.href = oauthLogoutUrl;
    } else {
      dispatch(logout());
      router.push('/');
    }
    changeValue('light');
    dispatch({ type: 'CHANGE_THEME', payload: 'light' });
    localStorage.setItem('theme', 'light');
  };

  const handleRerouting = (asPath: string, searchParams: string) => {
    const { replace } = router;
    const trueURL = `${asPath}?${searchParams}`;
    const visibleURL = asPath;
    replace(trueURL, visibleURL, { scroll: false });
  };

  const addExchURLParam = (exchFilters: string[]) => {
    const { query, asPath } = router;
    const nonExchKeys = Object.keys(query).filter((k) => k !== 'exchanges');
    const urlParams = nonExchKeys.map((paramKey) => `${paramKey}=${query[paramKey]}`);
    const exchParam = `exchanges=${exchFilters.join(',')}`;

    const searchParams = urlParams.length > 0
      ? `${urlParams.join('&')}&${exchParam}`
      : `${exchParam}`;

    handleRerouting(asPath, searchParams);
  };

  const handleFilters = (exch: string[]) => {
    dispatch(applyFilters(exch));
    setSelectedFilters(exch);

    const { query, asPath } = router;

    if (exch.length > 0) {
      addExchURLParam(exch);
    } else {
      const nonExchKeys = Object.keys(query).filter((k) => k !== 'exchanges');
      const urlParams = nonExchKeys.map((paramKey) => `${paramKey}=${query[paramKey]}`);
      const searchParams = urlParams.join('&');

      handleRerouting(asPath, searchParams);
    }
    // setShowFilters(false);
  };

  useEffect(() => {
    const { query } = router;

    if (exchangeFilter.length > 0 && !query.exchanges) {
      addExchURLParam(exchangeFilter);
    }
    if (['Tier2', 'Admin'].includes(group || '')) {
      changeValue('dark');
      dispatch({ type: 'CHANGE_THEME', payload: 'dark' });
      localStorage.setItem('theme', 'dark');
    }
  }, []);

  const onLeave = () => {
    setShowBar(false);
  };

  return (
    <Container style={{}} onMouseOver={() => setShowBar(true)} onMouseLeave={onLeave}>
      <div style={{ textAlign: 'center' }}>
        <Image src="/assets/sidebarLogo.png" width={40} height={40} alt="logo" />
      </div>
      <BorderLine style={{ marginTop: '35px' }} />
      <ListContainer>
        <SidebarNavItem
          active={active === '/dashboard'}
          setActive={() => setActive('/dashboard')}
          icon={(color: string) => <MdDashboard size={25} color={color} />}
          title="Dashboard"
          expanded={showBar}
          link={getPathWithExchanges('/dashboard')}
          linkAs="/dashboard"
        />
        <SidebarNavItem
          active={active === '/news'}
          setActive={() => setActive('/news')}
          icon={(color: string) => <BsNewspaper size={25} color={color} />}
          title="News and Social"
          expanded={showBar}
          subNav={[
            { title: 'News', link: getPathWithExchanges('/news', { mode: 'news' }), linkAs: '/news' },
            { title: 'Reddit Feed', link: getPathWithExchanges('/news', { mode: 'reddit' }), linkAs: '/news' },
          ]}
          subNavHeight="150px"
        />
        <SidebarNavItem
          active={active === '/screener'}
          setActive={() => setActive('/screener')}
          icon={(color: string) => <FaFilter size={25} color={color} />}
          title="Market Screener"
          expanded={showBar}
          link={getPathWithExchanges('/screener')}
          linkAs="/screener"
        />
        <SidebarNavItem
          active={active === '/alerts'}
          setActive={() => setActive('/alerts')}
          icon={(color: string) => <BsFillBellFill size={25} color={color} />}
          title="Market Alerts"
          expanded={showBar}
          link={getPathWithExchanges('/alerts')}
          linkAs="/alerts"
        />
        <SidebarNavItem
          active={active === '/profile'}
          setActive={() => setActive('/profile')}
          icon={(color: string) => <FaEthereum size={25} color={color} />}
          title="Currency Profiles"
          expanded={showBar}
          link={getPathWithExchanges('/profile')}
          linkAs="/profile"
        />
        <SidebarNavItem
          active={active === '/events'}
          setActive={() => setActive('/events')}
          icon={(color: string) => <BsFillCalendar2WeekFill size={25} color={color} />}
          title="Events"
          expanded={showBar}
          link={getPathWithExchanges('/events')}
          linkAs="/events"
        />
        <SidebarNavItem
          active={active === '/gpt'}
          setActive={() => setActive('/gpt')}
          icon={(color: string) => <BsFillChatDotsFill size={25} color={color} />}
          title="UptickGPT (Beta)"
          expanded={showBar}
          link={getPathWithExchanges('/gpt')}
          linkAs="/gpt"
        />
        <SidebarNavItem
          active={active === '/portfolio'}
          setActive={() => setActive('/portfolio')}
          icon={(color: string) => <BsFillBriefcaseFill size={25} color={color} />}
          title="Risk Management"
          subNav={[
            { title: 'Gain/Loss', link: { pathname: '/portfolio', query: { mode: 'gainLoss' } } },
            { title: 'Optimization', link: { pathname: '/portfolio', query: { mode: 'optimize' } } },
            { title: 'Drawdown', link: { pathname: '/portfolio', query: { mode: 'drawdown' } } },
            { title: 'Alerts & News', link: { pathname: '/portfolio', query: { mode: 'alerts-news' } } },
            { title: 'Scenario Analysis', link: { pathname: '/portfolio', query: { mode: 'scenario-analysis' } } },
            /*  { title: 'Factor Model', link: getPathWithExchanges('/portfolio', { mode: 'factor-model' }) }, */
          ]}
          subNavHeight="350px"
          expanded={showBar}
        />
      </ListContainer>
      <BorderLine />
      <ListContainer>
        <SidebarNavItem
          icon={(color: string) => <BsGlobe size={25} color={color} />}
          title="Global Filters"
          expanded={showBar}
          customOption={[<GlobalFilters
            onApply={(exchFilters: string[]) => handleFilters(exchFilters)}
            selectedExchanges={selectedFilters}
            sidebar
          />]}
          subNavHeight="400px"
        />
        <SidebarNavItem
          active={active === '/account'}
          setActive={() => setActive('/account')}
          icon={(color: string) => <FaUserAlt size={25} color={color} />}
          title="Account"
          expanded={showBar}
          link={getPathWithExchanges('/account')}
          linkAs="/account"
        />
        <SidebarNavItem
          icon={(color: string) => <AiOutlinePoweroff size={25} color={color} />}
          title="Logout"
          expanded={showBar}
          onClick={() => handleLogout()}
        />
      </ListContainer>
    </Container>
  );
};

Sidebar.defaultProps = {
  group: '',
};

export default Sidebar;
