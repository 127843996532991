import React from 'react';
import { getCurrencyIcon } from '../../utils';

export interface CryptoIconProps {
  symbol: string;
  width?: string;
  height?: string;
}

const CryptoIcon = (props: CryptoIconProps) => {
  const { symbol, width = '32', height = '32' } = props;
  const src = getCurrencyIcon(symbol);

  return (
    <img
      src={src}
      width={width}
      height={height}
      alt={`${symbol} logo`}
    />
  );
};

export default CryptoIcon;
