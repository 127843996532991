import React from 'react';
import styled from 'styled-components';
// import { FiCheck, AiFillCloseCircle } from 'react-icons/fi';
import {
  AiFillCheckCircle, AiFillCloseCircle,
} from 'react-icons/ai';
import SelectButton from './SelectButton';

const uptickFeatures = [
  'Market Dashboard',
  'Currency Profiles',
  'News and Social',
  'Technical Workspace',
  'Market Screener',
  'Trading Alerts',
  'Portfolio Management',
  'Risk Analytics',
  'Portfolio Optimization',
  'Downloadable Data',
  'Desktop Application',
  'Paper Trading',
  'Trading Application',
];

const subscriptionCost : any = {
  Basic: { price: 0, text: 'Free' },
  Premium: { price: 500, text: '$500' },
  Pro: { price: 250, text: '$250' },
};

const NoButton = styled.div`
  width: 100%;
  max-width: 300px;
  height: 40px;
  padding: 5px;
  margin-top: 15px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes[3]};
`;

const Container = styled.div`
  position: relative;
  box-shadow: 0 0 10px #00000050;
  display: flex;
  flex-direction: column;
  justify-content: end;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px 50px;
  margin: 0 10px;
  min-width: 300px;
  height: 800px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    align-items: center;
    margin: 5px 0;
  }
`;

const HeaderText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding: 10px 10px;
  text-align: center;
  & > span {
    font-size: ${({ theme }) => theme.fontSizes[1]};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }
`;

const Body = styled.div`
  margin-bottom: 15px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & > div {
    width: 100%;
    margin-left: 5px;
    padding-left: 5px;
    padding-bottom: 10px;
    padding-top: 10px;

    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
  &:last-child > div {
    border-bottom: none;
  }
  & > div > span {
    font-size: ${({ theme }) => theme.fontSizes[1]};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-style: italic;
    opacity: 0.8;
    margin-left: 5px;
  }
`;

const StrikedPrice = styled.span`
  color: red;
  text-decoration: line-through;
`;

const renderFeatures = (type: string) => uptickFeatures.map((itm : string, idx : number) => {
  if (type === 'Premium') {
    return (
      <Row>
        { (idx < 7 || idx > 10) ? <AiFillCheckCircle size={20} color="#39DF9F" /> : <AiFillCloseCircle size={20} color="#F9423D" /> }
        <div>
          { itm }
          { [11, 12].includes(idx) && <span>(Coming Soon)</span> }
        </div>
      </Row>
    );
  }
  if (type === 'Pro') {
    return (
      <Row>
        <AiFillCheckCircle size={20} color="#39DF9F" />
        <div>
          { itm }
          { [11, 12].includes(idx) && <span>(Coming Soon)</span> }
        </div>
      </Row>
    );
  }
  return (
    <Row>
      { idx < 6 ? <AiFillCheckCircle size={20} color="#39DF9F" /> : <AiFillCloseCircle size={20} color="#F9423D" /> }
      <div>
        { itm }
        { [0, 4, 5].includes(idx) && <span>(Limited)</span> }
        { [11, 12].includes(idx) && <span>(Coming Soon)</span> }
      </div>
    </Row>
  );
});

const getPrice = (discount: number, type: string) => {
  if (type === 'Pro') return '';
  if (discount !== 0 && type !== 'Basic') {
    const priceWithDiscount = subscriptionCost[type].price * (1 - discount);
    return `$${priceWithDiscount.toFixed(2)}`;
  }

  return subscriptionCost[type].text;
};

interface FeatureCardProps {
  type: string;
  onClick: Function;
  active: boolean;
  isAccount?: boolean;
  discount?: number;
  isCurrentPlan?: boolean;
}

const FeatureCard = ({
  type, onClick, active, isAccount = false, discount = 0, isCurrentPlan,
} : FeatureCardProps) => (
  <Container>
    <HeaderText style={{ height: 200 }}>
      {
        type === 'Premium' ? 'Market Analytics' : (
          <div>
            Market Analytics
            <br />
            +
            <br />
            Risk Management
          </div>
        )
      }
    </HeaderText>
    <Body>
      {renderFeatures(type)}
    </Body>
    <HeaderText>
      {
        discount !== 0 && type !== 'Pro'
          && (<StrikedPrice>{subscriptionCost[type].text}</StrikedPrice>)
      }
      {getPrice(discount, type)}
      {type !== 'Pro' && (
      <span>
        {'\n'}
        / Month
      </span>
      )}
    </HeaderText>
    {
      isAccount && isCurrentPlan && <NoButton>Current Plan</NoButton>
    }
    {
      type === 'Pro'
        ? <SelectButton active={active} label="Contact" activeLabel="Contact" onClick={() => onClick()} />
        : ((isAccount && !isCurrentPlan) || !isAccount)
            && <SelectButton active={active} label="Choose Plan" activeLabel="Selected" onClick={() => onClick()} />
    }
  </Container>
);

FeatureCard.defaultProps = {
  isAccount: false,
  discount: 0,
  isCurrentPlan: false,
};

export default FeatureCard;
