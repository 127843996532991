/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { AiFillFolderOpen } from 'react-icons/ai';
import { FaLockOpen } from 'react-icons/fa';
import {
  MdOutlineSpaceDashboard,
  MdOutlinePaid,
  MdOutlineArticle,
  MdOutlineDonutSmall,
  MdNotificationsNone,
} from 'react-icons/md';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { CgArrowLongRight } from 'react-icons/cg';
import {
  NavContainer,
  Nav,
  NavLogo,
  LogoutButton,
  Pointer,
  MobileNav,
  MobileHeader,
  SmallButton,
} from '../globalStyles/common';

interface HeaderUnauthProps {
  title?: string;
  border?: boolean;
}

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: 19px;
  margin-right: 32px;
  cursor: pointer;
`;

const DropdownLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface DropdownMenuProps {
  showDropdown: boolean;
}

const DropdownMenu = styled.div<DropdownMenuProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: absolute;
  top: 100px;
  width: 100%;
  left: 0;
  border-radius: 5px;
  border: ${({ theme, showDropdown }) => (showDropdown ? `1px solid ${theme.site.bannerBorder}` : 'none')};
  padding: ${({ showDropdown }) => (showDropdown ? '10px' : '0')};
  background: white;
  z-index: 1000000;
  height: ${({ showDropdown }) => (showDropdown ? '200px' : '0')};
  overflow: hidden;
  transition: height ease 300ms;
  @media screen and (max-width: 1650px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    height: ${({ showDropdown }) => (showDropdown ? '400px' : '0')};
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const NavText = styled.div`
  white-space: normal;
  text-align: center;
  margin-top: 20px;
  width: 200px;
  height: 70px;
`;

interface NavItemProps {
  isLocked?: boolean;
  active?: boolean;
  onClick?: Function;
}

const NavItem = styled.div<NavItemProps>`
  white-space: nowrap;
  margin-right: 32px;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  opacity: ${(props) => (props.isLocked ? 0.4 : 1)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  & > a {
  }
  &:after {
    content: "";
    display: block;
    width: 50%;
    margin-left: 25%;
    padding-top: 2px;
    border-radius: 1px;
  }
  & > a {
    font-size: 18px;
    font-weight: bold;
    color: ${({ theme }) => theme.site.color};
    position: relative;
    padding-bottom: 5px;
    margin-right: ${({ isLocked }) => (isLocked ? '5px' : '0')};
    border-bottom: ${({ active }) => (active ? '2px solid #55B1EC' : 'none')};
  }
  & > a:hover {
    opacity: 1.0;
    font-weight: bold;
  }
  ${({ active }) => (!active
    ? `& > a::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      background: none repeat scroll 0 0 transparent;
      height: 2px;
      border-radius: 1px;
      width: 0;
      background: #55B1EC;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
  }` : '')};
  & > a:hover::after {
    width: 50%;
    left: 25%;
  }
  & span {
    position: absolute;
    bottom: -15px;
    left: 0;
    opacity: 0;
    display: flex;
    align-items: flex-end;
  }
  & span:hover {
    opacity: 1;
  }
  & :hover {
    opacity: 0.8;
  }
  & :hover + span {
    opacity: 1;
  }
`;

const BurgerMenu = styled.div`
  display: none;
  @media screen and (max-width: 1200px) {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background: ${({ theme }) => theme.site.background};
    overflow-x: none;
    overflow-y: scroll;
    padding-bottom: 50px;
  }
`;

interface BurgerMenuItemProps {
  isLocked?: boolean;
}

const BurgerMenuItem = styled.div<BurgerMenuItemProps>`
  @media screen and (max-width: 1200px) {
    width: 100%;
    opacity: ${(props) => (props.isLocked ? 0.4 : 1)};
    font-size: 21px;
    line-height: 23px;
    padding-left: 24px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    & > a {
      color: ${({ theme }) => theme.site.color};
      margin-right: ${({ isLocked }) => (isLocked ? '5px' : '0')};
    }
  }
`;

const BurgerMenuItemIcon = styled.div`
  margin-right: 21px;
`;

interface MobileDropdownProps {
  showDropdown: boolean;
}

const MobileDropdown = styled.div<MobileDropdownProps>`
  overflow: hidden;
  width: 100%;
  padding-left: ${({ showDropdown }) => (showDropdown ? '20px' : '0')};
  background: white;
  z-index: 1000000;
  height: ${({ showDropdown }) => (showDropdown ? '500px' : '0')};
  transition: height ease 300ms;
`;

const HeaderUnauth: React.FC<HeaderUnauthProps> = ({ title, border }: HeaderUnauthProps) => {
  const result: any = useSelector((state) => state);
  const router = useRouter();
  const [active, setActive] = React.useState(router?.pathname);
  const [closed, setClosed] = React.useState(true);
  const [showDropdown, setShowDropdown] = React.useState(false);

  const { loggedIn } = result.account;

  const handleLogin = () => {
    router.push('/signin');
  };

  const handleNav = (path: string) => {
    setActive(path);
    router.push(path);
  };

  const { theme } = result.currencyProfile;

  return (
    <MobileHeader>
      <NavContainer>
        <Nav>
          <NavLogo onClick={() => setActive('/')}>
            <Link href="/">
              <div>
                {theme === 'light'
                  ? <Image src="/assets/logoBlack.png" width="106" height="20" alt="logo" />
                  : <Image src="/assets/logo.png" width="106" height="20" alt="logo" />}
              </div>
            </Link>
          </NavLogo>
        </Nav>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-around', fontWeight: 'bold',
        }}
        >
          <NavItem onClick={() => setActive('/terminal')} active={active === '/terminal'}>
            <Link href="/terminal"><div>Terminal</div></Link>
          </NavItem>
          <NavItem onClick={() => setActive('/signin')} active={active === '/signin'}>
            <Link href="/pricing"><div>Pricing</div></Link>
          </NavItem>
          <NavItem onClick={() => setActive('/downloads')} active={active === '/downloads'}>
            <Link href="/downloads"><div>Downloads</div></Link>
          </NavItem>
          <DropdownContainer>
            <DropdownLabel onClick={() => setShowDropdown(!showDropdown)}>
              Applications
              {
                showDropdown
                  ? <BsChevronUp style={{ marginLeft: 10 }} />
                  : <BsChevronDown style={{ marginLeft: 10 }} />
              }
            </DropdownLabel>
          </DropdownContainer>
          <SmallButton onClick={handleLogin}>Login</SmallButton>
        </div>
        <DropdownMenu onClick={() => setShowDropdown(false)} showDropdown={showDropdown}>
          <NavItem onClick={() => handleNav('/dashboard')} active={active === '/dashboard'}>
            <Link href="/dashboard" as="/dashboard">Market Dashboard</Link>
            <NavText>A 360° view of crypto market with powerful analytics & visualizations</NavText>
            <CgArrowLongRight size={30} />
          </NavItem>
          <NavItem onClick={() => handleNav('/profile')} active={active === '/profile'}>
            <Link href={!loggedIn ? '/profile' : ('/profile')} as="/profile">Currency Profile</Link>
            <NavText>Critical datapoints for a currency with key metrics for your research</NavText>
            <CgArrowLongRight size={30} />
          </NavItem>
          <NavItem onClick={() => handleNav('/news')} active={active === '/news'}>
            <Link href={!loggedIn ? '/news' : ('/news')} as="/news">News and Social</Link>
            <NavText>Aggregated news and social media feed with sentiment data</NavText>
            <CgArrowLongRight size={30} />
          </NavItem>
          <NavItem onClick={() => handleNav('/screener')} active={active === '/screener'}>
            <Link href="/screener" as="/screener">Screener</Link>
            <NavText>
              Scan the market with custom filters on fundamental, technical & on-chain data
            </NavText>
            <CgArrowLongRight size={30} />
          </NavItem>
          <NavItem onClick={() => handleNav('/alerts')} active={active === '/alerts'}>
            <Link href="/alerts" as="/alerts">Alerts</Link>
            <NavText>
              Market alerts for tech. breakouts, trading signals & social media activity
            </NavText>
            <CgArrowLongRight size={30} />
          </NavItem>
          <NavItem onClick={() => handleNav('/signin')} active={active === '/signin'}>
            <Link href="/signin" as="/signin">Portfolio</Link>
            <NavText>
              Risk management, optimization, and key statistics for your crypto portfolio
            </NavText>
            <FaLockOpen />
          </NavItem>
        </DropdownMenu>
      </NavContainer>
      <MobileNav border={border}>
        <div style={{ cursor: 'pointer' }}>
          {theme === 'light'
            ? <Image src="/assets/mobileLogoBlack.png" width="30" height="26" alt="logo" />
            : <Image src="/assets/mobileLogoWhite.png" width="30" height="26" alt="logo" />}
        </div>
        {title
          ? (
            <div className="mobileTitle">
              {title}
            </div>
          )
          : null}
        {closed
          ? (
            <Pointer onClick={() => setClosed(false)}>
              {theme === 'light'
                ? <Image src="/assets/burgerBlack.png" width="19" height="19" alt="menu" />
                : <Image src="/assets/burgerWhite.png" width="19" height="19" alt="menu" />}
            </Pointer>
          )
          : (
            <Pointer onClick={() => setClosed(true)}>
              {theme === 'light'
                ? <Image src="/assets/xBlack.png" width="24" height="24" alt="menu" />
                : <Image src="/assets/xWhite.png" width="24" height="24" alt="menu" />}
            </Pointer>
          )}
      </MobileNav>
      {!closed
        ? (
          <BurgerMenu>
            <MobileNav border={border}>
              <div style={{ cursor: 'pointer' }} onClick={() => { setActive('/'); setClosed(false); }}>
                <Link href="/">
                  {theme === 'light'
                    ? <Image src="/assets/mobileLogoBlack.png" width="30" height="26" alt="logo" />
                    : <Image src="/assets/mobileLogoWhite.png" width="30" height="26" alt="logo" />}
                </Link>
              </div>
              {title
                ? (
                  <div className="mobileTitle">
                    {title}
                  </div>
                )
                : null}
              {closed
                ? (
                  <Pointer onClick={() => setClosed(false)}>
                    {theme === 'light'
                      ? <Image src="/assets/burgerBlack.png" width="19" height="19" alt="menu" />
                      : <Image src="/assets/burgerWhite.png" width="19" height="19" alt="menu" />}
                  </Pointer>
                )
                : (
                  <Pointer onClick={() => setClosed(true)}>
                    {theme === 'light'
                      ? <Image src="/assets/xBlack.png" width="24" height="24" alt="menu" />
                      : <Image src="/assets/xWhite.png" width="24" height="24" alt="menu" />}
                  </Pointer>
                )}
            </MobileNav>
            <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
              <Link href="/terminal">Terminal</Link>
            </BurgerMenuItem>
            <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
              <Link href="/pricing">Pricing</Link>
            </BurgerMenuItem>
            <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
              <Link href="/downloads">Downloads</Link>
            </BurgerMenuItem>
            <BurgerMenuItem onClick={() => setShowDropdown(!showDropdown)}>
              <div>Applications</div>
              {
                showDropdown
                  ? <BsChevronUp style={{ marginLeft: 10 }} />
                  : <BsChevronDown style={{ marginLeft: 10 }} />
              }
            </BurgerMenuItem>
            <MobileDropdown showDropdown={showDropdown}>
              <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
                <BurgerMenuItemIcon>
                  <MdOutlineSpaceDashboard size={35} />
                </BurgerMenuItemIcon>
                <Link href="/dashboard">Dashboard</Link>
              </BurgerMenuItem>
              <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
                <BurgerMenuItemIcon>
                  <MdOutlinePaid size={35} />
                </BurgerMenuItemIcon>
                <Link href="/profile">Currency Profiles</Link>
              </BurgerMenuItem>
              <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
                <BurgerMenuItemIcon>
                  <MdOutlineArticle size={35} />
                </BurgerMenuItemIcon>
                <Link href="/news">News and Social</Link>
              </BurgerMenuItem>
              <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
                <BurgerMenuItemIcon>
                  <MdOutlineDonutSmall size={35} />
                </BurgerMenuItemIcon>
                <Link href="/screener">Market Screener</Link>
              </BurgerMenuItem>
              <BurgerMenuItem onClick={() => { setActive('/'); setClosed(true); }}>
                <BurgerMenuItemIcon>
                  <MdNotificationsNone size={35} />
                </BurgerMenuItemIcon>
                <Link href="/alerts">Alerts</Link>
              </BurgerMenuItem>
              <BurgerMenuItem isLocked onClick={() => { setActive('/'); setClosed(true); }}>
                <BurgerMenuItemIcon>
                  <AiFillFolderOpen size={35} />
                </BurgerMenuItemIcon>
                <Link href="/signin">Portfolio</Link>
                <BurgerMenuItemIcon style={{ marginLeft: 10 }}>
                  <FaLockOpen />
                </BurgerMenuItemIcon>
              </BurgerMenuItem>
            </MobileDropdown>
            <LogoutButton onClick={() => { setActive('/signin'); setClosed(true); handleLogin(); }}>
              Sign In
            </LogoutButton>
          </BurgerMenu>
        )
        : null}
    </MobileHeader>
  );
};

export default HeaderUnauth;
