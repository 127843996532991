import React from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { logout, oauthLogout } from '../../account/actions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  a {
    color: ${({ theme }) => theme.colors.accent};
    cursor: pointer;
  }
`;

const NavBackLink = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
`;

const UnauthView = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const result: any = useSelector((state) => state);
  const { refresh_token: refreshToken } = result.account;

  const handleLogout = () => {
    if (!refreshToken) {
      dispatch(oauthLogout());
    } else {
      dispatch(logout());
    }
    router.push('/signin');
  };

  return (
    <Container>
      <div>
        Unauthorized. Please email contact@uptick.co for login assistance.
      </div>
      <br />
      <br />
      <NavBackLink onClick={() => handleLogout()}>
        <b>Go Back To Login</b>
      </NavBackLink>
    </Container>
  );
};

export default UnauthView;
