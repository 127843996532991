/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import {
  useTable, useFilters, useSortBy, usePagination,
} from 'react-table';
// import { LockedOverlay } from '..';

interface TableProps {
  columns: any[];
  data: any[];
  initialPageSize?: number;
  locked?: boolean;
  rowSize: number;
  group?: string;
}

const TableContainer = styled.div`
  margin-top: 8px;
  padding-bottom: 20px;
  table {
    border-collapse: separate;
    border-spacing: 0 0;
    background: ${({ theme }) => theme.site.bannerBackground};
    border-radius: 8px;
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid ${({ theme }) => theme.site.bannerBorder};
      text-align: right;
      :first-child {
        text-align: left;
      }
    }
    td {
      padding: 16px;
    }
    th {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      text-align: right;
    }
    tr {
      cursor: pointer;
    }
    .pagination {
      margin-top: 20px;
    }
  }
`;

const TableSelect = styled.select`
  border-radius: 4px;
  background: ${({ theme }) => theme.site.bannerBackground};
  border: 1px solid ${({ theme }) => theme.site.bannerBorder};
  font-size: 16px;
  padding: 8px 8px;
  color: ${({ theme }) => theme.site.color};
  cursor: pointer;
`;

const TableButton = styled.button`
  border-radius: 4px;
  background: ${({ theme }) => theme.site.bannerBackground};
  border: 1px solid ${({ theme }) => theme.site.bannerBorder};
  font-size: 16px;
  color: ${({ theme }) => theme.site.color};
  padding: 8px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const ScrollContainer = styled.div`
  overflow-x: scroll;
  overflow-y: scroll;
  max-height: 450px;
  border: 1px solid ${({ theme }) => theme.site.bannerBorder};
`;

const Table: React.FC<TableProps> = (props: TableProps) => {
  const {
    columns, data = [], initialPageSize = 20, rowSize, locked = false,
  } = props;

  const router = useRouter();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canNextPage,
    canPreviousPage,
    prepareRow,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    locked,
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: initialPageSize },
    autoResetFilters: false,
  }, useFilters, useSortBy, usePagination);

  const navToProfile = (sym: string) => {
    router.push(`/profile?token=${sym}`);
  };

  return (
    <TableContainer>
      <ScrollContainer style={{ maxHeight: `${(pageSize + 1) * rowSize}px` }}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`${headerGroup.id}_table_head`}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} key={`${column.id}_table_header`}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                    </span>
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              const sym = row.cells[0].row.original.currency;
              return (
                <tr {...row.getRowProps()} onClick={() => navToProfile(sym)} key={`${row.id}_table_row`}>
                  {row.cells.map((cell: any) => (
                    <td
                      {...cell.getCellProps({
                        style: {
                          width: cell.column.width,
                          minWidth: cell.column.minWidth,
                        },
                      })}
                      key={`${cell.getCellProps().key}_table_cell`}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </ScrollContainer>
      <div style={{
        display: 'flex', marginTop: '20px', justifyContent: 'space-between', width: '100%',
      }}
      >
        <span style={{ position: 'relative' }}>
          <TableSelect
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[initialPageSize, 50, 100, 200].map((pageS) => (
              <option key={pageS} value={pageS}>
                {pageS}
              </option>
            ))}
          </TableSelect>
          {/* { !['Tier1', 'Tier2'].includes(group as string) && <LockedOverlay /> } */}
        </span>
        <div style={{ position: 'relative' }}>
          {canPreviousPage
            ? (
              <>
                <TableButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </TableButton>
                <TableButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
                </TableButton>
              </>
            ) : null}
          {[pageIndex + 1, pageIndex + 2, pageIndex + 3, pageIndex + 4, pageIndex + 5].map((p) => (
            <TableButton onClick={() => gotoPage(p - 1)} key={`tbl_btn_${p}`}>
              {p}
            </TableButton>
          ))}
          {canNextPage
            ? (
              <>
                <TableButton onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </TableButton>
                <TableButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  {'>>'}
                </TableButton>
              </>
            )
            : null}
          {/*  { !['Tier1', 'Tier2'].includes(group as string) && <LockedOverlay /> } */}
        </div>
      </div>
    </TableContainer>
  );
};

Table.defaultProps = {
  initialPageSize: 20,
  locked: false,
  group: 'Tier0',
};

export default Table;
