import * as React from 'react';
import styled from 'styled-components';
import { FiChevronDown, FiX } from 'react-icons/fi';
import CardContainer from './CardContainer';

interface SelectorOptionProps {
  text: string
  value: {
    symbol: string
  }
}

interface SelectorProps {
  options: Array<SelectorOptionProps>;
  selectCoin: Function;
}

const SelectorContainer = styled.div`
  width: 100%;
  position: relative;
`;

const SelectorInputContainer = styled(CardContainer)`
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
`;

const SelectorInput = styled.input`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.site.selectorText};
  outline: none;
  background: transparent;
  border: none;
`;

const OptionsContainer = styled.div`
  margin-top: 0;
  background: ${({ theme }) => theme.site.bannerBackground};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  z-index: 999;
  width: 100%;
  max-height: 193px;
  overflow-y: auto;
`;

const Option = styled.div`
  cursor: pointer;
  padding: 10px 16px;
  &:last-child {
    margin-bottom: 0px !important;
  }
  &:hover {
    background: ${({ theme }) => theme.site.selectorTextHover};
  }
`;

const Selector: React.FC<SelectorProps> = (props: SelectorProps) => {
  const { options, selectCoin } = props;
  const [selectorValue, setSelectorValue] = React.useState('');
  const [showOptions, setShowOptions] = React.useState(false);
  const [isOptionsHover, setIsOptionsHover] = React.useState(false);

  const handleOptionClick = (itm: SelectorOptionProps) => {
    setSelectorValue(itm.text);
    setShowOptions(false);
    selectCoin(itm.value.symbol);
  };

  const renderOptions = () => {
    let result = [...options];
    if (selectorValue) {
      result = options.filter(
        (itm) => itm.text.toLowerCase().indexOf(selectorValue.toLowerCase()) >= 0,
      );

      if (result.length === 0) {
        return <div>No results found</div>;
      }
    }

    return result.map((itm: SelectorOptionProps, idx: number) => (
      // eslint-disable-next-line react/no-array-index-key
      <Option key={`option_${idx}_${itm.value}`} onClick={() => handleOptionClick(itm)}>
        {itm.text}
      </Option>
    ));
  };

  return (
    <SelectorContainer onBlur={() => (!isOptionsHover ? setShowOptions(false) : null)}>
      <SelectorInputContainer>
        <SelectorInput
          placeholder="Select Currency"
          value={selectorValue}
          onChange={(e) => setSelectorValue(e.target.value)}
          onClick={() => setShowOptions(!showOptions)}
        />
        {
          selectorValue
            ? <FiX onClick={() => setSelectorValue('')} />
            : <FiChevronDown />
        }
      </SelectorInputContainer>
      {
        showOptions && (
          <OptionsContainer
            onMouseOver={() => setIsOptionsHover(true)}
            onMouseOut={() => setIsOptionsHover(false)}
          >
            { renderOptions() }
          </OptionsContainer>
        )
      }
    </SelectorContainer>
  );
};

export default Selector;
