import React from 'react';
import { FiCheck } from 'react-icons/fi';
import styled from 'styled-components';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MembershipPlanWrapper = styled.div`
  z-index: 2;
  position: relative;
  align-self: center;
  width: 350px;
  min-width: 350px;
  margin-bottom: 50px;
  border: 2px solid ${(props) => props.theme.site.bannerBorder};
  border-radius: 10px;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const MembershipPlanInnerWrapper = styled.div`
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.site.bannerBackground};
  color: white;
  border-radius: 10px;
  overflow: hidden;
`;

const TopSection = styled.div`
  width: 100%;
  height: 100px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.accent};
`;

const PlanDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const PlanDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.accent};
  font-family: ${(props) => props.theme.fonts.serif};
`;

const PlanDetailIcon = styled.div`
  flex: 1;
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes[4]};
`;

const PlanDetailText = styled.div`
  flex: 6;
  font-size: ${(props) => props.theme.fontSizes[3]};
  font-weight: ${(props) => props.theme.fontWeights.light};
  color: ${(props) => props.theme.site.color};
  text-align: left;
`;

const TierLabel = styled.div`
    font-size: 30px;
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.site.color};
`;

const CelebrationIcon = styled.img`
  width: 50%;
  height: auto;
  margin-bottom: -30px;
  z-index: 1;
`;

const renderPlanDetails = () => {
  const planDetails = [
    'Market and Coin Specific News',
    'Profile Page for Over 750 Currencies',
    'Market Heatmap',
    'News and Social Media Sentiment',
    'Trading Signals',
    'Market Screener',
    'Technical Workspace',
    'Trading Alert Notifications',
  ];

  const color = '#37CD93';

  return planDetails.map((itm, idx) => (
    // eslint-disable-next-line react/no-array-index-key
    <PlanDetail key={`plan_detail_${idx}`}>
      <PlanDetailIcon><FiCheck color={color} /></PlanDetailIcon>
      <PlanDetailText>{itm}</PlanDetailText>
    </PlanDetail>
  ));
};

const MembershipPlan = () => (
  <OuterContainer>
    <CelebrationIcon src="/assets/celebration-icon.png" />
    <MembershipPlanWrapper>
      <MembershipPlanInnerWrapper>
        <TopSection>
          <TierLabel>Features</TierLabel>
        </TopSection>
        <PlanDetailContainer>
          { renderPlanDetails() }
        </PlanDetailContainer>
      </MembershipPlanInnerWrapper>
    </MembershipPlanWrapper>
  </OuterContainer>
);

export default MembershipPlan;
