import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import {
  BsFillArrowDownCircleFill,
  BsFillArrowUpCircleFill,
} from 'react-icons/bs';

interface SidebarNavItemProps {
  active?: boolean;
  setActive?: Function;
  icon?: Function | null;
  link?: any;
  linkAs?: string;
  subNav?: any[];
  customOption?: React.ReactElement[];
  onClick?: Function | null;
  subNavHeight?: string;
  title: string;
  expanded: boolean;
}

type ContainerProps = {
  expanded: boolean;
  showSubNav?: boolean;
  subNavHeight?: string;
}

const Container = styled.div<ContainerProps>`
  width: ${({ expanded }) => (expanded ? '250px' : '')};
  height: ${({ showSubNav, subNavHeight }) => (showSubNav ? subNavHeight : '25px')};
  transition: height 300ms;
  justify-content: ${({ expanded }) => (expanded ? 'start' : 'center')};
  align-items: center;
  display: ${({ expanded }) => (expanded ? 'block' : 'flex')};;
  flex-direction: row;
  margin-bottom: 50px;
  cursor: pointer;
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
`;

interface TitleProps {
  active?: boolean;
}

const Title = styled.div<TitleProps>`
  font-weight: 700;
  margin-left: 25px;
  margin-right: 25px;
  font-size: 16px;
  color: ${({ active, theme }) => (active ? theme.colors.accent : 'white')};
  svg {
    color: ${({ active, theme }) => (active ? theme.colors.accent : 'white')};
  }
`;

const SubNav = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${({ theme }) => theme.colors.accent};
  align-items: start;
  justify-content: center;
  margin-left: 50px;
  margin-top: 25px;
`;

const SubNavItem = styled.div`
  cursor: pointer;
  font-weight: 700;
  margin-left: 14px;
  font-size: 16px;
  margin-bottom: 25px;
  padding: 5px 10px;
  width: 180px;
  text-align: left;
  :nth-last-child(1) {
    margin-bottom: 0;
  }
  :hover {
    background: #4c4d73;
    border-radius: 5px;
  }
  :active {
    opacity: 0.2;
  }
`;

const SidebarNavItem: React.FC<SidebarNavItemProps> = (props: SidebarNavItemProps) => {
  const {
    title, icon, expanded, link = '', linkAs = '',
    subNav = [], customOption = [],
    onClick, subNavHeight, active, setActive,
  } = props;
  const [showSubNav, setShowSubNav] = useState(false);
  const [showCustomOption, setShowCustomOption] = useState(false);
  const router = useRouter();

  const navToPath = (path: any, pathAs: string) => {
    if (setActive) setActive(pathAs);
    router.push(path, pathAs);
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (subNav.length === 0 && customOption.length === 0) {
      navToPath(link, linkAs);
    } else {
      setShowSubNav(!showSubNav);
      setShowCustomOption(!showCustomOption);
    }
  };

  const renderSubNav = () => subNav.map((itm) => (
    <SubNavItem key={itm.title} onClick={() => navToPath(itm.link, itm.linkAs)}>
      {itm.title}
    </SubNavItem>
  ));

  useEffect(() => {
    setShowSubNav(false);
    setShowCustomOption(false);
  }, [expanded]);

  return (
    <Container
      expanded={expanded}
      onClick={() => handleClick()}
      showSubNav={showSubNav}
      subNavHeight={subNavHeight}
    >
      <NavItem>
        {icon && icon(active ? '#55B1EC' : 'white')}
        {expanded && <Title active={active}>{title}</Title>}
        {
          expanded && (subNav.length !== 0 || customOption.length !== 0) && (
            showSubNav
              ? <BsFillArrowUpCircleFill size={18} />
              : <BsFillArrowDownCircleFill size={18} />
          )
        }
      </NavItem>
      {
        expanded && showSubNav && (
          <SubNav>
            { renderSubNav() }
          </SubNav>
        )
      }
      {
        expanded && showCustomOption && customOption
      }
    </Container>
  );
};

SidebarNavItem.defaultProps = {
  active: false,
  setActive: () => {},
  icon: null,
  link: {},
  linkAs: '',
  subNav: [],
  customOption: [],
  onClick: null,
  subNavHeight: '',
};

export default SidebarNavItem;
