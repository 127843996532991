import styled from 'styled-components';

const PageHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.site.bannerBorder};
  padding-bottom: 14px;
  padding-left: 50px;
  background: ${({ theme }) => theme.site.bannerBackground};
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export default PageHeader;
