import styled from 'styled-components';

const PageHeaderDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  max-width: 30.83vw;
  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
`;

export default PageHeaderDescription;
