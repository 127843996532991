import * as React from 'react';
import {
  FiChevronDown,
  FiChevronUp,
} from 'react-icons/fi';
import {
  OptionsContainer, SelectorContainer, SelectorDisplay, Option,
} from '../globalStyles/common';
// import { LockedOverlay } from '..';

interface SelectorOptionProps {
  display: any
  value: string
}

interface SimpleSelectorProps {
  options: any[]
  onSelect: Function
  placeholder: string
  reset?: boolean
  onReset?: React.Dispatch<React.SetStateAction<string>>
  // group?: string;
  // lock?: boolean;
  selectedValue?: any,
  displayValue?: string,
}

const SimpleSelector: React.FC<SimpleSelectorProps> = (props: SimpleSelectorProps) => {
  const {
    options = [], onSelect = () => {}, placeholder = 'Placeholder', reset = false, onReset = () => {},
    displayValue, selectedValue,
  } = props;
  const [selectorValue, setSelectorValue] = React.useState(selectedValue?.display || displayValue || '');
  const [showOptions, setShowOptions] = React.useState(false);
  const [, setIsOptionsHover] = React.useState(false);

  React.useEffect(() => {
    if (reset) {
      setSelectorValue('');
      onReset('');
    }
  }, [reset]);

  const handleOptionClick = (itm: SelectorOptionProps) => {
    if (itm.value) {
      setSelectorValue(itm.display);
    } else {
      setSelectorValue('');
    }
    onSelect(itm);
    setShowOptions(false);
  };

  const renderOptions = () => options.map((itm: SelectorOptionProps, idx: number) => (
    // TODO remove idx
    // eslint-disable-next-line react/no-array-index-key
    <Option key={`option_${idx}_${itm.value}`} onClick={() => handleOptionClick(itm)}>
      {itm.display}
    </Option>
  ));

  return (
    <SelectorContainer onMouseLeave={() => setShowOptions(false)}>
      <SelectorDisplay hasValue={selectorValue !== '' || displayValue !== ''} onClick={() => setShowOptions(!showOptions)}>
        <span>{ selectorValue || displayValue || placeholder }</span>
        {showOptions ? <FiChevronUp /> : <FiChevronDown />}
      </SelectorDisplay>
      {
        showOptions && (
          <OptionsContainer
            onMouseOver={() => setIsOptionsHover(true)}
            onMouseOut={() => setIsOptionsHover(false)}
          >
            { renderOptions() }
          </OptionsContainer>
        )
      }
      {/*  { !['Tier1', 'Tier2'].includes(group as string) && lock && <LockedOverlay /> } */}
    </SelectorContainer>
  );
};

SimpleSelector.defaultProps = {
  reset: false,
  onReset: () => {},
  selectedValue: {},
  displayValue: '',
};

export default SimpleSelector;
