/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Link from 'next/link';
import ReactLoading from 'react-loading';
import { BiEdit } from 'react-icons/bi';
import SelectRounded from '../../../ui/components/SelectRounded';
import { RootState } from '../../../store';

import { changePortfolio } from '../../actions';

type CustomOption = {
  value: any,
  label: string,
  style?: object,
  onSelect?: () => void,
}

interface PortfolioSelectorProps {
  width?: string;
  style?: any;
  withEditButton?: boolean;
  onSelect?: (id: any) => void;
  nullPortfolioOption?: boolean;
  customOptions?: CustomOption[];
}

const PortfolioSelector = ({
  width, style = {}, withEditButton, onSelect, nullPortfolioOption = false, customOptions = [],
}: PortfolioSelectorProps) => {
  const { portfolioList, portfolioId } = useSelector((state: RootState) => state.portfolio);
  const accountState: any = useSelector((state: RootState) => state.account);
  const {
    access_token: accessToken, id_token: idToken,
  } = accountState;
  const dispatch = useDispatch();
  const [options, setOptions] = useState<any[]>([]);
  const [showLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState({ label: '', value: '' });

  const prepareOptions = (portfolios: any[]) => {
    const opts: any[] = [];
    if (nullPortfolioOption) {
      opts.push({
        value: 'empty',
        label: 'Select Portfolio',
        style: { color: 'red' },
      });
    }
    if (portfolios) {
      portfolios.forEach((p) => {
        opts.push({
          value: p,
          label: p.portfolio_name,
        });
      });
    }
    if (customOptions) {
      customOptions.forEach((p) => {
        opts.push({
          value: p.value,
          label: p.label,
          style: p.style,
        });
      });
    }
    setOptions(opts);
    if (nullPortfolioOption) {
      setSelected(opts[0]);
      if (onSelect) {
        onSelect(null);
      }
    } else {
      setSelected(opts.find((p: any) => p.value.id === parseInt(portfolioId, 10)) || opts[0]);
    }
    setLoading(false);
  };

  const onSelectPortfolio = (val: any) => {
    setLoading(true);
    const customOpt = customOptions?.find((opt) => opt.value === val.value);
    if (customOpt) {
      customOpt.onSelect?.();
    } else if (onSelect && nullPortfolioOption && val.value === 'empty') {
      onSelect(null);
    } else if (onSelect) {
      onSelect(val.value.id.toString());
    } else {
      dispatch(changePortfolio(accessToken, idToken, val.value.id.toString()));
    }
    setSelected(val);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    if (portfolioList.results) {
      setLoading(true);
      prepareOptions(portfolioList.results);
    }
  }, [portfolioList]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <SelectRounded
        style={{ width, ...style }}
        id="PortfolioSelector"
        options={options}
        onChange={(val: any) => onSelectPortfolio(val)}
        value={selected}
      />
      {withEditButton
        && (
        <Link href="/portfolio?mode=gainLoss" passHref>
          <BiEdit style={{ marginLeft: '12px', cursor: 'pointer' }} size={22} />
        </Link>
        )}
      {showLoading
        && <ReactLoading type="bubbles" color="#55B1EC" height={30} width={30} />}
    </div>
  );
};

PortfolioSelector.defaultProps = {
  width: '320px',
  withEditButton: false,
};

export default PortfolioSelector;
