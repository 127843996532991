/* eslint-disable camelcase */
import React from 'react';
import { useRouter } from 'next/router';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { FiChevronRight } from 'react-icons/fi';
import styled from 'styled-components';
import { CardContainer, CryptoIcon } from '..';
import { colors } from '../themes/common';
import { formatPriceToUSD } from '../../utils';

interface PriceChangeTextProps {
  positive: boolean
}

interface OppScoreProps {
  score: number
}

interface CurrencyCardProps {
  data: {
    currency: {
      name: string;
      symbol: string;
    }
    marketdata: {
      price_usd: number;
      percent_change_24h: number;
      prices_7d: any[];
    }
    oppScore: number;
    alertCount: number;
  }
}

const Container = styled(CardContainer)`
  box-shadow: ${({ theme }) => theme.site.bannerShadow};
  width: 200px;
  min-width: 200px;
  margin-right: 20px;
  cursor: pointer;
  padding: 10px 10px 0 10px;
  padding-bottom: 0;
`;

const TopOuterContainer = styled.div`
  &:hover {
    opacity: 0.5;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justifiy-content: space-between;
`;

const CurrencyLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justifiy-content: space-between;
`;

const CurrencyText = styled.div`
  flex-direction: column;
  margin-left: 5px;
`;

const CurrencySymbol = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[1]};
`;

const CurrencyName = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.site.pageHeaderText};
`;

const PriceContainer = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 10px;
`;

const PriceText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const PriceChangeText = styled.div<PriceChangeTextProps>`
  color: ${({ positive, theme }) => (positive ? theme.colors.positive : theme.colors.negative)};
`;

const OppScoreText = styled.div<OppScoreProps>`
  color: ${({ score, theme }) => (score >= 0.5 ? theme.colors.positive : theme.colors.negative)};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  margin-bottom: 5px;
`;

/* const BottomButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${(props) => props.theme.site.bannerBorder};
  width: 200px;
  min-width: 200px;
  margin-top: 5px;
  margin-left: -10px;
  div {
    flex: 1;
  }
`;
 */
/* const BottomButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  &:first-child {
    border-right: 1px solid ${(props) => props.theme.site.bannerBorder};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`; */

const CurrencyCard: React.FC<CurrencyCardProps> = (props: CurrencyCardProps) => {
  const router = useRouter();
  const {
    data: {
      currency = { name: '', symbol: '' },
      marketdata = {
        price_usd: 0,
        percent_change_24h: 0,
        prices_7d: [],
      },
      oppScore = 0,
      // alertCount = 0,
    },
  } = props;

  const { name, symbol } = currency;
  const { price_usd, percent_change_24h, prices_7d } = marketdata;

  const navToProfile = () => {
    router.push(`/profile?token=${symbol}`);
  };

  /*   const navToAlerts = () => {
    const params = [{ field: 'currency', operator: '=', value: symbol }];
    const paramString = JSON.stringify(params);
    router.push(`/alerts?filters=${paramString}`);
  };

  const navToNews = () => {
    router.push(`/news?token=${symbol}`);
  }; */

  return (
    <Container>
      <TopOuterContainer onClick={navToProfile}>
        <OppScoreText score={oppScore}>
          {
            oppScore >= 0.5
              ? 'BULLISH (24HR SIGNAL)'
              : 'BEARISH (24HR SIGNAL)'
          }
        </OppScoreText>
        <TopSection>
          <CurrencyLabel>
            <CryptoIcon symbol={symbol} height="40" width="40" />
            <CurrencyText>
              <CurrencySymbol>{symbol}</CurrencySymbol>
              <CurrencyName>{name}</CurrencyName>
            </CurrencyText>
          </CurrencyLabel>
          <FiChevronRight />
        </TopSection>
        <BottomSection>
          <PriceContainer>
            <PriceText>{price_usd && formatPriceToUSD(price_usd)}</PriceText>
            <PriceChangeText positive={percent_change_24h > 0}>
              {percent_change_24h.toFixed(2)}
              %
            </PriceChangeText>
          </PriceContainer>
          <Sparklines data={prices_7d} style={{ width: '100%', height: 20 }}>
            <SparklinesLine color={colors.accent} style={{ fill: 'none', strokeWidth: 3 }} />
          </Sparklines>
        </BottomSection>
      </TopOuterContainer>
      {/* <BottomButtonContainer>
        <BottomButton onClick={navToAlerts}>
          <span>
            {alertCount}
            {' '}
            {alertCount === 1 ? 'Alert' : 'Alerts'}
          </span>
          <FiChevronRight />
        </BottomButton>
        <BottomButton onClick={navToNews}>
          <span>News</span>
          <FiChevronRight />
        </BottomButton>
      </BottomButtonContainer> */}
    </Container>
  );
};

export default CurrencyCard;
