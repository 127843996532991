import styled from 'styled-components';
import CardContainer from '../components/CardContainer';

interface NavContainerProps {
  background?: string;
}

interface NavItemProps {
  isLocked?: boolean;
  active?: boolean;
  onClick?: Function;
}

interface MobileNavProps {
  border?: boolean
}

interface BurgerMenuItemProps {
  isLocked?: boolean;
}

export const NavContainer = styled.div<NavContainerProps>`
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 14px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.site.bannerBackground};
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const Nav = styled.div`
  display: flex;
`;

export const NavLogo = styled.div`
  margin-right: 40px;
  cursor: pointer;
`;

export const SocialLogos = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 40px;

  @media screen and (max-width: 1200px) {
    justify-content: unset;
    img {
      padding-right: 20px !important;
    }
 }
`;
export const FooterTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #647087;
  color: ${(props) => props.theme.site.pageHeaderText} !important;
  text-transform: uppercase;
  margin-bottom: 20px;
  @media screen and (max-width: 1200px) {
    margin-top: 24px;
 }
`;
export const FooterLink = styled.a`
  display: block !important;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: ${(props) => props.theme.site.color} !important;
  margin-bottom: 20px;
  cursor: pointer;
`;
export const FooterLoginBtn = styled.button`
  padding: 8px 16px 8px 16px;
  border-radius: 6px;
  background-color: #55b1ec;
  color: #ffffff;
  border: none;
  margin-right: 24px;
  text-transform: uppercase;
  cursor: pointer;
`;
export const FooterInput = styled.input`
  border: 1px solid #55b1ec;
  background: ${(props) => props.theme.site.bannerBackground};
  width: 270px;
  border-radius: 8px;
  padding: 8px;
  &::placeholder {
    color:  ${(props) => props.theme.site.color};
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
 }
`;

export const FooterInputContainer = styled.div`
  position: relative;
  overflow: visible;
`;

export const InputArrowContainer = styled.div`
  position: absolute;
  right: 0;
  top: 7px;
  margin-right: 20px;
`;

export const NavItem = styled.div<NavItemProps>`
  white-space: nowrap;
  margin-right: 32px;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  opacity: ${(props) => (props.isLocked ? 0.4 : 1)};
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 50%;
    margin-left: 25%;
    padding-top: 2px;
    border-bottom: ${({ active }) => (active ? '2px solid #55B1EC' : 'none')};
    border-radius: 1px;
  }
  & > a {
    color: ${({ theme }) => theme.site.color};
    position: relative;
    padding-bottom: 5px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    margin-right: ${({ isLocked }) => (isLocked ? '5px' : '0')};
  }
  & > a:hover {
    opacity: 1.0;
    font-weight: bold;
  }
  ${({ active }) => (!active
    ? `& > a::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      background: none repeat scroll 0 0 transparent;
      height: 2px;
      border-radius: 1px;
      width: 0;
      background: #55B1EC;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
  }` : '')};
  & > a:hover::after {
    width: 50%;
    left: 25%;
  }
  & span {
    position: absolute;
    bottom: -15px;
    left: 0;
    opacity: 0;
    display: flex;
    align-items: flex-end;
  }
  & span:hover {
    opacity: 1;
  }
  & :hover {
    opacity: 0.8;
  }
  & :hover + span {
    opacity: 1;
  }
`;

export const MobileNav = styled.div<MobileNavProps>`
  display: none;
  @media screen and (max-width: 1200px) {
    display: flex;
    width: 100%;
    padding-left: 23px;
    padding-right: 23px;
    padding-top: 23px;
    padding-bottom: 32px;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${({ theme }) => `1px solid ${theme.site.bannerBorder}`};
    padding-bottom: 24px;
    background: ${({ theme }) => theme.site.bannerBackground};
  }
`;

export const BurgerMenu = styled.div`
  display: none;
  @media screen and (max-width: 1200px) {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background: ${({ theme }) => theme.site.background};
    overflow-x: none;
    overflow-y: scroll;
    padding-bottom: 50px;
  }
`;

export const BurgerMenuItem = styled.div<BurgerMenuItemProps>`
  @media screen and (max-width: 1200px) {
    width: 100%;
    opacity: ${(props) => (props.isLocked ? 0.4 : 1)};
    font-size: 24px;
    line-height: 29px;
    padding-left: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    & > a {
      color: ${({ theme }) => theme.site.color};
      margin-right: ${({ isLocked }) => (isLocked ? '5px' : '0')};
    }
  }
`;

export const BurgerMenuItemIcon = styled.div`
  margin-top: 5px;
  margin-right: 21px;
`;

export const FlexAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Pointer = styled.div`
  cursor: pointer;
`;

export const LogoutButton = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  width: 95%;
  background: #55b1ec;
  border-radius: 8px;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const FooterContainer = styled.div<NavContainerProps>`
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 28px;
  padding-bottom: 50px;
  margin-top: 120px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 8px;
  background: ${({ theme }) => theme.site.bannerBackground};
  border: 1px solid ${({ theme }) => theme.site.bannerBorder};
  @media screen and (max-width: 1200px) {
    padding: 30px;
    flex-direction: column;
    margin: 0px;
    margin-top: 80px;
  }
`;

export const FooterSubtext = styled.div`
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 50px;
  color: #647087;
  padding: 0 20px;
  @media screen and (max-width: 1200px) {
    margin: 15px
  }
`;

export const GreyText = styled.div`
  color: #647087
`;

export const BoldText = styled.div`
  font-weight: bolder;
`;

export const MobileHeader = styled.div`
  width: 100%;
  @media screen and (max-width: 1200px) {
    position: sticky;
    height: 100%;
    top: 0px;
    z-index: 9999;
    background: ${({ theme }) => theme.site.background};
  }
`;

interface FiltersApplyProps {
  disabled: boolean;
}

export const FiltersContainer = styled.div<NavContainerProps>`
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 40px;
  background: ${({ theme }) => theme.site.bannerBackground};
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const FiltersTitle = styled.div`
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.02em;
`;

export const FiltersSubtitle = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

export const FiltersCheckboxes = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
  grid-gap: 20px 15px;
  padding-top: 24px;
  padding-bottom: 24px;
  max-height: 160px;
  overflow-y: scroll;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const FiltersApply = styled.button<FiltersApplyProps>`
  ${({ disabled }) => (!disabled ? `
    cursor: pointer;
    :hover {
      opacity: 0.7;
    }
  ` : `cursor: not-allowed;
       opacity: 0.6;
  `)}
  padding: 8px;
  border-radius: 8px;
  background: ${({ theme }) => theme.site.bannerBorder};
  margin-right: 5px;
  border: 1px solid ${({ theme }) => theme.site.bannerBorder};
`;

export const FiltersApplyText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.site.color};
`;

export const FiltersApplySmallText = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  color: ${({ theme }) => theme.site.color};
`;

export const SmallButton = styled.div`
  padding: 8px;
  display: flex;
  background: ${({ theme }) => theme.colors.accent}};
  font-weight: ${({ theme }) => theme.fontWeights.midBold}};
  color: white;
  border-radius: 4px;
  min-width: 85px;
  height: 36px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

export const SelectorContainer = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
`;

export const SelectorDisplay = styled(CardContainer)<any>`
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${({ hasValue, theme }) => {
    if (hasValue) return theme.site.color;
    return '#bbb';
  }};
`;

export const OptionsContainer = styled.div`
  flex-direction: column;
  margin-top: 0;
  background: ${({ theme }) => theme.site.bannerBackground};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  z-index: 999;
  width: 100%;
  max-height: 193px;
  overflow-y: auto;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 16px;
  color: ${({ theme }) => theme.site.color};
  &:last-child {
    margin-bottom: 0px !important;
  }
  &:hover {
    background: ${({ theme }) => theme.site.selectorTextHover};
  }
`;
