import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FaLockOpen } from 'react-icons/fa';
import { RootState } from '../../store';

const LockedOverlayContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0px #000;
  border-radius: 5px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  cursor: pointer;
  white-space: nowrap;
  text-decoration: underline;
  svg {
    color: ${({ theme }) => theme.site.color};
  }
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 13px;
  }
`;

const LockedOverlay: React.FC = () => {
  const accountState: any = useSelector((state: RootState) => state.account);
  const { loggedIn } = accountState;
  return (
    <LockedOverlayContainer>
      <Link href={loggedIn ? '/account' : '/signin'}>
        <span>
          <FaLockOpen />
        </span>
      </Link>
    </LockedOverlayContainer>
  );
};

export default LockedOverlay;
