/* eslint-disable no-unused-vars */
import React from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.site.bannerBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
`;

const LoadingOverlay: React.FC = () => (
  <LoadingContainer>
    <ReactLoading type="bubbles" color="#55B1EC" height={50} width={50} />
  </LoadingContainer>
);

export default LoadingOverlay;
