import styled from 'styled-components';

interface FlexProps {
  flex?: number;
  width?: string;
}

const Flex = styled.div<FlexProps>`
  display: flex;
  flex: ${({ flex }) => (flex || 1)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 100vh;
  width: ${({ width }) => (width || 'auto')};
`;

export default Flex;
