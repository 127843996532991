import styled, { ThemeContext } from 'styled-components';
import React, { useContext, useMemo } from 'react';
import Select from 'react-select';

const Wrap = styled.div`
  .select__control--menu-is-open{
    .select__dropdown-indicator{
      transform: rotate(180deg);
    }
  }
`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  color: ${({ theme }) => theme.site.color};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  display: inline-block;
`;

type OptionsProps = {
  label: string,
  value: any,
}

interface SelectRoundedProps {
  id: string,
  onChange: (val: any) => void,
  label?: string,
  name?: string,
  options: OptionsProps[],
  value?: OptionsProps,
  disabled?: boolean,
  style?: any,
  withNoSelect?: boolean,
}

const SelectRounded: React.FC<SelectRoundedProps> = (props: SelectRoundedProps) => {
  const {
    id,
    label,
    name,
    options,
    disabled,
    onChange,
    value,
    style,
    withNoSelect,
  } = props;
  const themeContext = useContext(ThemeContext);
  const colourStyles = useMemo(() => ({
    control: (styles: any, {
      isDisabled,
    }: any) => ({
      ...styles,
      backgroundColor: themeContext.site.bannerBackground,
      borderColor: themeContext.colors.white,
      borderRadius: '18px',
      textAlign: 'left',
      boxShadow: themeContext.site.bannerShadow,
      cursor: 'pointer',
      // eslint-disable-next-line no-nested-ternary
      color: isDisabled
        ? '#ccc'
        // eslint-disable-next-line no-nested-ternary
        : themeContext.site.color,
      ':hover': {
        borderColor: themeContext.colors.accent,
      },
    }),
    singleValue: (styles: any, {
      isDisabled,
    }: any) => ({
      ...styles,
      // eslint-disable-next-line no-nested-ternary
      fontSize: '16px',
      color: isDisabled
        ? '#ccc'
        : themeContext.site.color,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      background: 'rgba(85, 177, 236, 0.25)',
      width: '20px',
      height: '20px',
      padding: 0,
      borderRadius: '50%',
      marginRight: '10px',
      color: themeContext.colors.accent,
      ':hover': {
        color: themeContext.colors.accent,
      },
    }),
    menu: (styles: any) => ({
      ...styles,
      backgroundColor: themeContext.site.bannerBackground,
      border: `1px solid ${themeContext.site.bannerBorder}`,
      boxShadow: themeContext.site.bannerShadow,
      borderRadius: '4px',
      zIndex: 10,
    }),
    option: (styles: any, {
      isDisabled, isFocused, isSelected, data,
    }: any) => ({
      ...styles,
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: isDisabled
        ? themeContext.colors.disabled
        // eslint-disable-next-line no-nested-ternary
        : isSelected
          ? 'rgba(85, 177, 236, 0.25)'
          : isFocused
            ? 'rgba(85, 177, 236, 0.25)'
            : themeContext.site.bannerBackground,
      // eslint-disable-next-line no-nested-ternary
      color: isDisabled
        ? '#ccc'
        // eslint-disable-next-line no-nested-ternary
        : themeContext.site.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        // backgroundColor:
        //   !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
      ...data.style,
    })
    ,
    // multiValue: (styles, { data }) => {
    //   const color = chroma(data.color);
    //   return {
    //     ...styles,
    //     backgroundColor: color.alpha(0.1).css(),
    //   };
    // },
    // multiValueLabel: (styles, { data }) => ({
    //   ...styles,
    //   color: data.color,
    // }),
    // multiValueRemove: (styles, { data }) => ({
    //   ...styles,
    //   color: data.color,
    //   ':hover': {
    //     backgroundColor: data.color,
    //     color: 'white',
    //   },
    // }),
  }), [themeContext]);

  return (
    <Wrap style={style}>
      {!!label && <Label htmlFor={id}>{label}</Label>}
      <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={value}
        value={value}
        isDisabled={disabled}
        onChange={onChange}
        isSearchable={false}
        name={name}
        options={withNoSelect ? [{ label: 'No Selection', value: null }, ...options] : options}
        styles={colourStyles}
      />
    </Wrap>
  );
};

SelectRounded.defaultProps = {
  label: '',
  value: undefined,
  name: '',
  disabled: false,
  style: {},
  withNoSelect: false,
};

export default SelectRounded;
