import styled from 'styled-components';

const ScreenerTableContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th, td:first-child {
    background: ${({ theme }) => theme.site.bannerBackground};
    position: sticky;
    left: 0;
  }
  th {
    top: 0;
    z-index: 2;
  }
  td:first-child {
    z-index: 1;
  }
  th:first-child {
    z-index: 3;
  }
  @media screen and (max-width: 1250px) {
    th:first-child, td:first-child {
      box-shadow: 4px 0 4px -1px rgba(0, 0, 0, .1);
    }
  }
`;

export default ScreenerTableContainer;
