import styled from 'styled-components';

const HorizontalScroll = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 0 10px 0;
  overflow-x: auto;

  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
  }
`;

export default HorizontalScroll;
