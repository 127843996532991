import React, { useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import styled from 'styled-components';
import { LockedSignal } from '..';
import { formatPriceToUSD } from '../../utils';

export interface HeatmapProps {
    data: Array<any>;
    locked?: boolean;
    group?: string;
}

interface HeatmapCellProps {
  priceChange: number
}

const OuterContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const HeatmapContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

const HeatmapCell = styled.div<HeatmapCellProps>`
  width: 9.5%;
  min-width: 155px;
  height: 90px;
  margin-bottom: 5px;
  border-radius: 8px;
  cursor: pointer;
  background: ${({ priceChange, theme }) => {
    if (priceChange > 0) return theme.colors.positive;
    return theme.colors.negative;
  }};
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.accent};
  }
`;

const CellTextBold = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const CellText = styled.div`
`;

const Toggle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.accent};
  border: 1px solid ${({ theme }) => theme.site.bannerBorder};
  border-radius: 5px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const Heatmap = (props: HeatmapProps) => {
  const ref = useRef<any>(null);
  const [containerWidth, setContainerWidth] = useState(1140);
  const { data = [], locked = false } = props;

  const [showAll, setShowAll] = useState(false);

  const rescale = () => {
    setContainerWidth(ref.current.offsetWidth);
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', rescale);
    }
    rescale();
    return (() => {
      window.removeEventListener('resize', rescale);
    });
  }, []);

  const renderHeatmapCells = () => {
    const dataArr = showAll ? data : [...data].splice(0, Math.floor(containerWidth / 155) * 2);

    return dataArr.map((itm, idx) => {
      const {
        currency = '',
        name = '',
        price_usd: priceUSD = 0,
        percent_change_24h: pct24HR = 0,
        opportunity_score: oppScore = 0,
      } = itm;

      let signalLabel = 'SELL';
      if (oppScore >= 0.5) signalLabel = 'BUY';
      if (!oppScore) signalLabel = 'NEUTRAL';

      let displayName = name;
      const txtArr = name.split('');

      if (txtArr.length > 11) {
        displayName = `${txtArr.splice(0, 8).join('')}...`;
      }

      return (
        <HeatmapCell priceChange={pct24HR} key={`${currency}heatmap_cell`}>
          <CellTextBold>
            {`(${currency}) ${displayName}`}
          </CellTextBold>
          <CellText>
            {priceUSD ? formatPriceToUSD(priceUSD) : 'N/A'}
            {' '}
            {pct24HR ? pct24HR.toFixed(2) : 'N/A'}
            %
          </CellText>
          <CellTextBold>
            {
              (locked && idx > 4)
                ? <LockedSignal />
                : `Signal: ${signalLabel} (${oppScore?.toFixed(2) || 'N/A'})`
            }
          </CellTextBold>
        </HeatmapCell>
      );
    });
  };

  return (
    <OuterContainer>
      <HeatmapContainer ref={ref}>
        { renderHeatmapCells() }
      </HeatmapContainer>
      <span style={{ position: 'relative' }}>
        <Toggle onClick={() => setShowAll(!showAll)}>
          { showAll ? 'COLLAPSE' : 'EXPAND' }
          { showAll ? <FiChevronUp size={20} /> : <FiChevronDown size={20} /> }
        </Toggle>
        {/* { !['Tier1', 'Tier2'].includes(group as string) && <LockedOverlay /> } */}
      </span>
    </OuterContainer>
  );
};

export default Heatmap;
